import requests  from "../request";


export const auth = () => {
   return requests({
        url:'/api/user/auth', method:'get'
    })
}

export const userLogin = (userInfo) => {
    return requests({
        url: "/api/user/login",method: "post", data:userInfo
    });
 }
export const userLoginByEmail = (authCode) => {
    return requests({
        url: "/api/user/loginByEmail",method: "post", data:{'authCode':authCode}
    });
 }
export const register = (userInfo) => {
    return requests({
        url: "/api/user/register",method: "post", data:userInfo
    });
 }
export const updatePsw = (userInfo) => {
    return requests({
        url: "/api/user/updatePassword",method: "post", data:userInfo
    });
}


export const getUserInfo = () => {
    return requests({
        url: "/api/user/getUserInfo",method: "get",
    });
 }
export const getUserLevel = () => {
    return requests({
        url: "/api/user/getUserLevel",method: "get",
    });
 }

 export const logout = () => {
    return requests({
        url: "/api/user/logout",method: "get",
    });
 }

 export const getAvatar = () => {
    return requests({
        url: "/api/user/getAvatarAddr",method: "get",
    });
 }

 export const updateUserInfo = (userInfo) => {
    return requests({
        url: "/api/user/updateUserInfo",method: "post", data :userInfo
    });
 }

 export const sendEmail = (emailVo) => {
    return requests({
        url: "/api/email/sendCode",method: "post", data :emailVo
    });
 }

 export const bindEmail = (vo) => {
    return requests({
        url: "/api/user/userBindEmail",method: "post", data :vo
    });
 }

 export const destroy = (vo) => {
    return requests({
        url: "/api/user/destroy",method: "post"
    });
 }

 export const report = (vo) => {
    return requests({
        url: "/api/feedback/reportTopic",method: "post", data:vo
    });
 }

 export const feedback = (vo) => {
    return requests({
        url: "/api/feedback",method: "post", data:vo
    });
 }

 export const getMyWallet = () => {
    return requests({
        url: "/api/user/getUserWallet",method: "get"
    });
 }

 export const getUserPublicInfo = (userId) => {
    return requests({
        url: "/api/user/getUserPublicInfo?userId="+userId, method: "get",
    });
 }