<template>
  <div>
        <el-table v-if="infos.length" :data="infos.filter(data => !search || data.type.toLowerCase().includes(search.toLowerCase()) || data.createTime.includes(search) || data.remark.includes(search))  " style="width: 100%">
       
            <el-table-column type="expand" label="详情">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <div style="margin-left:60px;">
                            
                            <el-form-item label="标签">
                                <div class="tag-group" v-if="props.row.reservation">
                                    <el-tag v-show="props.row.reservation.submitTags" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in props.row.reservation.submitTags.split(',')" :key="index">{{item}}</el-tag>
                                </div>
                                 <div class="tag-group" v-if="props.row.topic">
                                    <el-tag v-show="props.row.topic.tags" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in props.row.topic.tags.split(',')" :key="index">{{item}}</el-tag>
                                </div>
                            </el-form-item>
                            <el-form-item label="详情">
                                <div v-if="props.row.topic" v-html="props.row.topic.content"></div>
                                <div v-if="props.row.reservation" v-html="props.row.reservation.detail"></div>
                            </el-form-item>
                            <el-form-item label="收藏时间">
                                <span>{{props.row.createTime }}</span>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
            </el-table-column>
                 <el-table-column label="类型" width="70">
                 <template slot-scope="t">
                   <el-tag :type="t.row.type == 'topic' ? 'warning' : 'success'"> {{getType(t.row.type)}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="主题">
                 <template slot-scope="t">
                    <el-link type="primary" @click="lookDetail(t.row)" :underline="false" icon="el-icon-star-on">{{getTitle(t.row)}}</el-link>
                </template>
            </el-table-column>
              <el-table-column label="备注" prop="remark">
                  <template slot-scope="r">
                      <el-link type="info" :underline="false"  @click="updateRemark(r.row.id)">{{r.row.remark}}</el-link>
                  </template>
            </el-table-column>
            <el-table-column label="收藏时间" prop="createTime">
                <template slot-scope="timeTemp">
                    <span>{{dateFormat(timeTemp.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot="header" slot-scope="searchFavo">
                    <el-input v-model="search" size="mini" :id="searchFavo" placeholder="搜索收藏"/>
                </template>
                <template slot-scope="favo">
                    <el-link type="primary" @click="userCancelFavorite(favo.row.id)"  :underline="false" icon="el-icon-remove-outline">取消收藏</el-link>
                </template>
            </el-table-column>
        </el-table>
        
        <el-empty v-if="!infos.length" description="没有收藏任何东西"></el-empty>
           <el-dialog title="预约事项详情" :visible.sync="detailDialog" width="80%">
            <div class="el-row">
                主题：<a :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">{{plan.title}} </a>

            </div><br>
            <el-divider content-position="center"><span style="font-size:12px;">预约事项说明</span></el-divider>
            <div class="block">
                <div class="el-row">
                       <span style="font-size:12px;">活动地点：<strong>{{plan.addr}}</strong></span><el-divider direction="vertical"></el-divider>
                    <span style="font-size:12px;">预约时间：<strong>{{plan.startTime}} 至 {{plan.endTime}}</strong></span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;"> 限制人数：{{plan.maxAmount}}</span>
                </div><br>
                  <el-collapse v-model="activeNames">
                      <el-collapse-item title="查看详细说明" name="planDetail">
                          <div class="mb-3" v-html="plan.detail">
                          </div>
                          <div class="tag-group" v-if="plan.submitTags">
                              <el-tag :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in plan.submitTags.split(',')" :key="index">{{item}}</el-tag>
                          </div>
                      </el-collapse-item>
                      <el-collapse-item title="预约时需填信息" name="planTags">
                          <div class="mb-3" v-html="plan.tags">
                          </div>
                      </el-collapse-item>
                  </el-collapse>
                <div>
                  <br>
                  预约链接：<a  :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">https://reservation.cstweb.top/#/reservation/detail?id={{plan.id}}</a>
                </div>
                <br>
            <span slot="footer" class="row justify-content-center">
                    <a class="btn btn-success" :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">立即预约</a>
           </span>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import {listFavo,cancelFavoriteById,updateRemark} from '@/api/favorite'
import { getDateDiff } from "@/util";
export default {
    name : 'UserFavorite',
    data () {
        return {
            infos:[],
            search:'',
            plan:'',
            detailDialog: false,
            activeNames: ['planDetail','planTags'],
        }
    },mounted () {
        this.init()
    }
    ,methods: {
        getType(type){
            if('topic' == type){
                return '帖子'
            }else if('reservation' == type){
                return '预约'
            }
        },async init(){
            let res = await listFavo();
            this.infos = res.data
        }, async userCancelFavorite(id) {
            let res = await cancelFavoriteById({ 'id':id});
            if (res.resultCode == 200) {
                if(res.data){
                     this.$message.success("已取消收藏");
                }
            } else {
                this.$message.error("取消收藏失败");
            }
            this.init()
        },getVo(vo){
            if(vo.type == 'topic'){
                return vo.topic
            }else if(vo.type == 'reservation'){
                return vo.reservation
            }
        },getTitle(vo){
            if(vo.type == 'topic'){
                return vo.topic ? vo.topic.title : '帖子已不存在'
            }else if(vo.type == 'reservation'){
                return vo.reservation ? vo.reservation.title : '预约已不存在'
            }
        },lookDetail(vo){
            if(!vo.topic && !vo.reservation){
                this.$message.warning('此收藏已失效')
                return;
             }
            if(vo.type == 'topic'){
                this.$router.push('/reservation/topicDetail?topicId='+vo.topic.id)
            }else if(vo.type == 'reservation'){
                this.plan = vo.reservation
                this.detailDialog = true
            }
        }, updateRemark(id){
            this.$prompt('请输入备注', '修改', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            }).then(async ({ value }) => {
                let res = await updateRemark({'id':id , 'remark':value})
                if(res.resultCode == 200){
                    this.$message.success('修改备注成功')
                    this.init()
                }else{
                    this.$message.warning('修改失败')
                }
            }).catch(() => {});
        },dateFormat(time){
            return getDateDiff(time)
        }
    }
}
</script>

<style scoped>
a:hover{
    text-decoration: none;
}
</style>