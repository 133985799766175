<template>
    <div>
        <el-table :data="order" style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <div style="margin-left:60px;">
                            <el-form-item label="提交信息">
                                <el-collapse v-if="props.row.content">
                                    <el-collapse-item title="查看详情" name="userInput">
                                        <p v-for="(info,index) in props.row.content.split(',')" :key="index">{{info.replace('@#@', '：')}} </p>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-form-item>
                            <el-form-item label="开始时间">
                                <span>{{ props.row.targetTime }}</span>
                            </el-form-item>
                            <el-form-item label="截止时间">
                                <span>{{ props.row.targetEndTime }}</span>
                            </el-form-item>
                            <el-form-item v-if="props.row.fileAddr" label="提交附件">
                                <a :href="props.row.fileAddr" target="_blank"><em>下载附件</em></a>
                            </el-form-item>
                            <el-form-item label="预约评价">
                                <div v-if=" props.row.rateInfo">
                                    <span v-for="(rate,index) in props.row.rateInfo.split('&&')" :key="index">
                                        {{getRateInfo(rate)}}
                                    </span>
                                  </div>
                                <div v-else>用户暂未评价</div>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="订单号" prop="id">
            </el-table-column>
            <el-table-column label="订单状态">
                <template slot-scope="stateProp"><span style="cursor:pointer;" @click="showOrderDetail(stateProp.row.id)">{{orderState(stateProp.row.state)}}</span></template>
            </el-table-column>
            <el-table-column label="用户" prop="createUserNickName">
                <template slot-scope="tempNickName">
                    <el-link :underline="false" type="primary" @click="$userInfoDialog(tempNickName.row.createUser);">{{tempNickName.row.createUserNickName}}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="下单时间" prop="createTime">
                <template slot="header" slot-scope="elmbug">
                    <el-input placeholder="查询订单" clearable :id="elmbug" v-model="searchInfo">
                        <el-button slot="append" @click="searchOrder" icon="el-icon-search"></el-button>
                    </el-input> 
                </template>
            </el-table-column>
            <el-table-column width="350">
               <template slot="header" slot-scope="elmbug">
                   <div style="float:right"><el-button type="primary" @click="exportData" :id="elmbug.row"  icon="el-icon-connection">导出</el-button></div>
               </template>
                <template slot-scope="temp">
                    <el-button icon="el-icon-s-check" @click="showUpdateOrderDialog(temp.row)">修改</el-button>
                    <el-button icon="el-icon-sold-out" @click="orderRefund(temp.row)">退款</el-button>
                    <el-button icon="el-icon-s-order" @click="showOrderDetail(temp.row.id)">订单情况</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="float:right;margin-top:20px" v-if="order">
            <el-pagination @current-change="handleCurrentChange" background layout="total, prev, pager, next" :page-size="10" :total="totalSize">
            </el-pagination>
        </div>
        <el-dialog title="订单详情" :visible.sync="orderDetailDialog" width="70%">
            <div style="font-size:13px"  v-if="orderDetail.plan">
                <span>预约事项：{{orderDetail.plan.title}}</span>
                <el-divider direction="vertical"></el-divider>
                允许时段：{{orderDetail.plan.startTime}} 至 {{orderDetail.plan.endTime}} <el-divider direction="vertical"></el-divider>
                模式:{{orderDetail.plan.needMoney ? '【需付费 '+handerMoeny(orderDetail.plan.needMoney)+'元】' : '免费预约'}}
                <el-divider direction="vertical"></el-divider><span> 订单号：{{curOrderId}}</span>
            </div>
            <el-divider content-position="center"><span style="font-size:12px;">预约时间线</span></el-divider>
            <div class="block">
                <el-timeline>
                    <el-timeline-item v-for="(vo, index) in orderDetail.steps" :key="index" :icon="vo.remark.split('_')[0]" :type="vo.remark.split('_')[1]" :size="'large'" :timestamp="vo.createTime">
                        {{vo.title }}{{vo.remark.split('_')[2] != '无'? (' ('+vo.remark.split('_')[2]+')') : ''}}
                    </el-timeline-item>
                </el-timeline>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="orderDetailDialog = false">知道了</el-button>
            </span>
        </el-dialog>

        <el-dialog title="订单状态修改" :visible.sync="updateOrderDialog" width="70%">
            <div class="block">
                <el-form :model="curStep">
                    <el-form-item label="订单ID" :label-width="formLabelWidth">
                        <el-input v-model="curStep.infoId" disabled autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="状态标题" :label-width="formLabelWidth">
                        <el-input placeholder="例如：预约成功" v-model="curStep.title" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="状态码" :label-width="formLabelWidth">
                        <el-input placeholder="参见系统说明书订单状态码" v-model="curStep.state" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="标识与备注" :label-width="formLabelWidth">
                        <el-input placeholder="例如：el-icon-check_success_无" v-model="curStep.iconAndRemark" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateOrderDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate" :loading="updateLoading">确定修改</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>

<script>
import {
    listOrder,
    updateOrder,
    refund,
    getOrderStep,
} from "@/api/admin/reservationOrder.js";
import { regFenToYuan } from "@/util";
export default {
    name: "ReservationOrderManage",
    data() {
        return {
            order: [],
            searchInfo: "",
            totalSize: 0,
            orderDetailDialog: false,
            orderDetail: { steps: [], plan: "" },
            curStep: {
                infoId: "",
                state: "",
                title: "",
                iconAndRemark: "",
            },
            formLabelWidth: "120px",
            curOrderId: "",
            updateOrderDialog: false,
            updateLoading: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let res = await listOrder({
                id: "",
                content: "",
                pageNo: 1,
                pageSize: 10,
            });
            this.order = res.data;
            this.totalSize = res.totalSize;
        },
        orderState(state) {
            if (state == "00") {
                return "进行中，待签到";
            } else if (state == "10") {
                return "待审核";
            } else if (state == "01") {
                return "进行中，待支付";
            } else if (state == "02") {
                return "已完成";
            } else if (state == "06") {
                return "交易成功，待评价";
            } else if (state == "03") {
                return "已取消";
            } else if (state == "04") {
                return "已过期";
            } else if (state == "05") {
                return "进行中，已签到";
            } else {
                return "订单异常";
            }
        },
        async handleCurrentChange(val) {
            let vo = {
                pageNo: val,
                pageSize: 10,
                id: "",
                content: this.searchInfo,
            };
            let res = await listOrder(vo);
            this.totalSize = res.totalSize;
            this.order = res.data;
        },
        async searchOrder() {
            let vo = {
                pageNo: 1,
                pageSize: 10,
                id: "",
                content: this.searchInfo,
            };
            let res = await listOrder(vo);
            this.totalSize = res.totalSize;
            this.order = res.data;
        },
        async showOrderDetail(id) {
            this.orderStep = [];
            this.curOrderId = id;
            this.orderDetailDialog = true;
            let res = await getOrderStep({ infoId: id });
            if (res.resultCode == 200 && res.data) {
                this.orderDetail.steps = res.data.steps;
                this.orderDetail.plan = res.data.plan;
            } else {
                this.$message.warning("暂无订单流程信息");
            }
        },
        async showUpdateOrderDialog(vo) {
            this.curStep.infoId = vo.id;
            this.updateOrderDialog = true;
        },
        async submitUpdate() {
            console.log(this.curStep);
            this.updateLoading = true;
            let update = await updateOrder(this.curStep);
            if (update.resultCode == 200) {
                this.updateOrderDialog = false;
                this.$message.success("修改成功");
                this.searchOrder();
            } else {
                this.$message.console.error(update.resultMsg);
            }
            this.updateLoading = false;
        },
        handerMoeny(fen) {
            if (fen <= 0) return 0;
            return parseFloat(regFenToYuan(fen));
        },
        orderRefund(vo) {
            this.$prompt("请输入退款原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(({ value }) => {
                    console.log(value);
                    if (value) {
                        let reason = value;
                        this.$prompt("请输入退款金额（单位：分）", "重要提示", {
                            confirmButtonText: "确定退款",
                            cancelButtonText: "取消",
                            inputPattern:
                                /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                            inputErrorMessage: "金额格式不正确",
                        })
                            .then(async ({ value }) => {
                                if (value) {
                                    let request = {
                                        orderId: vo.id,
                                        refundMoney: value,
                                        reason: reason,
                                        toUserId: vo.createUser,
                                    };
                                    let res = await refund(request);
                                    if (res.resultCode == 200) {
                                        alert("退款成功！");
                                        this.searchOrder();
                                    } else {
                                        alert("退款失败！" + res.resultMsg);
                                    }
                                }
                            })
                            .catch(() => {});
                    } else {
                        alert("退款原因不能为空");
                    }
                })
                .catch(() => {});
        }, exportData(){
          window.location.href = "/api/admin/reservationOrder/export?content="+this.searchInfo+'&token='+localStorage.getItem('cst-token')
        },getRateInfo(rate){
          let str = rate.split('=');
          if(str[0] == 'A'){
            return '流程评分('+str[1] +') '
          }else if(str[0] == 'B'){
            return '服务评分('+str[1] +') '
          }else if(str[0] == 'C'){
            return '体验评分('+str[1] +') '
          }else{
            return ' 用户留言评论：'+str[1]
          }
        }
    },
};
</script>