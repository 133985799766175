import requests  from "../request";


export const luckGo = (vo) => {
    return requests({
         url:'/api/luck/go', method:'post',data:vo
     })
 }
export const luckGoNotFree = (vo) => {
    return requests({
         url:'/api/luck/goByNotFree', method:'post',data:vo
     })
 }

 export const getTimes = () => {
    return requests({
         url:'/api/luck/getTimes', method:'get'
     })
 }
 
 export const listGift = () => {
    return requests({
         url:'/api/luck/list', method:'get'
     })
 }

