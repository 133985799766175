import axios from "axios";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import {Message} from 'element-ui'
axios.defaults.withCredentials = true;
const requests = axios.create({
    timeout: "5000",
})

//请求拦截器
requests.interceptors.request.use((config) => {
    //进度条开始
    nprogress.start();
    config.headers["cst-token"] = localStorage.getItem("cst-token");
    config.headers["Content-Type"] = 'application/json;charset=UTF-8';
    return config;
},err=> {
    Message.error({message: '请求超时，网络不太稳定哦~'});
    return Promise.resolve(err);
})

//相应拦截器
requests.interceptors.response.use((res) => {
    nprogress.done();
    if(res.data.resultCode == 402){
        localStorage.removeItem('cst-token')
        Message.error({message: '状态已失效，请重新登录~'});
    }else if(res.data.resultCode == 401){
        localStorage.removeItem('cst-token')
        // Message.warning({dangerouslyUseHTMLString: 
        // true,message: '登录状态已过期，请'+'<a href=\'https://reservation.cstweb.top/#/login\'>'+' 重新登录'+'</a>'});
    }
    return res.data;
},(error) => {
    nprogress.done();
    Message.error({message: '网络或服务器无响应!'});
})


export default requests;