import requests  from "../request";


export const list = () => {
    return requests({
         url:'/api/admin/reward/list', method:'post'
     })
 }

 
export const add = (vo) => {
    return requests({
         url:'/api/admin/reward/add', method:'post',params:vo
     })
 }

 
export const del = (id) => {
    return requests({
         url:'/api/admin/reward/del', method:'post',params:id
     })
 }