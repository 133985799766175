import requests  from "../request";

export const listPlan = (vo) => {
    return requests({
         url:'/api/admin/reservationPlan/list', method:'post',data:vo
     })
 }

export const ban = (vo) => {
    return requests({
         url:'/api/admin/reservationPlan/ban', method:'post',params:vo
     })
 }
export const updatePlan = (vo) => {
    return requests({
         url:'/api/admin/reservationPlan/updatePlan', method:'post',data:vo
     })
}