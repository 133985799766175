<template>
    <div id="wrapper">
        <!-- <el-row style="height: 10%">
            <el-col :span="12">
                <h2>聊天日志</h2>
            </el-col>
            <el-col :span="12" style="text-align: right;margin: auto 20px auto 0;">当前在线人数： {{ onlineCount }}</el-col>
        </el-row>
        <el-row style="height: 80%">
            <el-col :span="24">
                <p v-for="(message, index) in chatList" :key="index">{{message}}</p>
            </el-col>
        </el-row> -->
        <div>
            <beautiful-chat placeholder="请输入..." :title="chatRoomTitle" :participants="participants" :titleImageUrl="titleImageUrl" :alwaysScrollToBottom="true" :onMessageWasSent="onMessageWasSent" :messageList="messageList" :newMessagesCount="newMessagesCount" :isOpen="isChatOpen" :close="closeChat" :open="openChat" :showEmoji="true" :showEdition="false" :showDeletion="false" :messageStyling="messageStyling" :showTypingIndicator="showTypingIndicator" :showLauncher="true" :showCloseButton="true" :colors="colors" :disableUserListToggle="disableUserListToggle" @onType="handleOnType">
                <template v-slot:system-message-body="{ message }">
                    [系统通知]: {{message.text}}
                </template>
                <template v-slot:text-message-body="{ message }">
                    <span v-if="!message.author" :style="{color:'#5D5E5F',fontSize: 10 + 'px'}"> {{message.data.text}}</span>
                    <span v-else>{{message.data.text}}</span>
                </template>

            </beautiful-chat>
            <!-- <a href="#" @click.prevent="openChat()">Open the chat window</a> -->

        </div>
    </div>
</template>

<script>
export default {
    name: "ChatRoom",
    data() {
        return {
            chatList: [],
            onlineCount: 0,
            customerMessage: "",
            socketInfo: {
                path:
                    "wss://reservation.cstweb.top/websocket/" +
                    localStorage.getItem("cst-token"),
                socket: null,
            },
            participants: [],
            titleImageUrl: "",
            messageList: [],
            newMessagesCount: 0,
            isChatOpen: false, // 确定聊天窗口应该打开还是关闭
            showTypingIndicator: "", // 当设置为匹配参与者的值时。它显示特定用户的输入指示
            disableUserListToggle: false,
            showUserList: false,
            chatRoomTitle: "聊天大厅",
            messageStyling: true,
            colors: {
                header: {
                    bg: "#4e8cff",
                    text: "#ffffff",
                },
                launcher: {
                    bg: "#4e8cff",
                },
                messageList: {
                    bg: "#ffffff",
                },
                sentMessage: {
                    bg: "#4e8cff",
                    text: "#ffffff",
                },
                receivedMessage: {
                    bg: "#eaeaea",
                    text: "#222222",
                },
                userInput: {
                    bg: "#f4f7f9",
                    text: "#565867",
                },
            },
        };
    },
    computed: {
        user() {
            return this.$store.state.user.userInfo;
        },
    },
    mounted() {
        // 初始化 WebSocket
        this.init();
    },
    methods: {
        init() {
            if (
                !localStorage.getItem("cst-cur-username") ||
                !localStorage.getItem("cst-token")
            ) {
                this.$message.warning("请先登录，才可进行在线群聊！");
                return;
            }
            const socket = new WebSocket(
                "wss://reservation.cstweb.top/websocket/" +localStorage.getItem("cst-token")
                // "ws://127.0.0.1:7878/websocket/" +localStorage.getItem("cst-token")
            );
            socket.onmessage = this.getMessage;
            socket.onopen = this.socketOpen;
            socket.onclose = this.socketClose;
            socket.onerror = this.socketError;
            this.socketInfo.socket = socket;
        },
        socketOpen() {
            console.log("进入群聊，已连接成功");
            // this.chatList.push("您已经进入聊天室，可以开始聊天了！");
        },
        socketError() {
            this.$notify({
                title: "群聊",
                message: "连接已断开",
                type: "warning",
            });
        },
        socketClose() {
            // this.$notify({
            //     title: "提示",
            //     message: "已离开群聊",
            //     type: "success",
            // });
        },
        getMessage(message) {
            const info = JSON.parse(message.data);
            let me = localStorage.getItem("cst-cur-username");
            if (info.type == "newOpen") {
                var oldCount = localStorage.getItem("chat-read-count") || 0;
                var newCount = info.messageInfos.length || 0;
                this.messageList = [];
                info.messageInfos.forEach((message) => {
                    if (message.author == me) {
                        message.author = "me";
                    }
                    this.messageList.push(message);
                });
                if (info.users) {
                    this.participants = [];
                    info.users.forEach((user) => {
                        this.participants.push({
                            id: user.username,
                            name: user.nickname,
                            imageUrl: user.avatarAddr
                                ? user.avatarAddr
                                : "https://reservation-platform.oss-cn-shenzhen.aliyuncs.com/images/defaultAvatar.png?versionId=CAEQMRiBgMCc8O2igRgiIDQwMjA3ODBlNzRiZTQ3MWU4N2Y1MzFmZTk5NDJmYjJi",
                        });
                    });
                }
                this.onlineCount = info.totalOnline;
                if (info.newMessage) {
                    this.messageList.push(info.newMessage);
                }
                this.newMessagesCount = newCount - oldCount;
            } else {
                if (info.author == localStorage.getItem("cst-cur-username")) {
                    info.author = "me";
                } else {
                    this.chatList.push(info);
                    this.onlineCount = info.totalOnline;
                    this.messageList = [...this.messageList, info];
                }

                this.newMessagesCount = this.newMessagesCount + 1;
            }
            this.chatRoomTitle =
                "预约平台-聊天室(" +
                this.onlineCount +
                "/" +
                this.participants.length +
                ")";
        },
        onMessageWasSent(message) {
            // this.messageList = [ ...this.messageList, message ]
        },
        openChat() {
            this.isChatOpen = true;
            this.newMessagesCount = 0;
        },

        onMessageWasSent(message) {
            if (!localStorage.getItem("cst-cur-username")) {
                this.$message.warning("请先登录！");
                return;
            }
            if (JSON.stringify(message.data).length > 600) {
                this.$message.warning("输入字数超过限制！");
                return;
            }
            // 当用户发送消息时调用
            this.messageList = [...this.messageList, message];
            this.chatList.push(message);

            let vo = {
                author: localStorage.getItem("cst-cur-username"),
                type: message.type,
                data: message.data,
            };
            //  this.messageList.push(message)
            setTimeout(() => {
                this.socketInfo.socket.send(JSON.stringify(vo));
            }, 300);
        },
        openChat() {
            // 当用户单击fab按钮打开聊天时调用
            this.isChatOpen = true;
            this.newMessagesCount = 0;
            localStorage.setItem(
                "chat-read-count",
                this.messageList.length || 0
            );
        },
        closeChat() {
            // // 当用户单击按钮关闭聊天时调用
            this.isChatOpen = false;
            localStorage.setItem(
                "chat-read-count",
                this.messageList.length || 0
            );
            this.newMessagesCount = 0;
        },
        handleScrollToTop() {
            // 当用户将消息列表滚动到顶部时调用
            // 利用分页来加载另一个消息页面
        },
        handleOnType() {
            // console.log("Emit typing event");
        },
    },
    destroyed() {
        this.socketInfo.socket.close();
    },
    
};
</script>

<style>
</style>