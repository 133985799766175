<template>
    <div>
        <el-table :data="topics.filter(data => !search || data.id.toLowerCase().includes(search.toLowerCase())
             || data.createTime.includes(search) || data.content.includes(search) || data.title.includes(search) || data.nickname.includes(search)   )  " height="600" ref="multipleTable" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="title" label="标题" show-overflow-tooltip width="120">
            </el-table-column>
            <el-table-column prop="content" label="内容" show-overflow-tooltip width="420">
                 <template slot-scope="scope"><div v-html="scope.row.content"></div></template>
            </el-table-column>
            <el-table-column prop="tags" label="标签" show-overflow-tooltip width="120">
            </el-table-column>
            <el-table-column prop="likeUser" label="点赞数" show-overflow-tooltip width="70">
                 <template slot-scope="scope">{{ scope.row.likeUser ? scope.row.likeUser.split(',').length : 0 }}</template>
            </el-table-column>
            <el-table-column label="发帖人" >
                <template  slot-scope="scope"><el-link type="primary"  @click="$userInfoDialog(scope.row.createUser);">{{ scope.row.nickname }}</el-link ></template>
            </el-table-column>
            <el-table-column label="时间" width="220">
                <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column>
                <template slot="header" slot-scope="searchInfo">
                    <el-input v-model="search" size="mini" :id="searchInfo" placeholder="搜索帖子信息" />
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" @click="showComment(scope.row)">查看评论</el-button>
                    <el-button size="mini" @click="deleteTopicOne(scope.row)" type="warning">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="float:right;margin-top:10px">
            <el-button @click="deleteTopic" icon="el-icon-scissors" type="danger">批量删除</el-button>
        </div>
        <el-dialog title="评论信息" :visible.sync="commentDialog" width="85%">
            <el-table :data="comments">
                <el-table-column property="nickname" label="评论人" width="200">
                        <template  slot-scope="scope"><el-link type="primary"  @click="$userInfoDialog(scope.row.createUser);">{{ scope.row.nickname }}</el-link ></template>
                </el-table-column>
                <el-table-column property="content" label="评论内容" show-overflow-tooltip  width="150"></el-table-column>
                <el-table-column property="createTime" label="评论时间"></el-table-column>
            </el-table>
              <span slot="footer" class="dialog-footer">
                <el-button @click="commentDialog = false">取 消</el-button>
                <el-button type="primary" @click="$router.push('/adminHome/comment?topicId='+curTopic.id)">管理评论</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { list, listComment, delTopic } from "@/api/admin/topic";
export default {
    name: "TopicManage",
    data() {
        return {
            topics: [],
            comments: [],
            multipleSelection: [],
            search: "",
            commentDialog : false,
            curTopic:''
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let res = await list();
            if (res.resultCode == 200) {
                this.topics = res.data;
            }
        },toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async getComments(topicId) {
            let res = await listComment({ topicId: topicId });
            if (res.resultCode == 200) {
                this.comments = res.data;
            }
        },
        deleteTopic() {
            if (this.multipleSelection.length == 0) {
                this.$message.warning("请先选择要删除的帖子");
                return;
            }
            let ids = [];
            let infos = [];
            for (
                let index = 0;
                index < this.multipleSelection.length;
                index++
            ) {
                ids.push(this.multipleSelection[index].id);
                infos.push(this.multipleSelection[index].title + "  ");
            }
            if (infos.length == this.topics.length) {
                this.$message.error("危险提示：请不要删除所有数据");
                return;
            }

            this.$prompt(
                "确定删除帖子吗? 删除后无法恢复， 共" +
                    this.multipleSelection.length +
                    "条待删除信息：" +
                    infos.toString() +
                    " ，请输入删除原因:",
                "删除帖子",
                {
                    confirmButtonText: "确定删除并通知发布者",
                    cancelButtonText: "算了",
                    type: "warning",
                }
            )
                .then(async ({ value }) => {
                    if (!value) {
                        this.$message.warning("原因不能为空！");
                        return;
                    }
                    console.log('ids.toString() ',ids.toString());
                    let res = await delTopic({
                        ids: ids.toString(),
                        reason: value,
                    });
                    if (res.resultCode == 200) {
                        this.$message.success("帖子删除成功");
                        this.init();
                    } else {
                        this.$message.warning(res.resultMsg);
                    }
                })
                .catch(() => {});
        },async showComment(vo){
            this.curTopic = vo
            let res = await listComment({'topicId' : vo.id})
            if(res.resultCode == 200){
                this.comments = res.data
            }
            this.commentDialog = true
        }, deleteTopicOne(vo) {
            this.$prompt(
                "确定删除帖子吗? 删除后无法恢复 【帖子："+vo.title+"】 请输入删除原因:",
                "删除帖子",
                {
                    confirmButtonText: "确定删除并通知发布者",
                    cancelButtonText: "算了",
                    type: "warning",
                }
            )
                .then(async ({ value }) => {
                    if (!value) {
                        this.$message.warning("原因不能为空！");
                        return;
                    }
                    let res = await delTopic({
                        ids: vo.id,
                        reason: value,
                    });
                    if (res.resultCode == 200) {
                        this.$message.success("帖子删除成功");
                        this.init();
                    } else {
                        this.$message.warning(res.resultMsg);
                    }
                })
                .catch(() => {});
        }
    },
};
</script>

<style>
</style>