<template>
    <div>
        <el-table :data="tableData.filter(data => !search || data.id.toLowerCase().includes(search.toLowerCase())
             || data.createTime.includes(search) || data.content.includes(search) || data.title.includes(search)  || (data.remark && data.remark.includes(search)))  " height="600" ref="multipleTable" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="120">
            </el-table-column>
            <el-table-column prop="content" label="内容" show-overflow-tooltip width="320">
            </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip width="420">
            </el-table-column>
            <el-table-column prop="type" label="类型" width="120">
                <template slot-scope="temp">
                    {{getType(temp.row.type)}}
                </template>
            </el-table-column>
            <el-table-column label="时间" width="220">
                <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column>
                <template slot="header" slot-scope="searchInfo">
                    <el-input v-model="search" size="mini" :id="searchInfo" placeholder="搜索通知信息" />
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" @click="showDetail(scope.row)">详情</el-button>
                    <el-button size="mini" @click="deleteNotify(scope.row)" type="warning">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="通知详情" :visible.sync="detailDialog" width="70%">
            <div class="block">
                <span class="demonstration">{{detail.remark}}</span>
                <el-carousel indicator-position="outside" v-if="curNotify.imageUrl">
                    <el-carousel-item v-for="(img,index) in curNotify.imageUrl.split('&**&')" :key="index">
                        <div style="text-align:center"><img height="200px" :src="img" alt="图片"></div>
                    </el-carousel-item>
                </el-carousel>
                <p>该消息发送给：{{detail.toUsers}}</p><br>
                <p>跳转URL：{{detail.clickUrl}}</p>
                <p>发布者：<el-link @click="$userInfoDialog(curNotify.createUser);" type="primary">{{detail.createUser}}</el-link>
                </p>
            </div>
        </el-dialog>

        <el-dialog title="发布通知" :visible.sync="createNotifyDialog" width="70%">
            <div class="block">
                <el-input placeholder="请输入标题, 如需实时弹窗，请将标题设置为： 新消息" v-model="title">
                </el-input><br><br>
                <el-input type="textarea" rows="10" placeholder="请输入通知内容" v-model="content" maxlength="200" show-word-limit>
                </el-input><br><br>

                发送给： <el-select multiple size="medium" v-model="toUser" filterable placeholder="请选择" width="500">
                    <el-option v-for="item in userList" :key="item.value" :label="item.username+' ('+item.nickname+')'" :value="item.userId">
                    </el-option>
                </el-select>   <el-switch v-model="toAllUser" inactive-text="发给所有人"  active-color="#13ce66">
                </el-switch>
                <br><br>
                通知附带图片：<el-upload
                    class="upload-demo"
                    action="/api/file/notify/upload"
                    :headers="{ 'cst-token': token }"
                    :on-remove="handleRemove"
                    :on-success="successHander"
                    :file-list="fileList"
                    list-type="picture">
                    <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="createNotifyDialog = false">取 消</el-button>
                <el-button type="primary" @click="createNotify">立即发布</el-button>
            </span>
        </el-dialog>
        <div style="float:right;margin-top:10px">
            <el-button @click="createNotifyDialog = true" type="primary" icon="el-icon-s-promotion">发通知</el-button>
            <el-button @click="batchDeleteNotify" :disabled="isDisable" icon="el-icon-scissors" type="danger">批量删除</el-button>
        </div>
    </div>
</template>

<script>
import {list, deleteBatch,getNotifyDetail,getUserList,sendNotify} from "@/api/admin/notify";
export default {
    name: "NotifyManage",
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            search: "",
            isDisable: false,
            detailDialog: false,
            detail: "",
            curNotify: "",
            title: "",
            content: "",
            toUser: [],
            userList: "",
            createNotifyDialog: false,
            toAllUser: false,
            fileList:[]
        };
    },computed:{
          token() {
            return localStorage.getItem("cst-token");
        }
    },
    mounted() {
        this.init();
    },
    methods: {successHander(res){
        let img = {url: res.data}
        this.fileList.push(img)
    },
    handleRemove(file, fileList) {
       this.fileList.some((item, i) => {
        if (item.url === file.url) {
          this.fileList.splice(i, 1)
        }
      })
    },
        async init() {
            let res = await list();
            let user = await getUserList();
            if (res.resultCode == 200) {
                this.tableData = res.data;
            }
            if (user.resultCode == 200) {
                this.userList = user.data;
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getType(type) {
            if (type == "00") {
                return "普通通知";
            } else if (type == "01") {
                return "收入通知";
            } else if (type == "10") {
                return "私信";
            } else {
                return "系统通知";
            }
        },
        async deleteNotify(info) {
            this.$confirm("确定删除此通知吗? 内容：" + info.content, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let res = await deleteBatch({ ids: info.id });
                    if (res.resultCode == 200) {
                        this.$message.success("删除成功");
                        this.init();
                    } else {
                        this.$message.error("删除失败");
                    }
                })
                .catch(() => {});
        },
        batchDeleteNotify() {
            if (this.multipleSelection.length == 0) {
                this.$message.warning("请先选择要删除的通知");
                return;
            }
            let ids = [];
            let infos = [];
            for (
                let index = 0;
                index < this.multipleSelection.length;
                index++
            ) {
                ids.push(this.multipleSelection[index].id);
                infos.push(this.multipleSelection[index].content + "  ");
            }
            if (infos.length == this.tableData.length) {
                this.$message.error("危险提示：请不要删除所有数据");
                return;
            }
            this.$confirm(
                "确定删除通知吗? 删除后无法恢复， 共" +
                    this.multipleSelection.length +
                    "条待删除信息：" +
                    infos.toString(),
                "重要提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(async () => {
                    let res = await deleteBatch({ ids: ids.toString() });
                    if (res.resultCode == 200) {
                        this.$message.success("批量删除成功");
                        this.init();
                    } else {
                        this.$message.error("批量删除失败");
                    }
                })
                .catch(() => {});
        },
        async showDetail(vo) {
            this.curNotify = vo;
            let res = await getNotifyDetail({ id: vo.id });
            if (res.resultCode == 200) {
                this.detail = res.data;
                this.detailDialog = true;
            } else {
                this.$message.warning("暂无详情");
            }
        },
        async createNotify() {
            let urls = ''
            if(this.fileList.length > 0){
                for (let index = 0; index < this.fileList.length; index++) {
                   urls = urls + this.fileList[index].url +"&**&";
                }
            }
            let res = await sendNotify({'title':this.title,'content':this.content,'toUser':this.toUser.toString(),
                'toAllUser':this.toAllUser,
                'imageUrls':urls
            }
            )
            if(res.resultCode == 200){
                 this.$message.success("发布成功");
                 this.createNotifyDialog = false
                 this.init()
            }else{
                this.$message.error("发布失败");
            }
        },
    },
};
</script>

<style>
</style>