<template>
    <div class="main">
        <div v-for="(item,index) in privateMsg" :key="index" class="row" >
            <div class="dropdown-list-image mr-3">
                <img @click="$userInfoDialog(item.createUser)" width="50px" class="rounded-circle" :src="item.createUserAvatarAddr" alt="..." />
                <div v-if="item.isOnline" class="status-indicator bg-success"></div>
                <div v-else class="status-indicator"></div>
            </div>
            <div class="font-weight-bold">
                <div class="text-truncate">
                {{item.createUserNickname}}： {{item.content}}
                </div>
                <div class="small text-gray-500" style="margin-top:10px;font-size:10px;">
                    {{item.createTime}}
                    <el-divider direction="vertical"></el-divider>
                    <el-link @click="$userInfoDialog(item.createUser)"  type="primary" :underline="false"><span style="font-size:10px;"> 回复</span></el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link @click="deleteMsg(item.id)" type="warning" :underline="false"> <span style="font-size:10px;"> 删除</span></el-link>
                </div>
            </div>
            <el-divider></el-divider>
        </div>
  </div>
</template>

<script>
import {listPrivateMsg,deletePrivateMsg } from "@/api/notify";
export default {
    name :'PrivateMsgDetail',
    data () {
        return {
            privateMsg:[]
        }
    },mounted () {
        this.initPrivateMsg()
    },methods: {
        async initPrivateMsg() {
            let res = await listPrivateMsg();
            if (res.resultCode == 200 && res.data) {
                this.privateMsg = res.data;
            }
        },deleteMsg(id){
            this.$confirm('此操作将永久删除此私信?', '提示', {
            confirmButtonText: '确定删除',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async () => {
                let res = await deletePrivateMsg({'id':id})
                if(res.resultCode == 200){
                    this.$message.success('删除私信成功');
                    this.initPrivateMsg()
                }else{
                    this.$message.warning('删除失败');
                }
            }).catch(() => {});
        }
    }
}
</script>

<style>
.main {
    width: 75%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
a:hover {
    text-decoration: none;
}
</style>