import requests  from "../request";


export const favorite = (vo) => {
    return requests({
         url:'/api/favorite', method:'post',data:vo
     })
 }


export const cancelFavoriteById = (vo) => {
    return requests({
         url:'/api/favorite/cancelFavoriteById', method:'post',params:vo
     })
 }
 

 export const check = (vo) => {
    return requests({
         url:'/api/favorite/check', method:'post',data:vo
     })
 }

 export const listFavo = (vo) => {
    return requests({
         url:'/api/favorite/list', method:'get'
     })
 }
 
 export const updateRemark = (vo) => {
    return requests({
         url:'/api/favorite/updateRemark', method:'post', params:vo
     })
 }
 