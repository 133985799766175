import requests  from "../request";


export const uploadImage = (file) => {
    var formData = new FormData();
    formData.append('image',file);
    return requests({
         url:'/api/file/imageHome/upload', method:'post',data:formData, headers: { 'Content-Type': 'multipart/form-data' }
     })
 }

export const listFile = () => {
    return requests({
         url:'/api/file/getUserFiles', method:'get'
     })
 }

 

export const deleteFile = (vo) => {
    return requests({
         url:'/api/file/delete', method:'post',params:vo
     })
 }

export const updateRemark = (vo) => {
    return requests({
         url:'/api/file/updateRemark', method:'post',params:vo
     })
 }

 