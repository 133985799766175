import { getUserInfo, updateUserInfo } from "@/api/userApi";
import { uploadUserAvatar } from "@/api/userApi";
import {Message} from 'element-ui'
const actions = {
    // async userInfo({context,commit}){
    //    let result =  await getUserInfo();
    //    console.log(context);
    //    console.log(result.data);
    //    if(result.resultCode == 200){
    //        commit('USER_INFO',result.data);
    //    }
    // }
    async userInfo(context){
        let result = await getUserInfo();
        console.log('result.data.username',result.data.username);
        if(result.data){
            localStorage.setItem('cst-cur-username',result.data.username)
        }
        if(result.resultCode == 200){
            context.commit('USER_INFO',result.data)
        }
    },
    async updateUserInfo(context,userInfo){
        console.log('userInfo=',userInfo);
        let result = await updateUserInfo(userInfo);
        if(result.resultCode == 200){
            Message.success("更新成功!");
            context.commit('UPDATE_USER_INFO',userInfo)
        }else{
            Message.error("更新失败!");
        }
    }
};
 
const mutations={
    USER_INFO(state,userInfo){
        state.userInfo = userInfo
    },
    UPDATE_USER_AVATAR(state,addr){
        console.log('addr =',addr);
        state.userInfo.avatarAddr = addr
    },
    UPDATE_USER_INFO(state,userInfo){
        state.userInfo.name = userInfo.name
        state.userInfo.nickname = userInfo.nickname
        state.userInfo.brief = userInfo.brief
        state.userInfo.tel = userInfo.tel
    }
};

const state = {
    userInfo:''
};

const getters = {}
export default{
    state,mutations,actions,getters
}