<template>

    <div id="wrapper">
        <!-- 左侧 -->
        <el-drawer :size="'mb-4'" :modal="false" :close-on-press-escape="true" :visible.sync="drawer" :with-header="false" :direction="direction">
            <div>
                <!-- Sidebar -->
                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                    <!-- Sidebar - Brand -->
                    <a class="
              sidebar-brand
              d-flex
              align-items-center
              justify-content-center
            ">
                        <div class="sidebar-brand-icon rotate-n-15">
                            <i class="fas fa-laugh-wink"></i>
                        </div>
                        <div class="sidebar-brand-text mx-3">在线预约平台</div>
                    </a>

                    <!-- Divider -->
                    <hr class="sidebar-divider my-0" />

                    <!-- Nav Item - Dashboard -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/reservation/plan">
                            <i class="fas fa-fw fa-tachometer-alt"></i>
                            <span  @click="drawer = false">预约首页</span></router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-expanded="false">预约模块</a>
                        <div class="dropdown-menu" aria-labelledby="dropdown01">
                            <router-link to="/reservation/plan?content=医疗">
                                <button @click="drawer = false" class="dropdown-item">
                                    <i class="fas fa-hospital fa-1x"></i> 医疗类
                                </button>
                            </router-link>
                            <router-link to="/reservation/plan?content=校园生活">
                                <a class="dropdown-item"><i class="fas fa-clipboard-list fa-1x"></i> 校园生活类 </a>
                            </router-link>
                            <router-link to="/reservation/plan?content=物品交易">
                                <a class="dropdown-item"><i class="fas fa-handshake fa-1x"></i> 物品交易类 </a>
                            </router-link>
                            <router-link to="/reservation/plan?content=其它">
                                <a class="dropdown-item"><i class="el-icon-box fa-1x"></i> 更多事宜 </a>
                            </router-link>
                        </div>
                    </li>
                    <!-- Divider -->
                    <hr class="sidebar-divider" />

                    <!-- Heading -->
                    <div class="sidebar-heading">分类</div>
                    <li class="nav-item">
                        <router-link to="/reservation/plan">
                            <a class="nav-link collapsed" @click="drawer = false" aria-expanded="true">
                                <i class="fa fa-american-sign-language-interpreting"></i>
                                <span>立即预约</span>
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link collapsed" @click="startReservationVisible = true " aria-expanded="true">
                            <i class="fa fa-paper-plane"></i>
                            <span style="cursor:pointer;" @click="drawer = false">发布预约</span>
                        </a>
                    </li>

                    <!-- Nav Item - Utilities Collapse Menu -->
                    <li class="nav-item">
                        <router-link to="/reservation/order">
                            <a class="nav-link collapsed" @click="drawer = false" aria-expanded="true">
                                <i class="el-icon-date"></i>
                                <span>预约订单</span>
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/reservation/payRecord">
                            <a class="nav-link collapsed" @click="drawer = false" aria-expanded="true">
                                <i class="el-icon-shopping-bag-1"></i>
                                <span>交易记录</span>
                            </a>
                        </router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                            <i class="fa fa-eye"></i>
                            <span>浏览记录</span>
                        </a>
                        <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                            <div class="bg-white py-2 collapse-inner rounded">
                                <h6 class="collapse-header">Custom Utilities:</h6>
                                <a class="collapse-item" >您无权限</a>
                                <a class="collapse-item" >您无权限</a>
                                <a class="collapse-item" >您无权限</a>
                                <a class="collapse-item" >您无权限</a>
                            </div>
                        </div>
                    </li> -->

                    <!-- Divider -->
                    <hr class="sidebar-divider" />

                    <!-- Heading -->
                    <div class="sidebar-heading">个性化</div>

                    <!-- Nav Item - Pages Collapse Menu -->
                    <li class="nav-item active">
                        <a class="nav-link" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
                            <i class="fas fa-fw fa-folder"></i>
                            <span>文件库</span>
                        </a>
                        <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                            <div class="bg-white py-2 collapse-inner rounded">
                                <h6 class="collapse-header">存储库:</h6>
                                <router-link to="/reservation/fileHome">
                                    <a class="collapse-item" @click="drawer = false">我的文件库</a>
                                </router-link>
                            </div>
                        </div>
                    </li>

                    <!-- Nav Item - Charts -->
                    <li class="nav-item">
                        <router-link to="/reservation/luckCircle">
                            <a class="nav-link" @click="drawer = false">
                                <i class="fa fa-cubes"></i>
                                <span>热门活动</span></a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/reservation/forumArea">
                            <a class="nav-link" @click="drawer = false">
                                <i class="el-icon-chat-dot-round"></i>
                                <span>论坛区</span></a>
                        </router-link>
                    </li>
                    <!-- Divider -->
                    <hr class="sidebar-divider d-none d-md-block" />

                    <!-- Sidebar Toggler (Sidebar) -->
                    <div class="text-center d-none d-md-inline">
                        <button class="rounded-circle border-0" @click="drawer = false" id="sidebarToggle"></button>
                    </div>
                </ul>
                <!-- End of Sidebar -->
            </div>
        </el-drawer>
        <!-- 核心区 -->
        <div id="content-wrapper" class="d-flex flex-column">
            <!-- Main Content -->
            <div id="content">
                <!-- 主页上侧 -->
                <div>
                    <!-- Topbar -->
                    <nav class="
              navbar navbar-expand navbar-light
              bg-white
              topbar
              mb-4
              static-top
              shadow
            ">
                        <el-button @click="drawer = true" type="primary" style="margin-left: 5px">
                            <i class="fa fa-bars"></i>
                        </el-button>
                        <!-- Sidebar Toggle (Topbar) -->
                        <!-- <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                            <i class="fa fa-bars"></i>
                        </button> -->
                        <!-- Topbar Search -->
                        <div class="
                d-none d-sm-inline-block
                form-inline
                mr-auto
                ml-md-3
                my-2 my-md-0
                mw-300
                navbar-search
              ">
                            <div class="input-group">
                                <input type="text" style="width:300px;" @keydown.enter="searchPlan" class="form-control bg-light border-0 small" placeholder="搜一搜预约"  id="searchInput" aria-label="Search" aria-describedby="basic-addon2" />
                                <div class="input-group-append">
                                    <button class="btn btn-primary"  type="button">
                                        <i class="fas fa-search fa-sm" @click="searchPlan"></i>
                                    </button>
                                </div>

                            </div>
                            
                        </div>
                       
                        <!-- Topbar Navbar -->
                        <ul v-if="user" class="navbar-nav ml-auto">
                            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
                            <li class="nav-item dropdown no-arrow d-sm-none">
                                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-search fa-fw"></i>
                                </a>
                                <!-- Dropdown - Messages -->
                                <div class="
                    dropdown-menu dropdown-menu-right
                    p-3
                    shadow
                    animated--grow-in
                  " aria-labelledby="searchDropdown">
                                    <form class="form-inline mr-auto w-100 navbar-search">
                                        <div class="input-group">
                                            <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" type="button">
                                                    <i class="fas fa-search fa-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </li>

                            <!-- Nav Item - Alerts -->
                            <li class="nav-item dropdown no-arrow mx-1">
                                <a class="nav-link dropdown-toggle" id="alertsDropdown" @click="openMsg" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-bell fa-fw"></i>
                                    <!-- Counter - Alerts -->
                                    <span v-if="newMsgNum>0" class="badge badge-danger badge-counter">{{newMsgNum}}</span>
                                </a>
                                <!-- Dropdown - Alerts -->
                                <div class="
                    dropdown-list dropdown-menu dropdown-menu-right
                    shadow
                    animated--grow-in
                  " aria-labelledby="alertsDropdown">
                                    <h6 class="dropdown-header">通知中心</h6>
                                    <a v-for="(item,index) in infos.slice(0,5)" :key="index" class="dropdown-item d-flex align-items-center">
                                        <div class="mr-3">
                                            <div v-if="item.type == '00'" class="icon-circle bg-primary">
                                                <i class="fas fa-file-alt text-white"></i>
                                            </div>
                                            <div v-else-if="item.type == '01'" class="icon-circle bg-success">
                                                <i class="fas fa-donate text-white"></i>
                                            </div>
                                            <div v-else-if="item.type == '03'" class="icon-circle bg-warning">
                                                <i class="fas fa-exclamation-triangle text-white"></i>
                                            </div>
                                        </div>
                                        <div @click="toDetail(item.clickUrl)" style="cursor:pointer;">
                                            <div class="small text-gray-500">{{item.title}}</div>
                                            <span class="font-weight-bold">{{item.content}}</span>
                                        </div>
                                    </a>
                                    <router-link to="/reservation/notifyDetail">
                                        <a class="dropdown-item text-center small text-gray-500" @click="openMsgMore">查看详情</a>
                                    </router-link>
                                </div>
                            </li>

                            <!-- Nav Item - Messages -->
                            <li class="nav-item dropdown no-arrow mx-1">
                                <a class="nav-link dropdown-toggle" id="messagesDropdown" @click="openPrivateMsg" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-envelope fa-fw"></i>
                                    <!-- Counter - Messages -->
                                    <span v-if="newPrivateMsgNum>0" class="badge badge-danger badge-counter">{{newPrivateMsgNum}}</span>
                                </a>
                                <!-- Dropdown - Messages -->
                                <div class="
                                        dropdown-list dropdown-menu dropdown-menu-right
                                        shadow
                                        animated--grow-in
                                    " aria-labelledby="messagesDropdown">
                                    <h6 class="dropdown-header">消息中心</h6>
                                    <a v-for="(item,index) in privateMsg.slice(0,5)" :key="index" class="dropdown-item d-flex align-items-center" @click="$userInfoDialog(item.createUser)">
                                        <div class="dropdown-list-image mr-3">
                                            <img class="rounded-circle" :src="item.imageUrl" alt="..." />
                                             <div v-if="item.isOnline" class="status-indicator bg-success"></div>
                                             <div v-else class="status-indicator"></div>
                                        </div>
                                        <div class="font-weight-bold">
                                            <div class="text-truncate">
                                                {{item.content}}
                                            </div>
                                            <div class="small text-gray-500">{{item.createTime}}</div>
                                        </div>
                                    </a>
                                     <a v-if="privateMsg.length == 0" class="dropdown-item d-flex align-items-center" >
                                        <div class="dropdown-list-image mr-3">
                                        </div>
                                        <div class="font-weight-bold">
                                            <div class="text-truncate">
                                                <el-empty description="暂时没有任何消息"></el-empty>
                                            </div>
                                            <div class="small text-gray-500"></div>
                                        </div>
                                    </a>
                                       <router-link to="/reservation/msgDetail">
                                             <a v-if="privateMsg.length" class="dropdown-item text-center small text-gray-500"  @click="openPrivateMsgMore" href="#">更多消息</a>
                                        </router-link>
                                </div>
                            </li>

                            <div class="topbar-divider d-none d-sm-block"></div>

                            <!-- Nav Item - User Information -->
                            <li class="nav-item dropdown no-arrow">
                                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{
                    user.nickname
                  }}</span>
                                    <img class="img-profile rounded-circle" :src="user.avatarAddr" />
                                </a>
                                <!-- Dropdown - User Information -->
                                <div class="
                    dropdown-menu dropdown-menu-right
                    shadow
                    animated--grow-in
                  " aria-labelledby="userDropdown">
                                    <router-link to="/reservation/userProfile/userInfo/show">
                                        <a class="dropdown-item">
                                            <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                            账户信息
                                        </a>
                                    </router-link>
                                    <router-link to="/reservation/order">
                                        <a class="dropdown-item">
                                            <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                            我的订单
                                        </a>
                                    </router-link>
                                    <router-link to="/reservation/myPlan">
                                        <a class="dropdown-item">
                                            <i class="el-icon-s-promotion mr-2 text-gray-400"></i>
                                            我的发布
                                        </a>
                                    </router-link>
                                    <router-link to="/reservation/favorite">
                                        <a class="dropdown-item">
                                            <i class="el-icon-star-on mr-2 text-gray-400"></i>
                                            我的收藏
                                        </a>
                                    </router-link>
                                    <router-link to="/reservation/payRecord">
                                        <a class="dropdown-item">
                                            <i class="el-icon-shopping-bag-1 mr-2 text-gray-400"></i>
                                            交易记录
                                        </a>
                                    </router-link>
                                    <router-link to="/reservation/userReward">
                                        <a class="dropdown-item">
                                            <i class="el-icon-present mr-2 text-gray-400"></i>
                                            积分记录
                                        </a>
                                    </router-link>
                                    <router-link to="/reservation/userCoupon">
                                        <a class="dropdown-item">
                                            <i class="el-icon-s-ticket mr-2 text-gray-400"></i>
                                            我的优惠卷
                                        </a>
                                    </router-link>
                                    <a @click="feedbackDialog = true" class="dropdown-item">
                                        <i class="el-icon-s-flag fa-sm fa-fw mr-2 text-gray-400"></i>
                                        用户反馈
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a @click="signOut" class="dropdown-item">
                                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        退出
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <router-link v-if="!user" to="/login">请先登录</router-link>
                    </nav>
                    <!-- End of Topbar -->
                </div>
                <!-- 核心内容 -->
                <!-- <Main /> -->
                <div class="container-fluid" style="min-height:400px;">
                    <keep-alive :include="['CreatePlan']">
                        <router-view />
                    </keep-alive>
                </div>
                <!-- 对话框 /> -->
                
            <el-dialog title="用户反馈" :visible.sync="feedbackDialog" :width="$_isMobile() ? '90%' : '50%'">
                <div class="block">
                    <el-input type="textarea" rows="12" placeholder="请输入您的看法、问题反馈或建议，我们将及时处理。" v-model="feedbackContent" maxlength="300" show-word-limit>
                    </el-input>
                    <br><br>
                    <el-input placeholder="请输入您的联系方式（可不填）" v-model="contactWay"></el-input>
                    <br><br>
                    <el-switch v-model="hideName" inactive-text="匿名反馈"  active-color="#13ce66">
                    </el-switch>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="feedbackDialog = false">取 消</el-button>
                    <el-button type="primary" @click="feedback">提 交</el-button>
                </span>
            </el-dialog>

            
        <el-dialog :visible.sync="startReservationVisible" title="选择预约模式" center="" width="60%">
            <div class="row" style="text-align:center;">
                <div class=" col-xl-6 col-md-6 mb-6" @click="createCommonPlan('common')" style="cursor:pointer;margin-top:15px;">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    通用预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-primary text-uppercase
                            mb-1
                          ">
                                    满足大部分预约需求，事项与流程相对固定，具有通用性~
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 mb-6"  @click="createCommonPlan('business')" style="cursor:pointer;margin-top:15px;">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-handshake fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    交易预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                    常用到涉及金钱、物品交易等事项，需要线下或线上进行
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" style="text-align:center;">
                <div class="col-xl-6 col-md-6 mb-6"  @click="createCommonPlan('quickly')"  style="cursor:pointer;margin-top:20px;">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-paper-plane fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    快速预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-success text-uppercase
                            mb-1
                          ">
                                    快捷为王！简易又快捷的事项，如收集报表、统计人数等
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 mb-6"  @click="createCommonPlan('medical')" style="cursor:pointer;margin-top:20px;">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-hospital fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    医疗预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-info text-uppercase
                            mb-1
                          ">
                                    适用于医疗的相关预约事项，如挂号预约、买药、核酸检测预约等
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </el-dialog>
            </div>
        </div>
    </div>

</template>
<script>
import HomeHeader from "@/components/reservation/HomeHeader.vue";
import Main from "@/components/reservation/Main.vue";
import HomeLeft from "@/components/reservation/HomeLeft.vue";
import HomeFooter from "@/components/reservation/HomeFooter.vue";
import { mapState } from "vuex";
import { logout,feedback } from "@/api/userApi";
import { notify } from "@/api/notify";
import { listNotify,listPrivateMsg } from "@/api/notify";
import QRCode from "qrcodejs2";
export default {
    name: "Reservation",
    data() {
        return {
            drawer: false,
            direction: "ltr",
            socketInfo: {
                path:
                    "wss://reservation.cstweb.top/websocket/" +
                    localStorage.getItem("cst-token"),
                socket: null,
            },
            infos: [],
            privateMsg: [],
            newMsgNum: 0,
            newPrivateMsgNum: 0,
            startReservationVisible: false,
            feedbackDialog:false,
            hideName:false,
            feedbackContent:'',
            contactWay:'',
          
        };
    },
    components: { HomeHeader, Main, HomeLeft, HomeFooter },
    mounted() {
        this.$store.dispatch("userInfo");
        this.initWebsocket();
        this.initNotify();
        this.initPrivateMsg();
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
        user() {
            return this.$store.state.user.userInfo;
        },
    },
    methods: {
        createCommonPlan(type) {
            this.startReservationVisible = false;
            if(type == 'common'){
                this.$router.push("/reservation/createPlan");
            }else if(type == 'business'){
                this.$router.push("/reservation/createPlanBusiness");
            }else if(type == 'quickly'){
                this.$router.push("/reservation/createPlanQuickly");
            }else{
                this.$router.push("/reservation/createPlanMedical");
            }
        },
        signOut() {
            this.$confirm("确定退出吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
             
                    let res = await logout();
                    if (res.resultCode == 200) {
                        localStorage.removeItem("cst-token");
                        localStorage.removeItem("cst-cur-username");
                        this.$router.replace("/login");
                    } else {
                        this.$message.warning("退出中...");
                        this.$router.replace("/login");
                    }
                })
                .catch(() => {});
        },
        initWebsocket() {
            const socket = new WebSocket(
                "wss://reservation.cstweb.top/notify/" +localStorage.getItem("cst-token")
                // "ws://127.0.0.1:7878/notify/" +localStorage.getItem("cst-token")
            );
            socket.onmessage = this.getMessage;
            socket.onopen = this.socketOpen;
            socket.onerror = this.socketError;
            this.socketInfo.socket = socket;
            console.log("已连接成功");
        },
        getMessage(message) {
            var msg = JSON.parse(message.data);
            let info = {
                title: msg.title,
                content: msg.content,
                createTime: msg.createTime,
            };
            if(msg.type != '10'){
                if ((msg.title == "新消息") || (msg.title == "系统通知")) {
                    this.$notify({
                        title: msg.title,
                        message: msg.content,
                        duration: 0,
                        type: msg.title == "新消息" ? "success" : "",
                    });
                } else {
                    this.infos.unshift(info);
                    this.newMsgNum++;
                }
            }else{
                 this.$notify({
                        title: msg.title,
                        message: msg.createUserNickname +'：'+msg.content,
                        duration: 0,
                        type: "success",
                 });
                 this.privateMsg.unshift(msg);
                this.newPrivateMsgNum++;
            }
        },
        socketOpen() {
            console.log("开启连接");
        },
        openMsg() {
            if (this.newMsgNum == 0) return;
            if (this.newMsgNum <= 5) {
                let oldCount =
                    parseInt(localStorage.getItem("cst-msg-read-count")) || 0;
                localStorage.setItem(
                    "cst-msg-read-count",
                    oldCount + this.newMsgNum
                );
                this.newMsgNum = 0;
                return;
            }
            this.newMsgNum = this.newMsgNum - 5 > 0 ? this.newMsgNum - 5 : 0;
            let oldCount =
                parseInt(localStorage.getItem("cst-msg-read-count")) || 0;
            localStorage.setItem("cst-msg-read-count", oldCount + 5);
        },openPrivateMsg(){
             this.initPrivateMsg()
             if (this.newPrivateMsgNum == 0) return;
             if (this.newPrivateMsgNum <= 5) {
                let oldCount =
                    parseInt(localStorage.getItem("cst-private-msg-read-count")) || 0;
                localStorage.setItem(
                    "cst-private-msg-read-count",
                    oldCount + this.newPrivateMsgNum
                );
                this.newPrivateMsgNum = 0;
                return;
            }
            this.newPrivateMsgNum = this.newPrivateMsgNum - 5 > 0 ? this.newPrivateMsgNum - 5 : 0;
            let oldCount = parseInt(localStorage.getItem("cst-private-msg-read-count")) || 0;
            localStorage.setItem("cst-private-msg-read-count", oldCount + 5);
            
        },
        openMsgMore() {
            this.newMsgNum = 0;
            localStorage.setItem("cst-msg-read-count", this.infos.length);
        }, openPrivateMsgMore() {
            this.newPrivateMsgNum = 0;
            localStorage.setItem("cst-private-msg-read-count", this.privateMsg.length);
        },
        async initNotify() {
            let res = await listNotify();
            if (res.resultCode == 200 && res.data) {
                this.infos = res.data;
            }
            if (this.infos) {
                let oldCount =
                    parseInt(localStorage.getItem("cst-msg-read-count")) || 0;
                this.newMsgNum = this.infos.length - oldCount;
            }
        },async initPrivateMsg() {
            let res = await listPrivateMsg();
            if (res.resultCode == 200 && res.data) {
                this.privateMsg = res.data;
            }
            if (this.privateMsg) {
                let oldCount = parseInt(localStorage.getItem("cst-private-msg-read-count")) || 0;
                this.newPrivateMsgNum = this.privateMsg.length - oldCount;
            }
        },
        async searchPlan() {
            let search = $("#searchInput").val();
            this.$children[2].searchInfo(search)
        },
        toDetail(url) {
            if (!url) {
                return;
            }
            if (url.indexOf("http") == -1) {
                this.$router.push(url);
            } else {
                window.location.href = url;
            }
        },async feedback(){
            if(!this.feedbackContent){
               this.$message.warning('请输入反馈的内容')
            }
            let res =  await feedback({'content':this.feedbackContent,'hideName':this.hideName,'contactWay':this.contactWay})
            if(res.resultCode == 200){
                 this.$message.success('反馈成功，感谢您的反馈。')
                 this.feedbackDialog = false
            }else{
                 this.$message.error('反馈失败')
            }
        }
    },
};
</script>
<style scoped>
a:hover {
    text-decoration: none;
}
.el-header,
.el-footer {
    background-color: #b3c0d1;
    text-align: center;
    height: 10%;
}

.el-main {
    background-color: #e9eef3;
    text-align: center;
    height: 600px;
}
.el-dialog > div > div > div:hover {
    transform: scale(1.06, 1.06);
    transition: 0.3s transform;
}
body > .el-container {
    margin-bottom: 40px;
}
a:hover {
    text-decoration: none;
}
</style>