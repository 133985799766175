//配置路由的地方
import Vue from 'vue';
import VueRouter from 'vue-router';

import Reservation from '@/components/reservation/Reservation.vue'
import Login from '@/components/user/Login.vue'
import UserProfile from '@/pages/user/UserProfile.vue'
import Register from '@/components/user/Register.vue'
import UserInfo from '@/pages/user/UserInfo.vue'
import UserSecurity from '@/pages/user/UserSecurity.vue'
import Plan from '@/pages/module/Plan.vue'
import PlanDetail from '@/pages/module/PlanDetail.vue'
import CreatePlan from '@/pages/module/CreatePlan.vue'
import Result from '@/pages/module/Result.vue'
import ReservationOrder from '@/pages/module/ReservationOrder.vue'
import ReservationPlanManage from '@/pages/module/ReservationPlanManage.vue'
import UpdatePlan from '@/pages/module/UpdatePlan.vue'
import ChatRoom from '@/pages/module/ChatRoom.vue'
import ReservationPlan from '@/pages/module/ReservationPlan.vue'
import NotifyDetail from '@/pages/message/NotifyDetail.vue'
import ForumArea from '@/pages/message/ForumArea.vue'
import TopicDetail from '@/pages/message/TopicDetail.vue'
import CreateTopic from '@/pages/message/CreateTopic.vue'
import PrivateMsgDetail from '@/pages/message/PrivateMsgDetail.vue'
import PayRecord from '@/pages/pay/PayRecord.vue'
import PayResult from '@/pages/result/PayResult.vue'
import UserWallet from '@/pages/user/UserWallet.vue'
import UserFavorite from '@/pages/user/UserFavorite.vue'
import FileHome from '@/pages/file/FileHome.vue'
import LuckCircle from '@/pages/luck/LuckCircle.vue'
import UserReward from '@/pages/reward/UserReward.vue'
import UserCoupon from '@/pages/coupon/UserCoupon.vue'
import CreatePlanQuick from '@/pages/module/CreatePlanQuick.vue'
import CreatePlanBusiness from '@/pages/module/CreatePlanBusiness.vue'
import CreatePlanMedical from '@/pages/module/CreatePlanMedical.vue'

import AdminLogin from '@/pages/admin/AdminLogin.vue'
import AdminHome from '@/pages/admin/AdminHome.vue'
import UserManage from '@/pages/admin/user/UserManage.vue'
import PayRecordManage from '@/pages/admin/pay/PayRecordManage.vue'
import PayRefundOrder from '@/pages/admin/pay/PayRefundOrder.vue'
import ReservationOrderManage from '@/pages/admin/reservation/ReservationOrderManage.vue'
import PlanAdminManage from '@/pages/admin/reservation/PlanAdminManage.vue'
import UpdatePlanAdmin from '@/pages/admin/reservation/UpdatePlanAdmin.vue'
import NotifyManage from '@/pages/admin/notify/NotifyManage.vue'
import TopicManage from '@/pages/admin/topic/TopicManage.vue'
import CommentManage from '@/pages/admin/topic/CommentManage.vue'
import RewardManage from '@/pages/admin/reward/RewardManage.vue'
import FeedbackManage from '@/pages/admin/feedback/FeedbackManage.vue'
import DataView from '@/pages/admin/DataView.vue'
Vue.use(VueRouter);
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function(location,resolve,reject){
    if(resolve && reject){
        originPush.call(this,location,resolve,reject)
    }else{
        originPush.call(this,location,()=>{},()=>{})
    }
}


VueRouter.prototype.replace = function(location,resolve,reject){
    if(resolve && reject){
        originReplace.call(this,location,resolve,reject)
    }else{
        originReplace.call(this,location,()=>{},()=>{})
    }
}
const router = new VueRouter({
    //配置路由
    routes: [
        { path :'/',redirect : '/reservation', meta: { title:'预约平台' }},
        { path: "/reservation",component: Reservation ,redirect : '/reservation/plan',name:'reservation',meta: { title:'预约平台' }
            ,beforeEnter:(to,from,next) => {
                let jumpURL = localStorage.getItem('reservation-jumpURL');
                if(jumpURL){
                    localStorage.removeItem('reservation-jumpURL');
                    next(jumpURL)
                }
                next()
            }
            ,children: [
                { path: 'userProfile/userInfo', component: UserProfile, meta: { title:'用户信息' },
                children : [
                    {path: 'show', component: UserInfo, meta: { title:'用户信息' }},
                    {path: 'security', component: UserSecurity, meta: { title:'账户安全' }},
                    {path: 'wallet', component: UserWallet, meta: { title:'我的钱包' }},
                    ]
                },
                { path: 'plan', component: Plan, meta: { title:'预约平台' }, },
                { path: 'createPlan', component: CreatePlan, meta: { title:'发布预约' }, },
                { path: 'createPlanQuickly', component: CreatePlanQuick, meta: { title:'发布预约（快速模式）' }, },
                { path: 'createPlanBusiness', component: CreatePlanBusiness, meta: { title:'发布预约（交易模式）' }, },
                { path: 'createPlanMedical', component: CreatePlanMedical, meta: { title:'发布预约（医疗模式）' }, },
                { path: 'order', component: ReservationOrder, meta: { title:'我的预约' }, },
                { path: 'myPlan', component: ReservationPlan, meta: { title:'我的发布' }, },
                { path: 'updatePlan', component: UpdatePlan, meta: { title:'修改预约' }, },
                { path: 'favorite', component: UserFavorite, meta: { title:'我的收藏' }, },
                { path: 'payRecord', component: PayRecord, meta: { title:'交易记录' }, },
                { path: 'userReward', component: UserReward, meta: { title:'积分记录' }, },
                { path: 'userCoupon', component: UserCoupon, meta: { title:'我的优惠卷' }, },
                { path: 'planManage', component: ReservationPlanManage, meta: { title:'预约订单管理' },},
                { path: 'notifyDetail', component: NotifyDetail, meta: { title:'通知中心' }, },
                { path: 'msgDetail', component: PrivateMsgDetail, meta: { title:'消息中心' }, },
                { path: 'forumArea', component: ForumArea, meta: { title:'预约平台-论坛区' }, },
                { path: 'luckCircle', component: LuckCircle, meta: { title:'每日抽奖' }, },
                { path: 'fileHome', component: FileHome, meta: { title:'个人文件库' }, },
                { path: 'topicDetail', component: TopicDetail, meta: { title:'帖子详情' }, },
                { path: 'createTopic', component: CreateTopic, meta: { title:'发表帖子' }, },
                { path: 'chatRoom', component: ChatRoom,name :'chatRoom', meta: { title:'聊天室' }, },
               
               
                
          ],
        },
        { path: "/login",component: Login ,name:'login', meta: { title:'登录' },
        },
        { path: '/reservation/detail', component: PlanDetail, meta: { title:'在线预约' }, },
        { path: '/reservation/result', component: Result,name :'result', meta: { title:'结果通知' }, },
        { path: '/reservation/payResult', component: PayResult,name :'payResult', meta: { title:'交易结果通知' }, },
        { path: "/register",component: Register ,name:'register', meta: { title:'注册' }},
        { path: "/adminLogin",component: AdminLogin ,name:'adminLogin', meta: { title:'后台管理-登录' }},
        { path: "/adminHome",component: AdminHome,name:'adminHome',redirect : '/adminHome/dataview', meta: { title:'后台管理' },
            children : [
                {path: 'user', component: UserManage, meta: { title:'用户管理' }},
                {path: 'reservation', component: PlanAdminManage, meta: { title:'预约管理' }},
                {path: 'dataview', component: DataView, meta: { title:'实时数据一览' }},
                {path: 'updatePlanAdmin', component: UpdatePlanAdmin, meta: { title:'预约详情修改' }},
                {path: 'notify', component: NotifyManage, meta: { title:'通知管理' }},
                {path: 'topic', component: TopicManage, meta: { title:'论坛管理' }},
                {path: 'comment', component: CommentManage, meta: { title:'评论管理' }},
                {path: 'reward', component: RewardManage, meta: { title:'积分管理' }},
                {path: 'feedback', component: FeedbackManage, meta: { title:'反馈管理' }},
                {path: 'payRecord', component: PayRecordManage, meta: { title:'支付订单管理' }},
                {path: 'refundOrder', component: PayRefundOrder, meta: { title:'退款与异常订单' }},
                {path: 'reservationOrder', component: ReservationOrderManage, meta: { title:'预约订单' }},
                ]
        },
       
    ]
})

//全局前置路由守卫
router.beforeEach((to,from,next)=>{
    next()
})

//全局后置路由守卫
router.afterEach((to,from)=>{
    document.title = to.meta.title || '预约平台'
})

export default router