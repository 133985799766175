<template>

    <div style="width:90%;margin:0 auto" >
        <div style="float:right">
            <i style="cursor: pointer;" @click="$router.push('/reservation/forumArea')" class="el-icon-arrow-left">返回</i>
        </div><br><br>
        <el-input placeholder="输入帖子标题" v-model="title"></el-input>
        <br>
        <div style="border: 1px solid #ccc;">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
            <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
        </div><br>
        <!-- 自定义标签 -->
        <div class="row">
            <el-tag size="medium" :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
                {{tag}}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" style="width:15%;" v-model="inputValue" ref="saveTagInput" size="medium" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
            </el-input>
            <el-button v-else class="button-new-tag" size="medium" @click="showInput">+帖子主题标签</el-button>
        </div><br>
        <div style="text-align:center">
            <el-button style="width:170px;" @click="createTopic" type="primary">发 帖</el-button>
        </div>
    </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { add } from "@/api/topic";
export default {
    components: { Editor, Toolbar },
    data() {
        return {
            editor: null,
            name: "CreateTopic",
            title: "",
            html: "",
            dynamicTags: [],
            inputVisible: false,
            inputValue: "",
            tag: "",
            // toolbarConfig: { excludeKeys: ["uploadImage"] },
            toolbarConfig: {},
            editorConfig: { placeholder: "请输入帖子内容..." },
            mode: "default", // or 'simple'
        };
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor);
            this.initUploadImage();
            this.initUploadVideo();
        },
        initUploadImage() {
            var config = this.editor.getMenuConfig("uploadImage");
            config.server = "/api/file/imageHome/upload";
            config.maxFileSize = 8 * 1024 * 1024;
            (config.fieldName = "file"),
                (config.headers = {
                    "cst-token": localStorage.getItem("cst-token"),
                });
            console.log("config.headers = ", config.headers);
            config.withCredentials = true;
            config.onError = this.uploadImgError;
            config.onFailed = this.uploadImgFailed;
            config.onSuccess = this.uploadImgSuccess;
        },
        initUploadVideo() {
            var config = this.editor.getMenuConfig("uploadVideo");
            config.server = "/api/file/videoHome/upload";
            config.maxNumberOfFiles = 3;
            config.maxFileSize = 20 * 1024 * 1024;
            (config.fieldName = "file"),
                (config.headers = {
                    "cst-token": localStorage.getItem("cst-token"),
                });
            console.log("config.headers = ", config.headers);
            config.withCredentials = true;
            config.onError = this.uploadImgError;
            config.onFailed = this.uploadImgFailed;
            config.onSuccess = this.uploadImgSuccess;
        },
        uploadImgError(file) {
            this.$message.error("上传失败，请稍后再试 (" + file.name + ")");
        },
        uploadImgFailed(file) {
            this.$message.error("添加失败!(" + file.name + ")");
        },
        uploadImgSuccess() {
            this.$message.success("添加成功！");
        },handleClose(tag) {
            console.log("dynamicTags = ", this.dynamicTags);
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
            
        },async createTopic(){
             if(!this.html) return
            let vo = {
                title: this.title,
                content: this.html,
                tags: this.dynamicTags.toString(),
            };
            console.log('vo=',vo);
            let res = await add(vo);
            if(res.resultCode == 200){
                this.$message.success('帖子发表成功！')
                this.$router.push('/reservation/forumArea')
            }else{
                 this.$$message.error('发表失败！'+res.resultMsg)
            }
        }
    },
};
</script>

<style>
</style>