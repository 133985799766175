<template>
   <div class="container">
        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-lg-5 d-none d-lg-block">
                         <img style="margin-top:30px;margin-left:45px" width="350px" height="350px" src="https://reservation-platform.oss-cn-shenzhen.aliyuncs.com/images/undraw_rocket.svg?versionId=CAEQMhiBgMCLjr7ehhgiIGUxZjExM2I0MDVmZTQ0ZTBhMTliZTM0NTFkYmIyNDMw" alt="">
                    </div>
                    <div class="col-lg-7">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">用 户 注 册</h1>
                            </div>
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-user" id="username"
                                        placeholder="输入账号" v-model="username">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control form-control-user" id="password"
                                        placeholder="输入密码" v-model="password">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control form-control-user" id="passwordRepeat"
                                        placeholder="请再次输入密码" v-model="passwordRepeat">
                                </div>
                                <button @click="register" :disabled="isDisable" class="btn btn-primary btn-user btn-block">
                                    {{registerBtnText}}
                                </button>
                                <hr>
                            <hr>
                            <div class="text-center">
                                
                                <router-link  class="small" to="/login">
                                    已有账号? 去登录!
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { register } from "@/api/userApi";
export default {
    name:'Register',
    data () {
        return {
            username:'',
            password:'',
            passwordRepeat:'',
            isDisable:false,
            registerBtnText:'注 册',
        }
    },methods: {
       async register(){
            if(!this.username || !this.password || this.username.length < 3 || this.password.length < 3){
                this.$message.warning("输入不合法, 账号或密码必须在3位数及以上"); return;
            }
            if(this.password !== this.passwordRepeat){
                this.$message.warning("两次密码输入不一致"); return;
            }
            this.isDisable=true
            this.registerBtnText='正在注册...'
            var res = await register({ username:this.username, password:this.password });
            if(res.resultCode === 200){
                this.$message.success("注册成功！")
                this.$router.push('/login')
            }else{
                 this.$message.error( res.resultMsg)
                 this.isDisable=false
                 this.registerBtnText = '注 册'
            }
        }
    }
}
</script>

<style>

</style>