<template>
    <div>
        
        <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item @click="$router.push('/adminHome/dataview')" index="1"><i class="el-icon-date"></i>数据一览</el-menu-item>
            <el-menu-item @click="$router.push('/adminHome/reservation')" index="1-2"><i class="el-icon-date"></i>预约管理</el-menu-item>
            <el-submenu index="2">
                <template slot="title"><i class="fas fa-list"></i> 订单管理</template>
                <el-menu-item index="2-1" @click="$router.push('/adminHome/reservationOrder')"><i class="el-icon-coin"></i>预约订单</el-menu-item>
                <el-menu-item index="2-3" @click="$router.push('/adminHome/refundOrder')"><i class="el-icon-sold-out"></i>退款与异常订单</el-menu-item>
                <el-menu-item index="2-2" @click="$router.push('/adminHome/payRecord')" ><i class="el-icon-bank-card"></i>线上支付订单</el-menu-item>
            </el-submenu>
            <el-menu-item index="3" @click="$router.push('/adminHome/user')" ><i class="el-icon-user"></i>用户管理</el-menu-item>
            <el-menu-item index="4" @click="$router.push('/adminHome/notify')"><i class="el-icon-bell"></i>通知管理</el-menu-item>
            <el-menu-item index="5" @click="$router.push('/adminHome/topic')"><i class="el-icon-chat-line-round"></i>论坛管理</el-menu-item>
            <el-menu-item index="7" @click="$router.push('/adminHome/reward')"><i class="el-icon-goods"></i>积分管理</el-menu-item>
            <!-- <el-menu-item index="8"><i class="el-icon-present"></i>活动管理</el-menu-item> -->
            <!-- <el-menu-item index="9"><i class="el-icon-receiving"></i>文件库管理</el-menu-item> -->
            <el-menu-item index="10" @click="$router.push('/adminHome/feedback')"><i class="el-icon-message"></i>反馈管理</el-menu-item>
            <div style="float:right;margin:12px;">
                <el-link type="primary" @click="$router.push('/reservation')">去前台</el-link>
            </div>
        </el-menu>
        <div style="width:95%;margin: 0 auto">
            <router-view></router-view>
        </div>
    </div>

</template>

<script>
export default {
    name: "AdminHome",
    data() {
        return {
            activeIndex: "1",
            activeIndex2: "1",
        };
    },
    methods: {
        handleSelect(key, keyPath) {
        },
    },
};
</script>

<style>
</style>