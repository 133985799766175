<template>

    <el-dialog center :visible.sync="userDialog" :width="$_isMobile() ? '90%' : '30%'">
        <template slot="title">
            <span style="font-size:22px;color:#000000">{{user.info.nickname}}</span> 
            <el-tag v-if="user.isOnline" size="mini"  type="success">在线</el-tag>
        </template>
        <div class="block" v-if="user.info">
            <div class="card-body text-center">
                <div>
                    <img v-if="user.info.avatarAddr" class="img-account-profile rounded-circle mb-2" :src="user.info.avatarAddr" width="90px" height="90px" alt="头像" />
                </div>
                <div class="small font-italic text-muted mb-4">
                    {{ user.info.brief }}
                </div>
                <div class="tag-group">
                    <el-tag size="small" type="success" style="margin-right:8px;">积分 {{user.rewardNum}}</el-tag>
                    <el-tag size="small" v-if="user.level" type="primary" style="margin-right:8px;">{{user.level.role}}</el-tag>
                    <el-tag size="small" v-if="user.level" type="warning">等级 {{user.level.grade}}</el-tag>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sendPriveteInfo" icon="el-icon-chat-dot-round">发送私信</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { getUserPublicInfo } from "@/api/userApi";
import { sendPrivateMsg } from "@/api/notify";
export default {
    name: "UserInfoDialog",
    data() {
        return {
            userDialog: false,
            user: { info: "", level: {} },
        };
    },
    methods: {
        async showUser(userId) {
            let res = await getUserPublicInfo(userId);
            this.user = res.data;
            this.userDialog = true;
        },
        sendPriveteInfo() {
            this.$prompt("请输入内容", "发送私信给（"+this.user.info.nickname+"）", {
                confirmButtonText: "立即发送",
                cancelButtonText: "取消",
            })
            .then(async({ value }) => {
                if(!value){
                    this.$message.warning('私信内容不能为空！')
                    return
                }
               let res = await sendPrivateMsg({'toUser':this.user.info.userId,'content':value})
               if(res.resultCode == 200){
                   this.$message.success("私信发送成功！")
               }else{
                    this.$message.error(res.resultMsg)
               }
            })
            .catch(() => {});
        },
    },
};
</script>

<style>
</style>