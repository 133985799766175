<template>
    <div>
        <el-table height="600" :data="rewards.filter(data => !search || data.typeName.includes(search) || data.remark.includes(search) || data.createTime.includes(search) || data.reward.toString().includes(search) || data.nickname.toString().includes(search))  " style="width: 100%" :stripe="true">
            <el-table-column prop="typeName" label="奖励类型"> </el-table-column>
            <el-table-column prop="reward" label="奖励值"> </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column prop="targetId" label="目标ID" show-overflow-tooltip width="90"></el-table-column>
            <el-table-column prop="nickname" label="用户">
                <template slot-scope="temp">
                    <el-link type="primary" @click="$userInfoDialog(temp.row.uid);">{{ temp.row.nickname }}</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="createTime">
                <template slot-scope="time">{{time.row.createTime}}</template>
                <template slot="header" slot-scope="searchReward">
                    <el-input v-model="search" size="mini" :id="searchReward" placeholder="搜索" />
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" @click="delReward(scope.row.id)" type="warning">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="float:right;margin-top:10px">
            <el-button @click="rewardAddDialog = true" icon="el-icon-present" type="primary">新增积分奖励</el-button>
        </div>
        <el-dialog title="奖励积分" :visible.sync="rewardAddDialog" width="70%">
            <div class="block">
                积分值：<el-input-number v-model="rewardNum" :min="1" :max="3000" ></el-input-number>
                <br><br>
                <el-input placeholder="请输入奖励原因" v-model="remark">
                </el-input><br><br>
                奖励给： <el-select multiple size="medium" v-model="toUser" filterable placeholder="请选择用户" width="500">
                    <el-option v-for="item in userList" :key="item.value" :label="item.username+' ('+item.nickname+')'" :value="item.userId">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="rewardAddDialog = false">取 消</el-button>
                <el-button type="primary" @click="rewardAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {list,add,del} from '@/api/admin/reward'
import {getUserList} from "@/api/admin/notify";
export default {
    name : 'RewardManage',
    data () {
        return {
            rewards:[],
            userList: "",
            toUser:[],
            rewardNum:1,
            search:'',
            remark:'',
            rewardAddDialog:false
        }
    },mounted () {
        this.init()
    },methods: {
        async init(){
          let res = await list()
          if(res.resultCode == 200){
              this.rewards = res.data
          }
          let user = await getUserList();
            if (user.resultCode == 200) {
                this.userList = user.data;
            }
        }, delReward(id){
            this.$confirm('确定要删除吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async() => {
                let res = await del({'id':id})
                if(res.resultCode == 200){
                    this.$message.success('删除奖励成功！');
                    this.init()
                }else{
                    this.$message.error('删除失败')
                }
            }).catch(() => {
            });
        },async rewardAdd(){
            let res = await add({'rewardNum': this.rewardNum, 'remark':this.remark, 'uids':this.toUser.toString()})
            if(res.resultCode == 200){
                this.$message.success('操作成功')
                this.rewardAddDialog = false
                 this.init()
            }else{
                 this.$message.success('操作失败')
            }
        }

    }
}
</script>

<style>
</style>