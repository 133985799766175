<template>
    <div style="text-align:center;">
        <h3>幸运大转盘</h3>
  
        <el-divider><i class="el-icon-odometer"></i></el-divider>
        <LuckyWheel class="main" ref="myLucky" width="300px" height="300px" :prizes="prizes" :blocks="blocks" :buttons="buttons" @start="startCallback" @end="endCallback" />
        <el-divider>
            <el-popover style="cursor:pointer" placement="bottom" title="抽奖规则说明" width="300" trigger="click" content="用户每天有5次免费机会。正常则是50积分抽一次，奖品和中奖率：谢谢参与(20%),20积分(35%)、50积分(20%)、100积分(15%)、1元优惠卷(8%)、3元(2%)">
                <i  style="font-size:8px;color:#6F6F6F"  slot="reference" class="el-icon-reading"><span >查看说明</span></i>
            </el-popover>
            
        </el-divider>
        <div>
            <p v-if="times > 0">今日免费抽奖剩余 <span style="color:#ff3300">{{times}}</span> 次</p>
            <p v-else>今日免费抽奖机会已用完</p>
            <div style="float:left">
                <el-link style="cursor:pointer;" :underline="false" @click="showGifts" type="primary" icon="el-icon-present" circle> 我的奖品</el-link>
            </div>
        <div style="float:right">
            <el-button v-if="!isSign" @click="signEveryday" type="primary" icon="el-icon-magic-stick" :loading="load" > 点击签到，领随机金额优惠卷！</el-button>
            <el-button v-else  type="success" disabled icon="el-icon-circle-check" > 今日已签到</el-button>
        </div>
        </div>
        <el-dialog title="我的奖品" :visible.sync="giftsDialog" :width="$_isMobile() ? '90%' : '50%'">
            <div class="block">
                <el-table :data="gifts" style="width: 95%;" height="300">
                    <el-table-column prop="name" label="奖品名称" align="center">
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="stateTemp">
                            {{getGiftState(stateTemp.row.state)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" align="center" label="中奖时间">
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-divider v-if="gifts.length >0" content-position="center"><span style="font-size:12px;">共有{{gifts.length}}条中奖记录</span></el-divider>
                <el-button type="primary" @click="giftsDialog = false">知道了</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="签到奖励"
            :visible.sync="signRewardDialog"
            :width="$_isMobile() ? '90%' : '26%'"
            >
                <div class="col-xl-12 col-md-12 mb-12">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-left">
                            <div class="col mr-6">
                                <div  style="cursor:pointer;" class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    <span style="font-size:22px">{{coupon.name}}</span>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    <span style="font-size:11px">过期时间：{{coupon.dueTime}}</span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i style="cursor:pointer" slot="reference" class="el-icon-present fa-3x text-gray-300"></i>
                                <i style="cursor:pointer" slot="reference" class="fa-3x text-gray-300"> ¥</i>
                            </div>
                        </div>
                    </div>
                </div><br>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="signRewardDialog = false">开心收下</el-button>
            </span>
            </el-dialog>
    </div>

</template>

<script>
import { luckGo,luckGoNotFree, getTimes, listGift } from "@/api/luck";
import { checkSignEveryday , getRandomCoupon } from "@/api/coupon";
export default {
    name: "LickCircle",
    data() {
        return {
            doing: false,
            gifts: [],
            giftsDialog: false,
            times: 0,
            blocks: [
                {
                    padding: "16px",
                    imgs: [
                        {
                            src: require("@/assets/luck_img/wheel-border.jpg"),
                            width: "100%",
                            height: "100%",
                        },
                    ],
                },
                {
                    padding: "15px",
                    imgs: [
                        {
                            src: require("@/assets/luck_img/wheel-border.png"),
                            width: "100%",
                            height: "100%",
                        },
                    ],
                },
                {
                    imgs: [
                        {
                            src: require("@/assets/luck_img/wheel-bg.png"),
                            width: "100%",
                            height: "100%",
                        },
                    ],
                },
            ],
            prizes: [
                {
                    fonts: [{ text: "谢谢参与", top: "10%" }],
                    background: "#e9e8fe",
                },
                {
                    fonts: [{ text: "20 积分", top: "10%" }],
                    background: "#b8c5f2",
                },
                {
                    fonts: [{ text: "50 积分", top: "10%" }],
                    background: "#e9e8fe",
                },
                {
                    fonts: [{ text: "100 积分", top: "10%" }],
                    background: "#b8c5f2",
                },
                {
                    fonts: [{ text: "1元优惠卷", top: "10%" }],
                    background: "#e9e8fe",
                },
                { fonts: [{ text: "3元", top: "10%" }], background: "#b8c5f2" },
            ],
            buttons: [
                {
                    radius: "35px",
                    // fonts: [{ text: "开始\n抽奖", top: "-20px" }],
                    imgs: [
                        {
                            src: require("@/assets/luck_img/wheel-btn.png"),
                            width: "100%",
                            top: "-130%",
                        },
                    ],
                },
            ],
            isFree:true,
            isSign:false,
            load:false,
            signRewardDialog:false,
            coupon:''
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let res = await getTimes();
            if (res.resultCode == 200) {
                this.times = res.data;
            }
            let d= await checkSignEveryday()
            this.isSign = d.data
        },
        startCallback() {

            if (!this.times || this.times <= 0) {
                this.isFree = false; 
                this.$confirm('确定消耗50积分，再抽奖1次吗？', '抽奖提示', {
                    confirmButtonText: '再来一次',
                    cancelButtonText: '算了，不抽了',
                    type: 'success'
                    }).then(() => { this.start() }).catch(() => { return;});
            }else{
                this.start()
            }
        },start(){
            console.log('开始');
            this.$refs.myLucky.play();
            setTimeout(async () => {
                try {
                    let res='';
                    if(this.isFree){
                        res = await luckGo({});
                    }else{
                        res = await luckGoNotFree({});
                    }
                    if (res.resultCode == 200) {
                        const gift = res.data;
                        // 调用stop停止旋转并传递中奖索引
                        this.$refs.myLucky.stop(gift.index);
                    } else {
                        this.$refs.myLucky.stop(0);
                        this.$message.error(res.resultMsg);
                    }
                } catch (e) {
                    this.$refs.myLucky.stop(0);
                }
            }, 3000);
        },
        // 抽奖结束会触发end回调
        endCallback(gift) {
            // this.$message.success("抽中" + gift.fonts[0].text);
            let name = gift.fonts[0].text;
            this.times--;
            if (name != "谢谢参与") {
                this.$alert("奖品：" + gift.fonts[0].text, "恭喜，中奖啦！", {
                    confirmButtonText: "开心收下",
                    type: "success",
                });
            } else {
                this.$alert("没有中奖", "谢谢参与", {
                    confirmButtonText: "好的",
                });
            }
        },
        async showGifts() {
            let res = await listGift();
            this.gifts = res.data;
            this.giftsDialog = true;
        },
        getGiftState(state) {
            if (state == "00") {
                return "已到账";
            } else if(state == "02") {
                return "到账失败";
            } else {
                return "发放中";
            }
        },async signEveryday(){
            this.load = true
            let res = await getRandomCoupon()
            this.load = false
            if(res.resultCode == 200){
                this.coupon = res.data
                this.signRewardDialog = true
                this.isSign = true
            }else{
                this.$alert( res.resultMsg, "提示", {
                    confirmButtonText: "好的",
                    type: "warning",
                });
            }
        }
    },
};
</script>

<style>
.main {
    width: 85%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>