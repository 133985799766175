<template>
  <div style="width:95%;margin:0 auto;">
      <div style="float:right">
        <el-button type="warning" @click="$router.push('/reservation/userProfile/userInfo/wallet')">我的钱包</el-button> 
        <el-button type="primary" @click="payDialog = true">充值</el-button> 
         <el-button type="primary" icon="el-icon-refresh" @click="refresh" circle></el-button>
        
      </div>
     <hr>
     <el-table :data="orders.filter(data => !search || data.orderId.toLowerCase().includes(search.toLowerCase()) || data.payId.includes(search) || data.moneyYuan.includes(search) || data.createTime.includes(search) || data.title.includes(search))  " style="width: 100%" :stripe="true">
      <el-table-column prop="orderId" label="订单号"> </el-table-column>
        <el-table-column prop="title" label="类型"> </el-table-column>
      <el-table-column prop="moneyYuan" label="金额" > 
           <template slot-scope="cash">
               <strong style="font-size:16px;">{{cash.row.type == '00' || cash.row.type == '03'  || cash.row.type == '02' ? '+' : '- '}} {{cash.row.type == '00' ?  cash.row.moneyYuan : cash.row.moneyRealYuan}}</strong>
               </template>
      </el-table-column>
      <el-table-column label="订单状态"> 
          <template slot-scope="stateProp">
            <span style="color:green" v-if="stateProp.row.state=='01'"><i class="el-icon-circle-check"></i> {{payState(stateProp.row.state)}}</span>
            <span style="color:red" v-else-if="stateProp.row.state=='02'"><i class="el-icon-warning-outline"></i> {{payState(stateProp.row.state)}}</span>
            <span v-else-if="stateProp.row.state=='03'"><i class="el-icon-remove-outline"></i> {{payState(stateProp.row.state)}}</span>
            <span style="color:orange;cursor:pointer;" v-else @click="cancelOrder(stateProp.row.orderId)"><i class="el-icon-shopping-cart-full"></i> {{payState(stateProp.row.state)+' (点击可取消订单)'}} </span>
          </template>
      </el-table-column>
    
      <el-table-column prop="createTime">
        <template slot-scope="time">{{dateFormat(time.row.createTime)}}</template>
        <template slot="header" slot-scope="searchOrder">
            <el-input v-model="search" size="mini" :id="searchOrder" placeholder="搜索订单"/>
        </template>
      </el-table-column>
      <el-table-column  label="操作" width="120"> 
          <template slot-scope="pay">
            <el-link v-if="pay.row.state == '00'" :underline="false" @click="rePay(pay.row.payId, pay.row.payAddr)" type="success">
                <i class="el-icon-goods"></i> 去支付</el-link>
            <el-link v-else :underline="false" @click="showOrderDetail(pay.row)" type="primary" icon="el-icon-postcard">查看详情</el-link>
          </template>
      </el-table-column>

    </el-table>
       <el-dialog
        :visible.sync="orderDetailDialog"
        width="70%">
        <template slot="title">
                <i class="el-icon-notebook-2"></i>订单详情  ({{currentOrder.title}})
        </template>
        <div class="block">
            <el-divider content-position="center"><span style="font-size:15px;">
                <span style="color:green" v-if="currentOrder.state=='01'"><i class="el-icon-circle-check"></i> {{payState(currentOrder.state)}}</span>
            <span style="color:red" v-else-if="currentOrder.state=='02'"><i class="el-icon-warning-outline"></i> {{payState(currentOrder.state)}}</span>
            <span v-else-if="currentOrder.state=='03'"><i class="el-icon-remove-outline"></i> {{payState(currentOrder.state)}}</span>
            <span style="color:orange;" v-else><i class="el-icon-shopping-cart-full"></i> {{payState(currentOrder.state)}}</span>
                 </span></el-divider>
            <p><el-tag size="small">订单号</el-tag> <strong>{{currentOrder.orderId}}</strong>
            <p>支付ID：{{currentOrder.payId}}</p>
            <p>订单金额： ¥ {{currentOrder.moneyYuan}}</p>
            <p>实付金额： <strong>¥ {{currentOrder.moneyRealYuan}}</strong></p>
            <p v-if="currentOrder.discountMoney">优惠金额： <strong>¥ {{currentOrder.discountMoneyYuan}}</strong></p>
            <p>订单描述：{{currentOrder.content}}</p>
            <p>支付方式：{{currentOrder.type =='00' ? '微信支付' : '账户钱包'}}</p>
            <p>{{currentOrder.state!='03' ? '完成' : '取消'}}时间：{{currentOrder.updateTime ? currentOrder.updateTime : '暂无'}}</p>
            <p v-if="currentOrder.remark">备注: {{currentOrder.remark}}</p>
            <p v-if="currentOrder.state == '02'" style="color:orange">对订单如有疑问，请咨询客服: QQ1315401811</p>
            <el-divider content-position="center"><span style="font-size:13px;"><i class="el-icon-alarm-clock"></i>下单时间 {{currentOrder.createTime}} </span></el-divider>
        </div>
        </el-dialog>

        <el-dialog
            title="选择充值金额"
            :visible.sync="payDialog"
            :width="$_isMobile() ? '95%' : '60%'">
             <div style="text-align:center;">
                <el-radio-group v-model="payMoney">
                    <el-radio-button label="0.01">¥ <span style="font-size:24px;">0.01</span> <span style="font-size:4px;color:#67C23A">体验价</span></el-radio-button>
                    <el-radio-button label="0.9">¥ <span style="font-size:24px;">1  </span> <span style="font-size:4px;color:orange;">享9折</span></el-radio-button>
                    <el-radio-button label="6.66">¥ <span style="font-size:24px;">6.66</span></el-radio-button>
                    <el-radio-button label="9">¥ <span style="font-size:24px;">10</span> <span style="font-size:4px;color:orange;">立减1元</span></el-radio-button>
                    <el-radio-button label="18.88">¥ <span style="font-size:24px;">18.88</span></el-radio-button>
                    <el-radio-button label="50">¥ <span style="font-size:24px;">50</span></el-radio-button>
                    <el-radio-button label="85">¥ <span style="font-size:24px;">100 <span style="font-size:4px;color:orange;">优惠15元</span></span></el-radio-button>
                </el-radio-group>
            </div>
            <span slot="footer" class="dialog-footer">
                <div style="float:left;">
                    <el-button @click="pay" plain size="mini" round>自定义金额</el-button> <span style="font-size:7px"> 备注：当前用户充值有机会享受随机立减</span>
                </div>
                <el-button @click="payDialog = false">取 消</el-button>
                <el-button type="primary" @click="payBySelect">立即支付  {{payMoney ? payMoney+'元' : ''}}</el-button>
            </span>
            </el-dialog>
  </div>
</template>

<script>
import { pay,checkOrder,listPayOrder,cancelOrder } from "@/api/pay";
import { getDateDiff } from "@/util";
export default {
 name : 'PayRecord',
 data () {
     return {
         orders : [],
         currentOrder :'',
         search : '',
         orderDetailDialog: false,
         payDialog: false,
         payMoney: '',
     }
 },mounted () {
     this.init()
 },methods: {
     async init(){
        let res =  await listPayOrder();
        this.orders = res.data
     },payState(state){
         if(state == '00'){
             return "待支付"
         }else if(state == '01'){
             return "交易成功"
         }else if(state == '02'){
             return "支付失败"
         }else if(state == '03'){
             return "已取消"
         }else{
            return "订单异常"
         }
     },async refresh(){
        let res =  await listPayOrder();
        this.orders = res.data
        this.$message.success("已刷新！")
     },showOrderDetail(order){
         this.currentOrder = order
         this.orderDetailDialog = true;
     },dateFormat(date) {
            return getDateDiff(date);
     },pay(){
         this.payMoney = ''
         this.$prompt('请输入金额', '充值', {
          confirmButtonText: '立即支付',
          cancelButtonText: '取消',
          inputPattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
          inputErrorMessage: '金额格式不正确'
        }).then(({ value }) => {
           this.createPay(value)
        }).catch(() => {
        });
     },payBySelect(){
         if(!this.payMoney){
             this.$message.warning('请选择金额')
             return
         }
         this.createPay(this.payMoney)
     },async createPay(moneyVal){
         this.payDialog = false
         let discountPrice = ''
         if(moneyVal == '9'){
             discountPrice = '1'
         }else if(moneyVal == '85'){
             discountPrice = '15'
         }else if(moneyVal == '0.9'){
             discountPrice = '0.1'
         }
        const loading = this.$loading({lock: true,text: '正在跳转至支付页面，请稍等....',spinner: 'el-icon-loading',});
        let res = await pay({price: moneyVal,title:'账户钱包充值',content:'预约平台线上支付', type:'1',param:'', discountMoney: discountPrice,
        returnUrl:'https://reservation.cstweb.top/#/reservation/payResult'})
        // returnUrl:'http://localhost:8080/#/reservation/payResult'})
        if(res.resultCode == 200 && res.data){
            window.location.href = res.data;
        }else{
            this.$message.error(res.resultMsg)
        }
        loading.close();
     },cancelOrder(orderId){
        this.$confirm('确定要取消订单吗? '+orderId, '提示', {
          confirmButtonText: '确定取消',
          cancelButtonText: '不取消',
          type: 'warning'
        }).then(async() => {
            let res = await cancelOrder({'orderId' : orderId});
            if(res.resultCode == 200){
                this.$message.success('订单取消成功！')
                this.init()
            }else{
                this.$message.error(res.resultMsg)
            }
        }).catch(() => {
        });
     },async rePay(id,payAddr){
        let res =  await checkOrder({'payId':id})
        if(res.resultCode == 200){
            this.$message.success('该订单已经支付！')
        }else if(res.resultCode == 223){
            window.location.href = payAddr;
        }else{
            this.$message.warning(res.resultMsg)
            this.init()
        }
     }
 }
}
</script>

<style scoped>
    a:hover{
        text-decoration: none;
    }
</style>