<template>
    <div>
        <br><br><br>
        <el-col>
            <el-result :icon="iconType" :title="title" :subTitle="message">
                <template slot="extra">
                    <el-button type="primary" @click="returnHome" size="medium">返回</el-button>
                </template>
            </el-result>
             <el-divider><i class="el-icon-mobile-phone"></i> <span style="font-size:13px;">{{payId}}，本次支付由 <a href="https://reservation.cstweb.top">在线预约平台</a> 提供支持</span></el-divider>
        </el-col>
    </div>
</template>


<script>
export default {
    name: "PayResult",
    data() {
        return {
            title:'',
            message:'',
            iconType:'',
            payId:'',
        };
    },
    mounted() {
        this.title = this.$route.query.sign ? '支付成功' : '支付失败';
        this.message = this.$route.query.reallyPrice ? this.$route.query.reallyPrice +'元' : '金额未知';
        this.iconType = this.$route.query.sign  ? 'success' : 'warning';
        this.payId = this.$route.query.payId  ? '订单号 '+this.$route.query.payId : '';
    },
    methods: {
        returnHome(){
            this.$router.replace('/reservation/payRecord')
        }
    },
};
</script>

<style>
.qrcode {
    display: inline-block;
    width: 180px;
    height: 180px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
}
</style>