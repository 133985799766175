<template>
    <div>
        <div style="float:right;">
            <a style="cursor: pointer;" @click="planHome"> <i class="el-icon-arrow-left" />返回</a>
        </div>
        <h3 v-if="order.plan">{{order.plan.title}}</h3>
           <div v-if="order.plan" class="row no-gutters align-items-center">
            <div class="col-auto">
                <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                    已预约 {{getDoubleNum(order.totalDone,order.plan.maxAmount)}}%
                </div>
            </div>
            <div class="col" >
                <div class="progress progress-sm mr-2" style="width:300px">
                    <div class="progress-bar bg-success" role="progressbar" :style="{width: getDoubleNum(order.totalDone,order.plan.maxAmount)+'%'}"  aria-valuemin="0" ></div>
                </div>
            </div>
        </div>
        <el-collapse v-if="order" v-model="activeNames">
            <el-collapse-item :title="'预约情况统计'+'（'+order.totalDone +' / '+order.plan.maxAmount+'）'" name="1">
                <div style="margin-left:12px;">
                    <p>订单完成数：{{order.doneNum}}</p>
                    <p>已收入到账：{{order.totalMoney}} 元， 预计总收入：{{order.expectedMoney}} 元</p>
                    <p>已取消订单：{{order.cancelNum}}</p>
                    <p>进行中订单：{{order.doing}}</p>
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-table :data="order.infos" style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <div style="margin-left:60px;">
                            <el-form-item label="提交信息">
                                <el-collapse v-if="props.row.content">
                                    <el-collapse-item title="查看详情" name="userInput">
                                        <p v-for="(info,index) in props.row.content.split(',')" :key="index">{{info.replace('@#@', '：')}} </p>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-form-item>
                            <el-form-item label="开始时间">
                                <span>{{ props.row.targetTime }}</span>
                            </el-form-item>
                            <el-form-item label="截止时间">
                                <span>{{ props.row.targetEndTime }}</span>
                            </el-form-item>
                            <el-form-item v-if="props.row.fileAddr" label="提交附件">
                                <a :href="props.row.fileAddr" target="_blank"><em>下载附件</em></a>
                            </el-form-item>
                            <el-form-item label="预约评价">
                                <div v-if=" props.row.rateInfo">
                                    <span v-for="(rate,index) in props.row.rateInfo.split('&&')" :key="index">
                                        {{getRateInfo(rate)}}
                                    </span>
                                  </div>
                                <div v-else>用户暂未评价</div>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="订单号" prop="id">
            </el-table-column>
            <el-table-column label="订单状态">
                <template slot-scope="stateProp">{{orderState(stateProp.row.state)}}</template>
            </el-table-column>
            <el-table-column label="用户" prop="createUserNickName">
                <template slot-scope="tempNickName">
                    <el-link :underline="false" type="primary" @click="$userInfoDialog(tempNickName.row.createUser);">{{tempNickName.row.createUserNickName}}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="下单时间" prop="createTime">
            </el-table-column>
            <el-table-column align="right">
                <template slot="header" slot-scope="elmbug">
                    <el-input placeholder="查询订单" clearable :id="elmbug" v-model="searchInfo">
                        <el-button slot="append" @click="searchOrder" icon="el-icon-search"></el-button>
                    </el-input>
                </template>
                <template slot-scope="op">
                    <el-button size="mini" @click="showOrderDetail(op.row.id)">预约时间线</el-button>
                    <el-button v-if="op.row.state == '10'" size="mini" @click="audit(op.row.id)" type="warning">审批</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="float:right;margin-top:20px" v-if="order.infos">
            <el-pagination @current-change="handleCurrentChange" background layout="total, prev, pager, next" :page-size="10" :total="totalSize">
            </el-pagination>
        </div>
        <el-dialog title="订单详情" :visible.sync="orderDetailDialog" width="70%">
            <el-divider content-position="center"><span style="font-size:12px;">预约时间线</span></el-divider>
            <div class="block">
                <el-timeline>
                    <el-timeline-item v-for="(vo, index) in orderStep" :key="index" :icon="vo.remark.split('_')[0]" :type="vo.remark.split('_')[1]" :size="'large'" :timestamp="vo.createTime">
                        {{vo.title }}{{vo.remark.split('_')[2] != '无'? (' ('+vo.remark.split('_')[2]+')') : ''}}
                    </el-timeline-item>
                </el-timeline>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="orderDetailDialog = false">知道了</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>

<script>
import { getOrderByPlanId, orderAudit, getOrderStep } from "@/api/reservation";
export default {
    name: "ReservationPlanManage",
    data() {
        return {
            order: "",
            searchInfo: "",
            totalSize: 0,
            planId: "",
            orderDetailDialog: false,
            orderStep: [],
            activeNames: [],
        };
    },
    mounted() {
        this.planId = this.$route.query.planId;
        this.init();
    },
    methods: {
        getDoubleNum(a,b){
          let res = a / b * 100;
          return res % 10== 0 ? res : res.toFixed(2)
        },
        async init(id) {
            let res = await getOrderByPlanId({
                id: this.planId,
                content: "",
                pageNo: 1,
                pageSize: 10,
            });
            this.order = res.data;
            this.totalSize = res.totalSize;
        },
        orderState(state) {
            if (state == "00") {
                return "进行中，待签到";
            } else if (state == "10") {
                return "待审核";
            } else if (state == "01") {
                return "进行中，待支付";
            } else if (state == "02") {
                return "已完成";
            } else if (state == "06") {
                return "交易成功，待评价";
            } else if (state == "03") {
                return "已取消";
            } else if (state == "04") {
                return "已过期";
            } else if (state == "05") {
                return "进行中，已签到";
            } else {
                return "订单异常";
            }
        },
        async handleCurrentChange(val) {
            let vo = {
                pageNo: val,
                pageSize: 10,
                id: this.planId,
                content: this.searchInfo,
            };
            let res = await getOrderByPlanId(vo);
            this.totalSize = res.totalSize;
            this.order = res.data;
        },
        audit(id) {
            this.$confirm("订单号：" + id, "预约订单审核", {
                distinguishCancelAndClose: true,
                confirmButtonText: "审核通过，允许预约",
                cancelButtonText: "不通过",
            })
                .then(async () => {
                    let update = await orderAudit({
                        infoId: id,
                        state: "00",
                        title: "审核通过，预约成功",
                        iconAndRemark: "el-icon-check_success_" + "无",
                    });
                    if (update.resultCode == 200) {
                        this.$message.success("审批通过！");
                        this.init();
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                })
                .catch((action) => {
                    if (action === "cancel") {
                        this.$prompt("请输入不通过的原因", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                        })
                            .then(async ({ value }) => {
                                let update = await orderAudit({
                                    infoId: id,
                                    state: "03",
                                    title: "订单取消，审核未通过",
                                    iconAndRemark:
                                        "el-icon-close_danger_" + value,
                                });
                                if (update.resultCode == 200) {
                                    this.$message.success(
                                        "审批完成！此订单已被您取消。"
                                    );
                                    this.init();
                                } else {
                                    this.$message.error(res.resultMsg);
                                }
                            })
                            .catch(() => {});
                    }
                });
        },
        async showOrderDetail(id) {
            this.orderStep = [];
            this.orderDetailDialog = true;
            let res = await getOrderStep({ infoId: id });
            this.orderStep = res.data;
        },
        planHome() {
            this.$router.push("/reservation/myPlan");
        },
        async searchOrder() {
            let vo = {
                pageNo: 1,
                pageSize: 10,
                id: this.planId,
                content: this.searchInfo,
            };
            let res = await getOrderByPlanId(vo);
            this.totalSize = res.totalSize;
            this.order = res.data;
        },getRateInfo(rate){
          let str = rate.split('=');
          if(str[0] == 'A'){
            return '流程评分('+str[1] +') '
          }else if(str[0] == 'B'){
            return '服务评分('+str[1] +') '
          }else if(str[0] == 'C'){
            return '体验评分('+str[1] +') '
          }else{
            return ' 用户留言评论：'+str[1]
          }
        }
    },
};
</script>