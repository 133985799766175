<template>
    <div style="text-align:center">
        <h4>我的文件库</h4>
        <el-upload class="upload-demo"
           drag :on-error="errorHander"
           :on-success="successHander" :headers="{ 'cst-token': token }" action="/api/file/uploadFile">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>

        <el-table :data="files.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.createTime.includes(search) || data.remark.includes(search))  " style="width: 90%">
            <el-table-column label="文件名" prop="name">
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <div style="margin-left:60px;">
                            <el-form-item label="存储信息">
                                <!-- <el-collapse v-if="props.row.content">
                                    <el-collapse-item title="查看详情" name="userInput">
                                        <p v-for="(info,index) in props.row.content.split(',')" :key="index">{{info.replace('@#@', '：')}} </p>
                                    </el-collapse-item>
                                </el-collapse> -->
                            </el-form-item>
                            <el-form-item label="ID">
                                <span>{{props.row.id }}</span>
                            </el-form-item>
                            <el-form-item label="类型">
                                <span>{{props.row.type == '00' ? '个人文件' : '其它文件' }} ({{isImage(props.row.name) ? '图片' : '其它类型'}})</span>
                            </el-form-item>
                            <el-form-item label="状态">
                                <span>{{props.row.state == '00' ? '正常' : '异常' }}</span>
                            </el-form-item>
                            <el-form-item label="路径">
                                <span>{{props.row.path }}</span>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="大小" prop="size">
                 <template slot-scope="fSize">
                   {{parseInt(fSize.row.size / 1024)}}KB
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark">
                <template slot-scope="r">
                    <span style="cursor:pointer;" @click="update(r.row.id)">{{r.row.remark}}</span>  
                </template> 
            </el-table-column>
            <el-table-column label="上传时间" prop="createTime">
            </el-table-column>
            <el-table-column align="right">
                <template slot="header" slot-scope="searchFile">
                    <el-input v-model="search" size="mini" :id="searchFile" placeholder="搜索文件"/>
                </template>
                <template slot-scope="op">
                    <el-link type="primary" :href="op.row.path" :underline="false" icon="el-icon-download">下载</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="warning" @click="del(op.row.id)" :underline="false" icon="el-icon-delete">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { listFile, deleteFile,updateRemark } from "@/api/file";
export default {
    name: "FileHome",
    data() {
        return {
            files: [],
            search : '' 
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let res = await listFile();
            this.files = res.data;
        },
        successHander(response) {
            console.log("response = ", response.data);
            if (!response.data) {
                this.$message.warning(response.resultMsg);
                return;
            }
            this.$message.success("文件上传成功!");
            this.init();
        },errorHander(response) {
            this.$message.error("上传失败!"+response);
        },
        del(id) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
                let res = await deleteFile({ 'id': id });
                if (res.resultCode == 200) {
                    this.$message.success("文件删除成功!");
                    this.init();
                }else{
                     this.$message.warning("文件删除失败!");
                }
            })
            .catch(() => {
            });
            
        },isImage(fileName) {
            if(!fileName) return false
            var index= fileName.lastIndexOf(".");
            var ext = fileName.substr(index+1);
            return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1;
        },async update(id){
            this.$prompt('请输入备注', '修改', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            }).then(async ({ value }) => {
                let res = await updateRemark({'id':id , 'remark':value})
                if(res.resultCode == 200){
                    this.$message.success('修改备注成功')
                    this.init()
                }else{
                    this.$message.warning('修改失败')
                }
            }).catch(() => {});
        }
    },
    computed: {
        token() {
            return localStorage.getItem("cst-token");
        },
    },
};
</script>

<style scoped>
a:hover {
    text-decoration: none;
}
</style>