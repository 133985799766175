<template>
    <div>
         <div style="float:right;">
            <a style="cursor: pointer;" @click="$router.push('/adminHome/topic')"> <i class="el-icon-arrow-left" />返回</a>
        </div>
        <el-table :data="comments.filter(data => !search
            || data.createTime.includes(search) || data.content.includes(search) || data.nickname.includes(search)  )  " height="500" ref="multipleTable" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column property="nickname" label="评论人" width="200">
                <template slot-scope="scope">
                    <el-link type="primary" @click="$userInfoDialog(scope.row.createUser);">{{ scope.row.nickname }}</el-link>
                </template>
            </el-table-column>
            <el-table-column property="content" label="评论内容" show-overflow-tooltip width="650"></el-table-column>
            <el-table-column property="createTime" label="评论时间"></el-table-column>
            <el-table-column>
                <template slot="header" slot-scope="searchInfo">
                    <el-input v-model="search" size="mini" :id="searchInfo" placeholder="搜索评论信息" />
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" @click="deleteCommentOne(scope.row)" type="warning">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
          <div style="float:right;margin-top:10px">
            <el-button @click="deleteComment" icon="el-icon-scissors" type="danger">批量删除</el-button>
        </div>
    </div>
</template>

<script>
import { listComment, delComment } from "@/api/admin/topic";
export default {
    name: "CommentManage",
    data () {
        return {
            comments : [],
            multipleSelection: [],
            search: "",
            topicId:''
        }
    },mounted () {
        this.topicId = this.$route.query.topicId
        this.init(this.topicId)
    },methods: {
       async init(id){
           let res = await listComment({'topicId' : id})
            if(res.resultCode == 200){
                this.comments = res.data
            }
       } ,toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },deleteCommentOne(vo){
            this.$prompt(
                "确定删除评论吗? 删除后无法恢复 【评论："+vo.content+"】 请输入删除原因:",
                "删除帖子",
                {
                    confirmButtonText: "确定删除并通知发布者",
                    cancelButtonText: "算了",
                    type: "warning",
                }
            )
                .then(async ({ value }) => {
                    if (!value) {
                        this.$message.warning("原因不能为空！");
                        return;
                    }
                    let res = await delComment({
                        ids: vo.id,
                        reason: value,
                    });
                    if (res.resultCode == 200) {
                        this.$message.success("评论删除成功");
                        this.init();
                    } else {
                        this.$message.warning(res.resultMsg);
                    }
                })
                .catch(() => {});
        },deleteComment() {
            if (this.multipleSelection.length == 0) {
                this.$message.warning("请先选择要删除的评论");
                return;
            }
            let ids = [];
            let infos = [];
            for (
                let index = 0;
                index < this.multipleSelection.length;
                index++
            ) {
                ids.push(this.multipleSelection[index].id);
                infos.push(this.multipleSelection[index].content + "  ");
            }
            if (infos.length == this.comments.length) {
                this.$message.error("危险提示：请不要删除所有数据");
                return;
            }

            this.$prompt(
                "确定删除评论吗? 删除后无法恢复， 共" +
                    this.multipleSelection.length +
                    "条待删除信息：" +
                    infos.toString() +
                    " ，请输入删除原因:",
                "删除评论",
                {
                    confirmButtonText: "确定删除并通知发布者",
                    cancelButtonText: "算了",
                    type: "warning",
                }
            )
                .then(async ({ value }) => {
                    if (!value) {
                        this.$message.warning("原因不能为空！");
                        return;
                    }
                    console.log('ids.toString() ',ids.toString());
                    let res = await delComment({
                        ids: ids.toString(),
                        reason: value,
                    });
                    if (res.resultCode == 200) {
                        this.$message.success("评论删除成功");
                        this.init(this.topicId);
                    } else {
                        this.$message.warning(res.resultMsg);
                    }
                })
                .catch(() => {});
        }
    }
};
</script>

<style>
</style>