import { render, staticRenderFns } from "./CreatePlanMedical.vue?vue&type=template&id=1830dde0&scoped=true&"
import script from "./CreatePlanMedical.vue?vue&type=script&lang=js&"
export * from "./CreatePlanMedical.vue?vue&type=script&lang=js&"
import style0 from "./CreatePlanMedical.vue?vue&type=style&index=0&id=1830dde0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1830dde0",
  null
  
)

export default component.exports