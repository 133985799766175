/**
 * 预约平台 前端
 */
import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import 'element-ui/lib/theme-chalk/index.css';
import '/public/fontawesome-free/css/all.min.css'
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';
import Chat from 'vue-beautiful-chat'
Vue.use(Chat)
// import 'bootstrap/dist/css/bootstrap.min.css';
import '/public/sb-admin-2.min.css'
Vue.config.productionTip = false
import './plugins/element.js'
import {auth} from '@/api/userApi'
import webSocket from "@/util/ws.js";
Vue.prototype.webSocket = webSocket;
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'gHRwYlQyWoL8jRqzcPEQMrAqzMtCN4PS'
})
import userInfoDialog from "@/util/userInfoDialog.js";; 
Vue.prototype.$userInfoDialog = userInfoDialog.install; 
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

Vue.prototype.$_isMobile = function (){
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}
auth()
new Vue({
  render: h => h(App),
  store,router
}).$mount('#app')
