import requests  from "../request";


export const notify = (message) => {
    return requests({
         url:'/api/message/notifyAllUser', method:'post',params:message
     })
 }
 
 export const listNotify = () => {
    return requests({
         url:'/api/message/listNotify', method:'get'
     })
 }
 
 export const listPrivateMsg = () => {
    return requests({
         url:'/api/message/listPrivateMsg', method:'get'
     })
 }
 
       
 export const sendPrivateMsg = (vo) => {
    return requests({
         url:'/api/message/sendPrivateMsg', method:'post',params:vo
     })
 }
 export const deletePrivateMsg = (vo) => {
    return requests({
         url:'/api/message/deletePrivateMsg', method:'post',params:vo
     })
 }