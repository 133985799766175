export const regFenToYuan = (fen) =>{
    var num = fen;
    num=fen*0.01;
    num+='';
    var reg = num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
    num=num.replace(reg,'$1');
    num = toDecimal2(num)
    return num
};
const toDecimal2 = (x) => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}

const abs = function(val){
  //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
  var str = (val/100).toFixed(2) + '';
  var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
  var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
  var ret = intSum + dot;
  return ret;
  }

  export  const getDateDiff = (time) =>{
    if(!time){return time}
    var nowTime = new Date();
    var day = nowTime.getDate();
    var hours = parseInt(nowTime.getHours());
    var minutes = nowTime.getMinutes();
    // 开始分解付入的时间
   
    var timeday = time.substring(8, 10);
    var timehours = parseInt(time.substring(11, 13));
    var timeminutes = time.substring(14, 16);
    var d_day = Math.abs(day - timeday);
    var d_hours = hours - timehours;
    var d_minutes = Math.abs(minutes - timeminutes);
    if (d_day <= 1) {
        switch (d_day) {
            case 0:
                if (d_hours == 0 && d_minutes > 0) {
                    return d_minutes + '分钟前';
                } else if (d_hours == 0 && d_minutes == 0) {
                    return '刚刚';
                } else {
                    return d_hours + '小时前';
                }
                break;
            case 1:
                if (d_hours < 0) {
                    return (24 + d_hours) + '小时前';
                } else {
                    return d_day + '天前';
                }
                break;
        }
    } else if (d_day > 1 && d_day < 10) {
        return d_day + '天前';
    } else {
        return time;
    }
  }