<template>
    <div>
        
      
        <div>
            <el-button icon="el-icon-search" title="搜索帖子" circle @click="showSearchInput"></el-button>
            <div v-show="showSearch" style="width:95%;margin:0 auto;">
                  <input type="text" class="form-control" id="searchInputText" @keydown.enter="searchTopic"  placeholder="搜一搜帖子"><hr>
            </div>
        </div>
   
        <div class="card" v-for="topic in topics" :key="topic.id" style="width:85%;margin:0 auto;margin-bottom:55px;" :id="topic.id">
            <div class="card-header mb-10">
                <div style="height: 40px;margin-left: 0;" class="panel-heading">
                    <div style="width:55px;float: left;">
                        <img @click="showUser(topic.createUser)" style="height: 50px;width: 50px;border-radius: 8px;border: 1px solid white;cursor: pointer;" :src="topic.userAvatar">
                    </div>
                    <div style="width:75%;float: left;margin-left: 3px;">
                        <div>
                            <span class="panel-title" style="position:relative;text-align: left;"><a @click="showUser(topic.createUser)" style="font-size: 19px;text-decoration: none;" href="javascript:void(0)">
                                    {{topic.nickname}}
                                </a></span>
                        </div>
                        <div>
                            <span class="panel-title" style="text-align: left;">{{topic.title}}</span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card-body" style="cursor:pointer;" @click="enterTopicDetail(topic.id)">
                <div style="clear: left;font-size: 13px;">
                    <div v-html="topic.content"></div>
                    <div class="tag-group" v-if="topic.tags">
                        <el-tag size="mini" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in topic.tags.split(',')" :key="index">{{item}}</el-tag>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div style="text-align: center;height:20px;">
                    <div style="float: left;">
                         <i class="fa fa-eye" title="浏览量" aria-hidden="true" style="margin-right:10px;font-size:8px">
                            <span style="font-size:13px;" id="topicVisitorNum" title="浏览量"> {{topic.visitorNum}}</span>
                        </i>
                    </div>
                    <div style="float: right;font-size:16px;">
                        <i @click="like(topic.id)" style="cursor: pointer;font-size: 13px;color:#FF6685"  title="赞" :id="'like'+topic.id" :class="isLike(topic.likeUser,topic.id)" aria-hidden="true">
                        </i> <span style="font-size: 13px;" :id="'likeNum'+topic.id">{{topic.likeUser ? topic.likeUser.split('-').length : ''}}</span>
                        <el-divider direction="vertical"></el-divider>
                        <!-- <i style="cursor: pointer;font-size: 17px;" title="赞" class="far fa-heart" aria-hidden="true"></i> -->
                        <i style="cursor: pointer;font-size: 13px;" title="回复" class="el-icon-chat-dot-round" aria-hidden="true" @click="showCommentDialog(topic.id)">
                            <span>评论</span>
                        </i>
                        <span name="showTime" style="font-size: 13px;"></span>&nbsp;
                        <span style="font-size:13px" v-if="isMe(topic.createUser)" @click="deleteTopic(topic.id,topic.title)"><a style="cursor: pointer;"><el-divider direction="vertical"></el-divider><i class="el-icon-delete-solid" />删除</a></span>
                    </div>
                </div>
            </div>

        </div>
        <div style="width:80%;margin:0 auto;margin-bottom:15px;" v-if="topics.length">
            <el-pagination @current-change="handleCurrentChange" background layout="total, prev, pager, next" :page-size="10" :total="totalSize">
            </el-pagination>
        </div>

        <el-empty v-show="!topics.length" description="还没有帖子..."></el-empty>
        <el-drawer title="发表评论" :modal="false" :size="'30%'" :visible.sync="drawer" direction="btt">
            <div class="row" style="margin-left:10%;margin-right:10%">
                <div style="width:90%;">
                    <el-input type="textarea" id="commentInput" autosize placeholder="请输入..." v-model="commentInfo">
                    </el-input>
                </div>
                <div>
                    <el-button size="small" @click="comment" style="width:100px" plain type="primary">评论</el-button>
                </div>
            </div>
        </el-drawer>
          <div class="createTopicBtn">
            <el-button type="primary" style="width:60px;height:60px;font-size:20px;" title="我要发帖" @click="$router.push('/reservation/createTopic')" icon="el-icon-edit" circle></el-button>
        </div>
        <ChatRoom></ChatRoom>
    </div>
</template>

<script>
import { list, addComment, deleteTopic,topicLike } from "@/api/topic";
import ChatRoom from '@/pages/module/ChatRoom.vue'
export default {
    name: "ForumArea",
    data() {
        return {
            drawer: false,
            commentInfo: "",
            topics: [],
            totalSize: 0,
            curTopicId:'',
            showSearch:false,
        };
    }, components: {ChatRoom},
    mounted() {
        this.initTopics();
    },
    methods: {
        enterTopicDetail(topicId) {
            this.$router.push("/reservation/topicDetail?topicId=" + topicId);
        },
        async initTopics() {
            let request = { pageNo: 1, pageSize: 10, orderType: "create_time" };
            let res = await list(request);
            this.totalSize = res.totalSize;
            this.topics = res.data;
        },
        async handleCurrentChange(val) {
            let request = {
                pageNo: val,
                pageSize: 10,
                content :  $("#searchInputText").val() ,
                orderType: "create_time",
            };
            let res = await list(request);
            this.totalSize = res.totalSize;
            this.topics = res.data;
        },deleteTopic(topicId,title) {
            this.$confirm("确定删除贴子吗？("+title+")", "提示", {confirmButtonText: "确定", cancelButtonText: "取消",type: "warning", })
                .then(async () => {
                   let res = await deleteTopic({ id: topicId });
                    if (res.resultCode == 200) {
                         $("#"+topicId).hide(100)
                        this.$message.success("帖子删除成功！");
                        this.totalSize--;
                    } else {
                        this.$message.error(res.resultMsg);
                    }
            })
            .catch(() => {});
            
        }, isMe(userId){
           return this.$store.state.user.userInfo && (userId == this.$store.state.user.userInfo.userId)
        },showCommentDialog(topicId){
            this.curTopicId = topicId
            this.drawer = true
        },async comment(){
            if(!this.commentInfo) return
             if(this.commentInfo.toLowerCase().indexOf("<script>") != -1 ){
                 this.$message.warning('请不要输入这个敏感内容: <script>')
                 return;
             }
           let res = await addComment( {topicId:this.curTopicId,content:this.commentInfo})
           if(res.resultCode == 200){
              this.enterTopicDetail(this.curTopicId)
           }else{
              this.$message.error(res.resultMsg); 
           }
        },async like(id){
           let num = $('#likeNum'+id).text();
           num = num ? num : 0
           let res = await topicLike({'id': id});
           if(res.resultCode == 200){
               if(res.data == true){ 
                   $("#like"+id).css({color:'#FF6685'});
                   $("#like"+id).attr("class","fas fa-heart")
                   $('#likeNum'+id).text(parseInt(num )+1)
               }else{
                   $("#like"+id).attr("class","far fa-heart")
                   $('#likeNum'+id).text(parseInt(num )-1)
                   if(parseInt(num) - 1 == 0){$('#likeNum'+id).text('')}
               }
           }else{
               this.$message.error('点赞失败');
           }
        } ,isLike(likeUser,id){
           if(this.$store.state.user.userInfo){
               let like =  likeUser.indexOf(this.$store.state.user.userInfo.userId) != -1
               if(like){
                   $("#like"+id).css({color:'#FF6685'});
                   $("#like"+id).attr("class","fas fa-heart")
                   return "fas fa-heart"
               }else{
                   $("#like"+id).attr("class","far fa-heart")
                   return "far fa-heart"
               }
           }else{
               return "far fa-heart"
           }
        },showSearchInput(){
            $("#searchInputText").val('')
            this.showSearch = !this.showSearch
        },async searchTopic(){
             let request = { pageNo: 1, pageSize: 10, content : $("#searchInputText").val() ,orderType: "create_time" };
            let res = await list(request);
            this.totalSize = res.totalSize;
            this.topics = res.data;
        }, showUser(userId){
            this.$userInfoDialog(userId);
        }
    },
};
</script>

<style>
body{
    background-color: rgb(219, 13, 13);
}
.createTopicBtn {
    position: fixed;
    left: 25px;
    bottom: 25px;
}
a:hover{
    text-decoration: none;
}
</style>