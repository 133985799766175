<template>
        <div class="row">
        <div class="col-lg-8">
            <!-- Change password card-->
            <div class="card mb-4">
                <div class="card-header">修改密码</div>
                <div class="card-body">
                    <form>
                        <!-- Form Group (current password)-->
                        <div class="mb-3">
                            <label class="small mb-1" for="currentPassword">当前密码</label>
                            <input class="form-control" v-model="oldPassword" id="currentPassword" type="password" placeholder="Enter current password" />
                        </div>
                        <!-- Form Group (new password)-->
                        <div class="mb-3">
                            <label class="small mb-1" for="newPassword">新密码</label>
                            <input class="form-control" v-model="newPassword" id="newPassword" type="password" placeholder="Enter new password" />
                        </div>
                        <!-- Form Group (confirm password)-->
                        <div class="mb-3">
                            <label class="small mb-1" for="confirmPassword">确认密码</label>
                            <input class="form-control" v-model="newPasswordRepeat" id="confirmPassword" type="password" placeholder="Confirm new password" />
                        </div>
                        <button class="btn btn-primary" @click="update"  type="button">立即修改</button>
                    </form>
                </div>
            </div>
            <!-- Security preferences card-->
            <div class="card mb-4">
                <div class="card-header">安全设置</div>
                <div class="card-body">
                    <!-- Account privacy optinos-->
                    <h5 class="mb-1">账户安全</h5>
                    <p class="small text-muted">查看个人主页和信息的权限</p>
                    <form>
                        <div class="form-check">
                            <input class="form-check-input" id="radioPrivacy1" type="radio" name="radioPrivacy" checked="" />
                            <label class="form-check-label" for="radioPrivacy1">公开，他人可以浏览</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="radioPrivacy2" type="radio" name="radioPrivacy" />
                            <label class="form-check-label" for="radioPrivacy2">保密，别人不能看</label>
                        </div>
                    </form>
                    <hr class="my-4" />
                    <!-- Data sharing options-->
                    <h5 class="mb-1">通知设置</h5>
                    <p class="small text-muted">系统邮件通知接收设置</p>
                    <form>
                        <div class="form-check">
                            <input class="form-check-input" id="radioUsage1" type="radio" name="radioUsage" checked="" />
                            <label class="form-check-label" for="radioUsage1">只接收预约相关和系统通知邮件</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="radioUsage2" type="radio" name="radioUsage" />
                            <label class="form-check-label" for="radioUsage2">接收本平台所有的相关邮件</label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <!-- Two factor authentication card-->
            <div class="card mb-4">
                <div class="card-header">平台私信功能</div>
                <div class="card-body">
                    <p>他人可通知私信联系我</p>
                    <form>
                        <div class="form-check">
                            <input class="form-check-input" id="twoFactorOn" type="radio" name="twoFactor"  checked=""  />
                            <label class="form-check-label" for="twoFactorOn">同意</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="twoFactorOff" type="radio" name="twoFactor" />
                            <label class="form-check-label" for="twoFactorOff">不同意</label>
                        </div>
                        <div class="mt-3">
                            <label class="small mb-1" for="twoFactorSMS">备注信息</label>
                            <input class="form-control" id="twoFactorSMS" type="tel" placeholder="备注" />
                        </div>
                    </form>
                </div>
            </div>
            <!-- Delete account card-->
            <div class="card mb-4">
                <div class="card-header">账号注销</div>
                <div class="card-body">
                    <p>用户可选择注销账号，注销后数据将不可恢复，请谨慎操作...</p>
                    <button class="btn btn-danger-soft text-danger" @click="destory" type="button">去注销</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { updatePsw } from "@/api/userApi";
import { destroy } from "@/api/userApi";

export default {
    name:'UserSecurity',
    data () {
        return {
            oldPassword : '',
            newPassword : '',
            newPasswordRepeat : '',
        }
    },
    methods: {
         update(){
            if(this.oldPassword.length < 3 || this.newPassword.length < 3){
                this.$message.warning('密码输入不规范')
                return;
            }
            if( this.newPasswordRepeat != this.newPassword){
                this.$message.warning('两次新密码不一致')
                return;
            }
            this.$confirm('确定修改密码吗? 若修改成功，需要重新登录~', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async () => {
                let res = await updatePsw({username:this.$store.state.user.userInfo.username, oldPwd:this.oldPassword, newPwd:this.newPassword})
            if(res.resultCode == 200 && res.data == true){
                this.$message.success('修改成功，请重新登录！')
                localStorage.removeItem('cst-token')
                this.$router.replace('/login')
            }else{
                this.$message.error('修改失败！'+res.resultMsg)
            }
            }).catch(() => {
                return
             });
        },destory(){
            this.$alert('因站长毕设答辩需要用户数据，2022年5月期间，不支持注销，望理解。', '提示', {
                confirmButtonText: '确定'});
            // this.$confirm("确定要注销账号?", "警告", {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消",
            //     type: "warning",
            // })
            //     .then(async () => {
            //         let res = await destroy();
            //         this.submiting = false;
            //         if (res.resultCode == 200) {
            //              localStorage.removeItem("cst-token")
            //               this.$router.replace({
            //                 name: "result",
            //                 params: {
            //                     title: "账号已注销",
            //                     message: "感谢您的使用",
            //                     iconType: "success",
            //                 },
            //             });
            //         } else {

            //         }
            //     })
            //     .catch(() => {});
        }
    }
}
</script>

<style>

</style>