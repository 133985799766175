<template>
  <div class="main">
         <div class="tag-group"> 
            <el-tag  type="success" style="margin-right:15px;">总积分 {{userLevel.rewardNum}}</el-tag>
            <el-tag  v-if="userLevel" type="primary" style="margin-right:15px;">今日积分 {{userLevel.todayRewardNum}}</el-tag>
            <el-tag  v-if="userLevel.level" type="warning">当前等级 {{userLevel.level.grade}} （{{userLevel.level.role}}）</el-tag>
            <div style="float:right">
                <el-link type="primary" :underline="false" @click="$router.push('/reservation/luckCircle')"><i class="el-icon-present"></i> 去抽奖</el-link>
            </div>
         </div>
         <hr>
          <el-table height="520" :data="rewards.filter(data => !search || data.createTime.includes(search) || data.remark.includes(search) || data.typeName.includes(search))  " style="width: 100%">
            <el-table-column label="类别名称" prop="typeName">
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <div style="margin-left:60px;">
                            <el-form-item label="详细信息">
                            </el-form-item>
                            <el-form-item label="ID">
                                <span>{{props.row.id }}</span>
                            </el-form-item>
                            <el-form-item label="目标ID">
                                <span>{{props.row.targetId }}</span>
                            </el-form-item>
                        </div>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="奖励值" prop="reward">
            </el-table-column>
            <el-table-column label="备注" prop="remark">
                <template slot-scope="r">
                    <span style="cursor:pointer;" >{{r.row.remark}}</span>  
                </template> 
            </el-table-column>
            <el-table-column label="获得时间" prop="createTime">
                  <template slot="header" slot-scope="searchReward">
                    <el-input v-model="search" size="mini" :id="searchReward" placeholder="搜索"/>
                </template>
            </el-table-column>
        </el-table>
  </div>
</template>

<script>
import {listReward} from '@/api/reward'
import {getUserLevel} from '@/api/userApi'
export default {
    name : 'UserReward',
    data () {
        return {
            rewards:[],
            userLevel:'',
            search:''
        }
    },mounted () {
        this.init()
    },methods: {
        async init(){
            let res= await listReward()
            if(res.resultCode = 200){
                this.rewards = res.data
            }
            let r = await getUserLevel()
            if(r.resultCode = 200){ this.userLevel = r.data}
            
        }
    }
}
</script>

<style>
.main {
    width: 85%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>