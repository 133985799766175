<template>
    <div>
         <div>
            <div class="col-xl-3 col-md-6 mb-4" style="float:left" v-for="(coupon, index) in coupons" :key="index">
                <div :class="coupon.remark == '由抽奖获得' ? 'card border-left-success shadow h-100 py-2' : 'card border-left-warning shadow h-100 py-2'">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div  style="cursor:pointer;" class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    <span style="font-size:20px">{{coupon.name}} {{getState(coupon.state)}}</span>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    <span style="font-size:11px">过期时间：{{coupon.dueTime}}</span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <el-popover placement="bottom" :title="coupon.remark" width="280" trigger="click"
                                :content="'获得时间：'+coupon.createTime">
                                    <i style="cursor:pointer" slot="reference" class="el-icon-present fa-3x text-gray-300"></i>
                                </el-popover>
                                <el-popover placement="bottom" title="优惠金额说明" width="280" trigger="click"
                                :content="'支付时可抵扣 '+formatMoney(coupon.amount)+' 元'">
                                <i style="cursor:pointer" slot="reference" class="fa-3x text-gray-300"> ¥</i>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                </div><br>
            </div>
        </div>

        <el-empty v-if="coupons.length == 0" description="没有任何优惠卷"><el-link type="primary" :underline="false" @click="$router.push('/reservation/luckCircle')"><i class="el-icon-present"></i> 去抽奖</el-link></el-empty>
    </div>

</template>

<script>
import { listCoupon } from "@/api/coupon";
import { regFenToYuan } from "@/util";
export default {
    name: "UserCoupon",
    data() {
        return {
            coupons: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let res = await listCoupon();
            if (res.resultCode == 200) {
                this.coupons = res.data;
            }
        },getState(state){
            if(state == '00'){
                return ''
            }else if(state == '01'){
                return '(无效)'
            }else if(state == '01'){
                return '(已过期)'
            }
        },formatMoney(fen){
            return regFenToYuan(fen)
        }
    },
};
</script>

<style>
</style>