<template>
  <div style="text-align:center">
   <div class="container">
        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <div class="row" >
                    <div style="width:500px;margin:0 auto">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">预约平台-后台管理</h1>
                            </div>
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-user" id="username"
                                        placeholder="输入账号" v-model="username">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control form-control-user" id="password"
                                        placeholder="输入密码" v-model="password">
                                </div><br>
                                <button @click="login"  class="btn btn-primary btn-user btn-block">
                                    登 录
                                </button>
                                <hr>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import {login} from '@/api/admin'
export default {
    name : 'AdminLogin',
    data () {
        return {
            username : '',
            password : ''
        }
    },methods: {
        async login(){
            let res = await login({'username':this.username,'password':this.password})
            if(res.resultCode == 200){
                this.$message.success('登录成功')
                this.$router.push('/adminHome/dataview')
            }else{
                this.$message.error(res.resultMsg)
            }
        }
    }
}
</script>

<style>

</style>