<template>
  <div>
         <el-table :data="orders.filter(data => !search || data.orderId.toLowerCase().includes(search.toLowerCase()) || data.payId.includes(search) || data.moneyYuan.includes(search) || data.createTime.includes(search) || data.title.includes(search))  " style="width: 100%" :stripe="true">
      <el-table-column prop="orderId" label="订单号"> </el-table-column>
        <el-table-column prop="title" label="类型"> </el-table-column>
      <el-table-column prop="moneyYuan" label="金额" > 
           <template slot-scope="cash">
               <strong style="font-size:16px;">{{cash.row.type == '00' || cash.row.type == '03'  || cash.row.type == '02' ? '+' : '- '}} {{cash.row.type == '00' ?  cash.row.moneyYuan : cash.row.moneyRealYuan}}</strong>
               </template>
      </el-table-column>
      <el-table-column label="订单状态"> 
          <template slot-scope="stateProp">
            <span style="color:green" v-if="stateProp.row.state=='01'"><i class="el-icon-circle-check"></i> {{payState(stateProp.row.state)}}</span>
            <span style="color:red" v-else-if="stateProp.row.state=='02'"><i class="el-icon-warning-outline"></i> {{payState(stateProp.row.state)}}</span>
            <span v-else-if="stateProp.row.state=='03'"><i class="el-icon-remove-outline"></i> {{payState(stateProp.row.state)}}</span>
            <span style="color:orange;cursor:pointer;" v-else ><i class="el-icon-shopping-cart-full"></i> {{payState(stateProp.row.state)}} </span>
          </template>
      </el-table-column>
    
      <el-table-column prop="createTime">
        <template slot-scope="time">{{dateFormat(time.row.createTime)}}</template>
        <template slot="header" slot-scope="searchOrder">
            <el-input v-model="search" size="mini" :id="searchOrder" placeholder="搜索订单"/>
        </template>
      </el-table-column>
      <el-table-column  label="操作" width="120"> 
          <template slot-scope="pay">
            <el-link  :underline="false" @click="showOrderDetail(pay.row)" type="primary" icon="el-icon-postcard">查看详情</el-link>
            <el-link  :underline="false" @click="$userInfoDialog(pay.row.createUser);" type="primary" icon="el-icon-user">用户信息</el-link>
          </template>
      </el-table-column>

    </el-table>
      <el-dialog
        :visible.sync="orderDetailDialog"
        width="70%">
        <template slot="title">
                <i class="el-icon-notebook-2"></i>订单详情  ({{currentOrder.title}})
        </template>
        <div class="block">
            <el-divider content-position="center"><span style="font-size:15px;">
                <span style="color:green" v-if="currentOrder.state=='01'"><i class="el-icon-circle-check"></i> {{payState(currentOrder.state)}}</span>
            <span style="color:red" v-else-if="currentOrder.state=='02'"><i class="el-icon-warning-outline"></i> {{payState(currentOrder.state)}}</span>
            <span v-else-if="currentOrder.state=='03'"><i class="el-icon-remove-outline"></i> {{payState(currentOrder.state)}}</span>
            <span style="color:orange;" v-else><i class="el-icon-shopping-cart-full"></i> {{payState(currentOrder.state)}}</span>
                 </span></el-divider>
            <p><el-tag size="small">订单号</el-tag> <strong>{{currentOrder.orderId}}</strong>
            <p>支付ID：{{currentOrder.payId}}</p>
            <p>订单金额： ¥ {{currentOrder.moneyYuan}}</p>
            <p>实付金额： <strong>¥ {{currentOrder.moneyRealYuan}}</strong></p>
            <p v-if="currentOrder.discountMoney">优惠金额： <strong>¥ {{currentOrder.discountMoneyYuan}}</strong></p>
            <p>订单描述：{{currentOrder.content}}</p>
            <p>支付方式：{{currentOrder.type =='00' ? '微信支付' : '账户钱包'}}</p>
            <p>{{currentOrder.state!='03' ? '完成' : '取消'}}时间：{{currentOrder.updateTime ? currentOrder.updateTime : '暂无'}}</p>
            <p v-if="currentOrder.remark">备注: {{currentOrder.remark}}</p>
            <p v-if="currentOrder.state == '02'" style="color:orange">对订单如有疑问，请咨询客服: QQ1315401811</p>
            <el-divider content-position="center"><span style="font-size:13px;"><i class="el-icon-alarm-clock"></i>下单时间 {{currentOrder.createTime}} </span></el-divider>
        </div>
        </el-dialog>
  </div>
</template>

<script>
import { listRefundOrder } from "@/api/admin/payOrder.js";
import { getDateDiff } from "@/util";
export default {
 name :'PayRecordManage',
  data () {
     return {
         orders : [],
         currentOrder :'',
         search : '',
         orderDetailDialog: false,
         payDialog: false,
     }
 },mounted () {
     this.init()
 },methods: {
     async init(){
        let res =  await listRefundOrder();
        this.orders = res.data
     },payState(state){
         if(state == '00'){
             return "待支付"
         }else if(state == '01'){
             return "交易成功"
         }else if(state == '02'){
             return "支付失败"
         }else if(state == '03'){
             return "已取消"
         }else{
            return "订单异常"
         }
     },showOrderDetail(order){
         this.currentOrder = order
         this.orderDetailDialog = true;
     },dateFormat(date) {
        return getDateDiff(date);
     }
    }
}
</script>

<style>
 a:hover{
        text-decoration: none;
    }
</style>