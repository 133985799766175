import requests  from "../request";

export const listOrder = (vo) => {
    return requests({
         url:'/api/admin/reservationOrder/list', method:'post',data:vo
     })
 }
 
 export const updateOrder = (vo) => {
    return requests({
        url: "/api/admin/reservationOrder/update",method: "post",data:vo
    });
 }
 export const refund = (vo) => {
    return requests({
        url: "/api/admin/reservationOrder/refund",method: "post",data:vo
    });
 }

 export const exportExcel = (vo) => {
    return requests({
        url: "/api/admin/reservationOrder/export",method: "post",data:vo, responseType: "blob",headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
 }


 export const getOrderStep = (vo) => {
    return requests({
        url: "/api/admin/reservationOrder/getOrderStep",method: "post",params:vo
    });
 }
