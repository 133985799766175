import requests  from "../request";


export const list = () => {
    return requests({
         url:'/api/admin/user/list', method:'post'
     })
 }

 export const update = (vo) => {
    return requests({
         url:'/api/admin/user/update', method:'post',data:vo
     })
 }

 export const ban = (userId) => {
    return requests({
         url:'/api/admin/user/ban', method:'post',params:userId
     })
 }