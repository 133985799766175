<template>

    <div class="main">
        <div>
            <h4>发布预约事项（快速模式）</h4><br>
            <div class="row" style="width:60%">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-white">标题</span>
                    </div>
                    <input type="text" class="form-control" v-model="title" placeholder="请输入预约标题" aria-describedby="basic-addon1">
                </div>
            </div>

            <div class="row">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white">设置时段</span>
                    
                </div>
                <el-date-picker style="margin-right:10px;" v-model="startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择活动开始时间">
                </el-date-picker>
                <el-date-picker v-model="endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择活动结束时间">
                </el-date-picker>
                
                <div class="input-group-prepend" style="margin-left:20px;">
                    <span class="input-group-text bg-white">最大预约人数</span>
                    <el-input-number v-model="maxAmount" :min="1"></el-input-number>
                </div>
            </div><br>

            <div class="row">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white">预约者需提交</span>
                </div>
                <el-select style="width:60%" v-model="value" multiple filterable allow-create default-first-option placeholder="请选择标签 或者 输入自定义内容然后按回车">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <br><br>
            <!-- 自定义标签 -->
            <div class="row">
                预约标签：
                <el-tag size="medium" :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
                    {{tag}}
                </el-tag>
                <el-input class="input-new-tag" v-if="inputVisible" style="width:15%;" v-model="inputValue" ref="saveTagInput" size="medium" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                </el-input>
                <el-button v-else class="button-new-tag" size="medium" @click="showInput">+新建标签</el-button>
            </div>
            <br>

            <div class="row">
                <span>常用设置 &nbsp;&nbsp;</span>
                <el-switch style="margin-right:20px;" v-model="needFile" active-color="#13ce66" inactive-color="#E8E9EB" active-text="预约需要提交附件"> </el-switch>
            </div>
            <br> <br>
            预约事项说明
            <!-- <quill-editor ref="text" v-model="content" class="myQuillEditor" :options="editorOption" /><br><br> -->

            <div style="border: 1px solid #ccc;">
                    <Toolbar
                        style="border-bottom: 1px solid #ccc"
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                        :mode="mode"
                    />
                    <Editor
                        style="height: 400px; overflow-y: hidden;"
                        v-model="html"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onCreated="onCreated"
                    />
            </div>

            <hr>
            <div>
                <el-button type="primary" style="width:220px;" :loading="creating" @click="addReservation">提 交</el-button>
                <el-button type="warning" @click="showMoreSetDialog">更多设置</el-button>
            </div>

            <!-- 更多设置 -->
            <el-dialog title="更多设置" :visible.sync="showMoreSet" :width="moreSetWidth">
                <el-switch v-model="needMoney" active-color="#13ce66" inactive-color="#E8E9EB" active-text="预约需要付费"> </el-switch>
                <br><br>
                <input type="text" class="form-control" v-show="needMoney" style="width:80%" v-model.number="money" placeholder="输入金额（元）" aria-describedby="basic-addon1">
            </el-dialog>
        </div>

        <el-dialog 
            title="系统通知"
            :visible.sync="resultShow"
            width="50%"
            :destroy-on-close="true"
            >
            <el-result icon="success" title="预约事项创建成功！" subTitle="用户可通过下方二维码或链接进行预约，建议保存二维码至本地 ~"></el-result>
            <div class="row justify-content-center">
                <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div>
            </div>
            <div class="row justify-content-center" style="margin:5px">
                <div class="row">
                        预约链接：<a style="text-decoration: none" :href="QR_URL" target="_blank">{{QR_URL}}</a>
                </div>
            </div>
            </el-dialog>
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { addInfo } from "@/api/reservation";
import QRCode from "qrcodejs2";
export default {
    name:'CreatePlanQuick',
    components: { Editor, Toolbar },
    data() {
        return {
            title: "",
            startTime: "",
            endTime: "",
            endTime: "",
            addr: "当前预约无需地址信息",
            selectAddrText: "",
            maxAmount: "",
            signCode: "123456",
            getAddr: false,
            dialogVisible: false,
            showMoreSet: false,
            editorOption: {},
            nowCreate: true,
            qrCode: true,
            delay: false,
            options: [
                {
                    value: "手机号",
                    label: "手机号",
                },
                {
                    value: "邮箱",
                    label: "邮箱",
                },
                {
                    value: "地址",
                    label: "地址",
                },
                {
                    value: "学校",
                    label: "学校",
                },
                {
                    value: "身份证号",
                    label: "身份证号",
                },
            ],
            value: [],
            dynamicTags: ["快速预约", "其它", "校园"],
            inputVisible: false,
            inputValue: "",
            tag: "",
            pubTime: "",
            delayAmount: "",
            money: "",
            needMoney: false,
            emailNotify: false,
            comment: true,
            multSign: false,
            banCoupons: false,
            creating: false,
            resultShow: false,
            mustLogin: true,
            mustAudit: false,
            needFile: false,
            signLocation: false,
            QR_URL: "",
            editor: null,
            html: '',
            toolbarConfig: {},
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
            moreSetWidth:'20%'
        };
    },mounted() {
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        onCreated(editor) {
           this.editor = Object.seal(editor)
           this.initUploadImage();
           this.initUploadVideo();
        },initUploadImage(){
            var config = this.editor.getMenuConfig('uploadImage')
            config.server = '/api/file/imageHome/upload'
            config.maxFileSize= 8 * 1024 * 1024
            config.fieldName = 'file',
            config.headers={
                'cst-token': localStorage.getItem('cst-token'),
            }
            config.withCredentials= true
            config.onError = this.uploadImgError;
            config.onFailed = this.uploadImgFailed;
            config.onSuccess = this.uploadImgSuccess;
        },initUploadVideo(){
            var config = this.editor.getMenuConfig('uploadVideo')
            config.server = '/api/file/videoHome/upload'
            config.maxNumberOfFiles=3
            config.maxFileSize= 20 * 1024 * 1024
            config.fieldName = 'file',
            config.headers={
                'cst-token': localStorage.getItem('cst-token'),
            }
            console.log('config.headers = ',config.headers);
            config.withCredentials= true
            config.onError = this.uploadImgError;
            config.onFailed = this.uploadImgFailed;
            config.onSuccess = this.uploadImgSuccess;
        }
        ,uploadImgError(file){
            this.$message.error("上传失败，请稍后再试 ("+file.name+")")
        },uploadImgFailed(file){
            this.$message.error("添加失败!("+file.name+")")
        },uploadImgSuccess(){
            this.$message.success("添加成功！")
        },
        submitForm() {},
        handleClose(tag) {
            console.log("dynamicTags = ", this.dynamicTags);
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                console.log("dynamicTags = ", this.dynamicTags);
                this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },
        addReservation() {
            if(!this.checkInput()){return;}
            this.$confirm('确定创建此预约事项吗', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.creating = true
                this.value.forEach((val, index) => {
                    if(val){this.value[index] =  val.replace(',','，') }
                });
                this.dynamicTags.forEach((val, index) => {
                    if(val){ this.dynamicTags[index] = val.replace(',','，') }
                });
                let vo = {
                    title: this.title,
                    moduleId: 1,
                    detail: this.html,
                    signCode: this.signCode,
                    maxAmount: this.maxAmount,
                    tags: this.value.toString(),
                    submitTags: this.dynamicTags.toString(),
                    commentState: this.comment ? "1" : "0",
                    delay: this.delayAmount ? this.delayAmount : 0,
                    qrCodeSign: this.qrCode ? "1" : "0",
                    emailNotify: this.emailNotify ? "1" : "0",
                    repeatSign: this.multSign ? "1" : "0",
                    coupons: this.banCoupons ? "0" : "1",
                    addr: this.addr,
                    needMoney: this.money ? this.money * 100  : 0,
                    pubTime: this.pubTime,
                    startTime: this.startTime,
                    endTime: this.endTime,
                    mustLogin: this.mustLogin ? "1" : "0",
                    mustAudit: this.mustAudit ? "1" : "0",
                    needFile: this.needFile ? "1" : "0",
                    signLocation: this.signLocation ? "1" : "0",
                };
                let res = await addInfo(vo);
                if (res.resultCode == 200) {
                    this.QR_URL = res.data;
                    this.resultShow = true;
                    if(this.qrCode){
                        this.creatQrCode();
                    }
                } else {
                    this.$message.error(res.resultMsg);
                }
                this.creating = false;
            }).catch(() => {});
           
        },
        creatQrCode() {
            console.log("QRurl = ", this.QR_URL);
            this.$nextTick(() => {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.QR_URL, // 需要转换为二维码的内容
                    width: 150,
                    height: 150,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            });
        },checkInput(){
            if(!this.startTime || !this.endTime){
                this.$message.warning('预约时间设定不能为空')
                return false;
            }
            if(this.startTime > this.endTime){
                this.$message.warning('开始时间不能超过结束时间')
                return false;
            }

            if(this.delay == true){
                if(!this.delayAmount){
                     this.$message.warning('请指定可迟到时间')
                     return false;
                }
            }
             if(this.needMoney == true){
                if(!this.money){
                     this.$message.warning('开启付费模式后，必须指定付费金额')
                     return false;
                }
            }
            return true;
        },showMoreSetDialog(){
            if(!this.$_isMobile()){
                this.moreSetWidth = '25%'
            }else{
                this.moreSetWidth = '80%'
            }
            this.showMoreSet = true
        }
    },
};
</script>

<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.qrcode {
    display: inline-block;
    width: 180px;
    height: 180px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
}
.main {
    width: 87%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.bm-view {
  width: 100%;
  height: 400px;
}
</style>