import requests  from "../request";


export const listCoupon = () => {
    return requests({
         url:'/api/coupon/list', method:'get'
     })
 }

 export const getCoupon = () => {
    return requests({
         url:'/api/coupon/getUserCoupon', method:'get'
     })
 }
 export const getRandomCoupon = () => {
    return requests({
         url:'/api/coupon/getRandomCoupon', method:'post'
     })
 }
 export const checkSignEveryday = () => {
    return requests({
         url:'/api/coupon/checkSignEveryday', method:'post'
     })
 }