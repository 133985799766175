<template>
    <div v-if="title">
        <br><br><br>
        <el-col>
            <el-result :icon="iconType" :title="title" :subTitle="message">
                <template slot="extra">
                    <el-button v-if="title!='账号已注销'" type="primary" @click="returnHome" size="medium">返回</el-button>
                </template>
            </el-result>
        </el-col>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    name: "Result",
    data() {
        return {
            title:'',
            message:'',
            iconType:'',
        };
    },
    mounted() {
        this.title = this.$route.params.title;
        this.message = this.$route.params.message;
        this.iconType = this.$route.params.iconType;
    },
    methods: {
        returnHome(){
            this.$router.replace('/reservation')
        }
    },
};
</script>

<style>
.qrcode {
    display: inline-block;
    width: 180px;
    height: 180px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
}
</style>