<template>
  <div>
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">我的发布</h1>
      </div>
    <el-table :data="plans" style="width: 100%" :stripe="true">
      <el-table-column prop="title" label="事项标题" width="150"> </el-table-column>
      <el-table-column prop="submitTags" label="预约类别标签" width="220">
          <div class="tag-group" slot-scope="scope">
            <el-tag :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in scope.row.submitTags.split(',')" :key="index">{{item}}</el-tag>
          </div>
      </el-table-column>
      <el-table-column prop="addr" label="活动地点" width="280"> </el-table-column>
      <el-table-column prop="state" label="状态"> 
        <template slot-scope="temp">
            {{getState(temp.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="开始时间"> </el-table-column>
      <el-table-column prop="endTime" label="截止时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope2">
          <el-button @click="$router.push('/reservation/updatePlan?planId='+scope2.row.id)" >修改</el-button>
          <el-button @click="manage(scope2.row.id)" >预约情况</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right"
      @current-change="handleCurrentChange"
      background
      layout="total, prev, pager, next"
      :page-size="4"
      :total="totalSize"
    >
    </el-pagination>
    <router-view></router-view>
  </div>
</template>

<script>
import { getUserPlan } from "@/api/reservation";
export default {
 name : 'ReservationPlan',
 data () {
     return {
         plans: [],
         totalSize: 0,
     }
 },mounted () {
    this.get();
 },methods: {
    async get() {
      let res = await getUserPlan({
        pageNo: 1,
        pageSize: 4,
      });
      this.totalSize = res.totalSize;
      this.plans = res.data;
    },async handleCurrentChange(val) {
      let res = await getUserPlan({
        pageNo: val,
        pageSize: 4,
      });
      this.totalSize = res.totalSize;
      this.plans = res.data;
    },manage(id){
      this.$router.push('/reservation/planManage?planId='+id)
    },getState(plan){
    if(plan.state == '00'){
         if (new Date().getTime() < new Date(plan.startTime).getTime() ) {
                return '等待发布';
            }
        return '已发布'
      }else if(plan.state == '01'){
        return '已暂停预约'
      }else{
        return '禁用中'
      }
    }
 }
}
</script>

<style>

</style>