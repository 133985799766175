import requests  from "../request";


export const list = () => {
    return requests({
         url:'/api/admin/notify/list', method:'get'
     })
 }

 export const deleteBatch = (ids) => {
    return requests({
         url:'/api/admin/notify/deleteBatch', method:'post',params:ids
     })
 }
 export const getNotifyDetail = (id) => {
    return requests({
         url:'/api/admin/notify/getNotifyDetail', method:'post',params:id
     })
 }
 export const sendNotify = (vo) => {
    return requests({
         url:'/api/admin/notify/sendNotify', method:'post',data:vo
     })
 }
 export const getUserList = () => {
    return requests({
         url:'/api/admin/notify/getUserList', method:'get'
     })
 }