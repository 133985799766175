<template>



</template>

<script>
export default {
  name: "HomeLeft",
};
</script>

<style>

</style>