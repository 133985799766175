import requests  from "../request";


export const getOrderState = () => {
    return requests({
         url:'/api/admin/dataView/getOrderState', method:'get'
     })
 }

export const getProgressBar = () => {
    return requests({
         url:'/api/admin/dataView/getProgressBar', method:'get'
     })
 }