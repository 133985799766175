import requests  from "../request";


export const listPayOrder = () => {
    return requests({
         url:'/api/admin/payOrder/list', method:'post'
     })
 }
export const cancelOrder = (vo) => {
    return requests({
         url:'/api/admin/payOrder/cancel', method:'post',params:vo
     })
 }

 export const deleteOrder = (vo) => {
    return requests({
         url:'/api/admin/payOrder/delete', method:'post',params:vo
     })
 }

 export const listRefundOrder = () => {
    return requests({
         url:'/api/admin/payOrder/listRefundOrder', method:'post'
     })
 }