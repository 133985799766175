<template>
    <div>
        <h3>用户信息一览表</h3>
        <el-table :height="600" :data="tableData.filter(data => !search || 
            data.userId.toLowerCase().includes(search.toLowerCase()) || data.nickname.includes(search) || data.username.includes(search) 
            || data.createTime.includes(search) || (data.name && data.name.includes(search)))
            " style="width: 100%;font-size:13px;">
            <el-table-column label="UID" prop="userId" show-overflow-tooltip >
            </el-table-column>
            <el-table-column label="账号"  prop="username" align="center">
            </el-table-column>
            <el-table-column label="呢称"  prop="nickname" align="center" width="150">
                <template slot-scope="temp">
                    <span style="cursor:pointer;color:#000000"  @click="$userInfoDialog(temp.row.userId);">{{temp.row.nickname}}</span>
                </template>
            </el-table-column>
            <el-table-column label="姓名" prop="name">
            </el-table-column>
            <el-table-column label="手机号" prop="tel">
            </el-table-column>
            <el-table-column label="邮箱" prop="email">
            </el-table-column>
            <el-table-column label="简介" prop="brief">
            </el-table-column>
            <el-table-column label="状态" prop="state">
                <template slot-scope="temp">
                    <span v-if="temp.row.state == 1">正常</span>
                    <span style="color:red" v-else-if="temp.row.state == 0">禁用中</span>
                </template>
            </el-table-column>
            <el-table-column label="余额（分）" prop="money">
            </el-table-column>
            <el-table-column label="注册时间" prop="createTime">
            </el-table-column>
            <el-table-column label="最近登录" prop="createTime">
            </el-table-column>
            <el-table-column align="right">
                <template slot="header" slot-scope="searchUser">
                    <el-input v-model="search" size="mini" :id="searchUser" placeholder="搜索用户" />
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit( scope.row)">编辑</el-button>
                    <el-button v-if="scope.row.state == 1" size="mini" @click="banUser(scope.row)" type="danger">禁用</el-button>
                    <el-button v-else size="mini" @click="banUser(scope.row)" type="warning">解禁</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 支持修改：电话、性别、简介、姓名, 如果user_id为空，则表示修改当前用户 -->
        <el-dialog title="修改信息" :visible.sync="dialog">
            <el-form :model="curUser">
                <el-form-item label="呢称" :label-width="formLabelWidth">
                    <el-input v-model="curUser.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="curUser.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="简介" :label-width="formLabelWidth">
                    <el-input v-model="curUser.brief" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input v-model="curUser.tel" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { list,update,ban } from "@/api/admin/user.js";
export default {
    name: "UserManage",
    data() {
        return {
            tableData: [],
            search: "",
            curUser:{
                nickname :'',name:'',brief:'',tel:'',userId:''
            },
            formLabelWidth: '120px',
            dialog: false
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        handleEdit( row) {
            console.log( row);
            this.curUser.nickname = row.nickname
            this.curUser.name = row.name
            this.curUser.brief = row.brief
            this.curUser.tel = row.tel
            this.curUser.userId = row.userId
            this.dialog = true
        },
        handleDelete(index, row) {
            console.log(index, row);
        },
        async init() {
            let res = await list();
            if (res.resultCode == 200) {
                this.tableData = res.data;
            } else {
                this.$message.error(res.resultMsg);
            }
        },async submitUpdate(){
            if(!this.curUser.nickname){
                this.$message.warning('呢称不能为空');
                return
            }
            let res = await update(this.curUser);
            if (res.resultCode == 200) {
                this.$message.success('修改成功');
                this.init();
                this.dialog = false
            } else {
                this.$message.error(res.resultMsg);
            }
        },banUser(user){
            this.$confirm(user.state == 1 ? '确定要禁用该用户吗？禁用后该用户将不能登录。' : '确定解禁吗', '重要提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                 let res = await ban({'userId':user.userId});
                  if (res.resultCode == 200) {
                    this.$message.success('操作成功！');
                    this.init();
                  } else {
                    this.$message.error('操作失败');
                  }
            }).catch(() => {});
        }
    },
};
</script>

<style>
</style>