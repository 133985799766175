import requests  from "../request";

 export const add = (topic) => {
    return requests({
        url: "/api/topic/add",method: "post", data:topic
    });
 }

 export const list = (vo) => {
    return requests({
        url: "/api/topic/list",method: "post", data:vo
    });
 }
 
 export const detail = (topicId) => {
    return requests({
        url: "/api/topic/detail?topicId="+topicId,method: "get"
    });
 }

 export const addComment = (comment) => {
    return requests({
        url: "/api/topic/addComment",method: "post", data:comment
    });
 }

 export const listComment = (vo) => {
    return requests({
        url: "/api/topic/listComment",method: "post", data:vo
    });
 }

 export const deleteTopic = (vo) => {
    return requests({
        url: "/api/topic/delete",method: "post", params:vo
    });
 }

 export const deleteComment = (vo) => {
    return requests({
        url: "/api/topic/deleteComment",method: "post", params:vo
    });
 }
 
 export const topicLike = (id) => {
    return requests({
        url: "/api/topic/like",method: "post", params:id
    });
 }

 export const commentLike = (id) => {
    return requests({
        url: "/api/topic/likeComment",method: "post", params:id
    });
 }

 export const detailComment = (id) => {
    return requests({
        url: "/api/topic/detailComment",method: "post",params:id
    });
 }