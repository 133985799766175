import requests  from "../request";


export const list = () => {
    return requests({
         url:'/api/admin/topic/list', method:'post'
     })
 }

 export const listComment = (topicId) => {
    return requests({
         url:'/api/admin/topic/listComment', method:'post', params:topicId
     })
 }

 export const delTopic = (vo) => {
    return requests({
         url:'/api/admin/topic/deleteTopic', method:'post', params:vo
     })
 }

 export const delComment = (vo) => {
    return requests({
         url:'/api/admin/topic/deleteComment', method:'post', params:vo
     })
 }