<template>

  <div class="main">
      
    <el-table :data="plans" style="width: 100%" :stripe="true">
      <el-table-column prop="title" label="事项标题" width="210"> </el-table-column>
      <el-table-column prop="addr" label="活动地点"  width="240"> 

      </el-table-column>
      <el-table-column prop="startTime" label="预约开始时间" width="170">
      </el-table-column>
      <el-table-column prop="endTime" label="预约截止时间" width="170"> </el-table-column>
       <el-table-column prop="state" label="状态"> 
        <template slot-scope="temp"  width="40">
            {{getState(temp.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="needMoney" label="模式" > 
          <template  slot-scope="elmbug">
              <span>{{elmbug.row.needMoney ? '【需付费'+handerMoeny(elmbug.row.needMoney)+'元】' : '【免费预约】'}}</span>
          </template>
      </el-table-column>
      <el-table-column label="预约操作" width="350">
        <template slot="header" slot-scope="elmbug" >
            <el-input placeholder="查询事项" clearable :id="elmbug" v-model="search">
                <el-button slot="append" @click="searchPlan" icon="el-icon-search"></el-button>
            </el-input> 
        </template>
        <template slot-scope="scope">
          <el-button v-if="scope.row.qrCodeSign == '1'" @click="open(scope.row)">扫码</el-button>
          <el-button @click="$router.push('/adminHome/updatePlanAdmin?planId='+scope.row.id)">管理</el-button>
          <el-button @click="showDetail(scope.row)">详情</el-button>
          <el-button v-if="scope.row.state == '00'" @click="banPlan(scope.row)">禁用</el-button>
          <el-button v-if="scope.row.state == '02'" type="primary" @click="banPlan(scope.row)">解禁</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right"
      @current-change="handleCurrentChange"
      background
      layout="total, prev, pager, next"
      :page-size="10"
      :total="totalSize"
    >
    </el-pagination>
    <el-dialog  title="扫码预约" :visible.sync="dialogVisible" :width="$_isMobile() ? '96%' : '45%'">
      <div class="row justify-content-center">
              <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div>
      </div>
      <span slot="footer" class="row justify-content-center">
              <br>
              <el-divider style="font-size:12px;"><i class="el-icon-mobile-phone"></i>使用手机扫一扫预约</el-divider>
        <el-button type="primary" @click="dialogVisible = false"
          >好的，知道了</el-button
        >
      </span>
    </el-dialog>

    <br><br>
    <hr>
     <el-divider style="font-size:12px;">地图定位信息一览</el-divider>
    <baidu-map class="map" :max-zoom="21" @click="setClick($event)" :scroll-wheel-zoom="true" center="厦门市">
      <bm-map-type
        :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
        anchor="BMAP_ANCHOR_TOP_LEFT"
      ></bm-map-type>
      <bm-city-list anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-city-list>
      <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :showAddressBar="true"
        :autoLocation="true"
      ></bm-geolocation>
    </baidu-map>

      <el-dialog title="预约事项详情" :visible.sync="detailDialog"  :width="$_isMobile() ? '99%' : '80%'">
            <div class="el-row">
                主题：<a :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">{{plan.title}} </a>
                 <i style="float:right;color:#409EFF;cursor:pointer" :class="[isFavorite ?'el-icon-star-on':'el-icon-star-off']"  @click="userFavorite(plan.id)">收藏</i>

            </div><br>
            <el-divider content-position="center"><span style="font-size:12px;">预约事项说明</span></el-divider>
            <div class="block">
                <div class="el-row">
                       <span style="font-size:12px;">活动地点：<strong>{{plan.addr}}</strong></span><el-divider direction="vertical"></el-divider>
                    <span style="font-size:12px;">预约时间：<strong>{{plan.startTime}} 至 {{plan.endTime}}</strong></span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;"> 限制人数：{{plan.maxAmount}}</span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;"> 模式:{{plan.needMoney ? '【需付费'+handerMoeny(plan.needMoney)+'元】' : '免费预约'}}</span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;">{{plan.signLocation == '1' ? '【需要定位】' : ''}}</span>
                </div><br>
                  <el-collapse v-model="activeNames">
                      <el-collapse-item title="查看详细说明" name="planDetail">
                          <div class="mb-3" v-html="plan.detail">
                          </div>
                          <div class="tag-group" v-if="plan.submitTags">
                              <el-tag style="margin-right:7px" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in plan.submitTags.split(',')" :key="index">{{item}}</el-tag>
                          </div>
                      </el-collapse-item>
                      <el-collapse-item title="预约时需填信息" name="planTags">
                          <div class="mb-3" v-html="plan.tags">
                          </div>
                      </el-collapse-item>
                  </el-collapse>
                <div>
                  <br>发布者：<el-link :underline="false" type="primary" @click="$userInfoDialog(plan.createUser);">{{plan.createUserNickname}}</el-link> <el-divider direction="vertical"></el-divider>
                  预约链接：<a  :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">https://reservation.cstweb.top/#/reservation/detail?id={{plan.id}}</a>
                </div>
                <br>
            <span slot="footer" class="row justify-content-center">
                    <a class="btn btn-success" :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">立即预约</a>
           </span>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import { favorite,check  } from "@/api/favorite";
import { listPlan,ban} from "@/api/admin/reservationPlan";
import {Message} from 'element-ui'
import QRCode from "qrcodejs2";
import { regFenToYuan } from "@/util";
export default {
  name: "PlanAdminManage",
  data() {
    return {
      plans: [],
      activeNames: ['planDetail','planTags'],
      plan:'',
      totalSize: 0,
      dialogVisible: false,
      QRurl: "",
      detailDialog: false,
      isFavorite: false,
      search: '',
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      let res = await listPlan({
        pageNo: 1,
        pageSize: 10,
        content:'',
      });
      this.totalSize = res.totalSize;
      this.plans = res.data;
    },
    async handleCurrentChange(val) {
      let res = await listPlan({
        pageNo: val,
        pageSize: 10,
        content:'',
      });
      this.totalSize = res.totalSize;
      this.plans = res.data;
    },
    async searchPlan() {
      let res = await listPlan({
        pageNo: 1,
        pageSize: 10,
        content:this.search,
      });
      this.totalSize = res.totalSize;
      this.plans = res.data;
    },
    open(row) {
      this.QRurl = "https://reservation.cstweb.top/#/reservation/detail?id=" + row.id;
      console.log("QRurl = ", this.QRurl);
      this.dialogVisible = true;
      this.creatQrCode();
    },
    creatQrCode() {
      this.$nextTick(() => {
        document.getElementById("qrCodeUrl").innerHTML = "";
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.QRurl, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },setClick(e){
      console.log(e);
          var myGeo = new BMap.Geocoder();
            // 根据坐标得到地址描述
            myGeo.getLocation(
              new BMap.Point(e.point.lng, e.point.lat),
               (rs) => { Message.success(rs.address); }
            );
    },showDetail(row){
      this.plan = row
      this.chechFavorite(this.plan.id)
      this.detailDialog = true
    },handerMoeny(fen) {
            if (fen <= 0) return 0;
            return parseFloat(regFenToYuan(fen));
    },async chechFavorite(id) {
          let res = await check({ targetId: id, type: "reservation" });
          if (res.resultCode == 200) {
              this.isFavorite = res.data;
          }
    },
    async userFavorite(id) {
        let res = await favorite({ targetId: id, type: "reservation",remark:'预约收藏'});
        if (res.resultCode == 200) {
            this.isFavorite = res.data;
            if(res.data ){
                this.$message.success("收藏成功");
            }else{
                this.$message.warning("已取消收藏");
            }
        } else {
            this.$message.error("收藏失败");
        }
    },banPlan(vo){
        this.$prompt((vo.state == '00' ? '确定要禁用该预约吗？禁用后系统将停止此事项的后续预约操作。' : '确定解禁吗') +'当前事项【'+vo.title+'】', '输入原因', {
          confirmButtonText: '提交并通知发布者',
          cancelButtonText: '算了',
          type:'warning'
        }).then(async ({ value }) => {
            if(!value){
                this.$message.warning('原因不能为空！')
                return
            }
            let res = await ban({'id':vo.id , 'reason':value})
            if(res.resultCode == 200){
                 this.$message.success('操作成功');
                 this.get()
            }else{
                 this.$message.warning(res.resultMsg);
            }
        }).catch(() => {});
    },getState(plan){
      if(plan.state == '00'){
         if (new Date().getTime() < new Date(plan.startTime).getTime() ) {
                return '等待发布';
            }
        return '已发布'
      }else if(plan.state == '01'){
        return '已暂停预约'
      }else{
        return '禁用中'
      }
    }
  },
};
</script>

<style scoped>
.main {
    width: 97%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.qrcode {
  display: inline-block;
  width: 230px;
  height: 230px;
  background-color: #fff;
}
.map {
  width: 100%;
  height: 400px;
}
a:hover{
    text-decoration: none;
}
</style>