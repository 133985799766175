import { render, staticRenderFns } from "./UserFavorite.vue?vue&type=template&id=65baa40c&scoped=true&"
import script from "./UserFavorite.vue?vue&type=script&lang=js&"
export * from "./UserFavorite.vue?vue&type=script&lang=js&"
import style0 from "./UserFavorite.vue?vue&type=style&index=0&id=65baa40c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65baa40c",
  null
  
)

export default component.exports