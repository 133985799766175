import requests  from "../request";


export const pay = (vo) => {
    return requests({
         url:'/api/pay/createOrder', method:'post',data:vo
     })
 }
 
 export const checkOrder = (vo) => {
    return requests({
         url:'/api/pay/checkOrder', method:'post',params:vo
     })
 }
 
 export const listPayOrder = () => {
    return requests({
         url:'/api/pay/listPayOrder', method:'post'
     })
 }
 
 export const cancelOrder = (vo) => {
    return requests({
         url:'/api/pay/cancelOrder', method:'post',params:vo
     })
 }
 export const createReservationOrder = (vo) => {
    return requests({
         url:'/api/pay/reservation/createOrder', method:'post',data:vo
     })
 }
 