import requests  from "../request";


export const auth = (id) => {
   return requests({
        url:'/api/reservation/getInfo', method:'get',params:id
    })
}

export const list = (vo) => {
    return requests({
        url: "/api/reservation/list",method: "post", data:vo
    });
 }

 export const addInfo = (vo) => {
    return requests({
        url: "/api/reservation/add",method: "post", data:vo
    });
 }

 export const getInfo = (id) => {
    return requests({
        url: "/api/reservation/get?id="+id,method: "get"
    });
 }
 export const getDetail = (id) => {
    return requests({
        url: "/api/reservation/getDetail?id="+id,method: "get"
    });
 }
 export const updatePlan = (vo) => {
    return requests({
        url: "/api/reservation/updatePlan",method: "post",data:vo
    });
 }
 export const sign = (vo) => {
    return requests({
        url: "/api/reservation/sign",method: "post",data:vo
    });
 }

 export const getOrder = () => {
    return requests({
        url: "/api/reservation/listOrder",method: "get"
    });
 }

 export const inAuth = (vo) => {
    return requests({
        url: "/api/reservation/inAuth",method: "post",params:vo
    });
 }
 export const updateOrder = (vo) => {
    return requests({
        url: "/api/reservation/updateOrder",method: "post",data:vo
    });
 }

 export const orderAudit = (vo) => {
    return requests({
        url: "/api/reservation/audit",method: "post",data:vo
    });
 }

 export const rate = (vo) => {
    return requests({
        url: "/api/reservation/rate",method: "post",params:vo
    });
 }
 export const getUserPlan = (vo) => {
    return requests({
        url: "/api/reservation/getUserPlan",method: "post",data:vo
    });
 }

 export const getOrderByPlanId = (vo) => {
    return requests({
        url: "/api/reservation/listOrderByPlanId",method: "post",data:vo
    });
 }

 export const getOrderStep = (vo) => {
    return requests({
        url: "/api/reservation/getOrderStep", method: "post", params:vo
    });
 }

 