<template>
    <div class="row">
        <div class="col-xl-4">
            <!-- Profile picture card-->
            <div class="card mb-4 mb-xl-0">
                <div class="card-header">我的名片</div>
                <div class="card-body text-center">
                    <!-- Profile picture image-->
                    <img class="img-account-profile rounded-circle mb-2" :src="user.avatarAddr" width="90px" height="90px" alt="头像"  />
                    <!-- Profile picture help block-->
                    <div class="small font-italic text-muted mb-4">
                        {{ user.nickname }}
                    </div>

                    <div class="small font-italic text-muted mb-4">
                        {{ user.brief }}
                    </div>
                    <el-upload class="upload-demo" action="/api/user/uploadAvatar" accept="image/*" :limit="1" :show-file-list="false" :on-success="successHander" :on-error="errorHander" :headers="{ 'cst-token': token }">
                        <el-button type="primary" round>更换头像</el-button>
                    </el-upload><br>
                    <div class="tag-group"> 
                        <el-tag size="small" @click="$router.push('/reservation/userReward')" type="success" style="margin-right:8px;cursor:pointer;">积分 {{userLevel.rewardNum}}</el-tag>
                        <el-tag size="small" v-if="userLevel.level" type="primary" style="margin-right:8px;">{{userLevel.level.role}}</el-tag>
                        <el-tag size="small" v-if="userLevel.level" type="warning">等级 {{userLevel.level.grade}}</el-tag>
                    </div>
                    <!-- <input type="file" name="image" @change="uploadSectionFile"> -->
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <!-- Account details card-->
            <div class="card mb-4">
                <div class="card-header">个人信息</div>
                <div class="card-body">
                    <!-- Form Group (username)-->
                    <div class="mb-3">
                        <label class="small mb-1" for="inputUsername">账号</label>
                        <input class="form-control" id="inputUsername" readonly="readonly" type="text" placeholder="账号" :value="user.username" />
                    </div>
                    <!-- Form Row-->

                    <div class="row gx-3 mb-3">
                        <!-- Form Group (first name)-->
                        <div class="col-md-6">
                            <label class="small mb-1" for="inputFirstName">昵称</label>
                            <input class="form-control" id="inputFirstName" type="text" v-model="user.nickname" placeholder="输入昵称" />
                        </div>
                        <!-- Form Group (last name)-->
                        <div class="col-md-6">
                            <label class="small mb-1" for="inputLastName">真实姓名</label>
                            <input class="form-control" id="inputLastName" v-model="user.name" type="text" placeholder="输入真实姓名" />
                        </div>
                    </div>
                    <!-- Form Row        -->
                    <div class="row gx-3 mb-3">
                        <!-- Form Group (organization name)-->
                        <div class="col-md-6">
                            <label class="small mb-1" for="inputOrgName">个人简介</label>
                            <input class="form-control" id="inputOrgName" v-model="user.brief" type="text" placeholder="简单介绍一下自己吧！" />
                        </div>
                        <!-- Form Group (location)-->
                        <div class="col-md-6">
                            <label class="small mb-1" for="inputLocation">账号状态</label>
                            <label class="form-control">{{
                      user.state ? "正常" : "异常"
                    }}</label>
                        </div>
                    </div>
                    <!-- Form Group (email address)-->
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <label class="input-group-text" for="inputGroupSelect01">邮箱</label>
                        </div>
                        <input type="text" class="form-control" readonly v-model="user.email" :placeholder="user.eamil || '暂未绑定'" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" @click="showModal">
                                {{user.email ? '邮箱解绑' : '邮箱绑定'}}
                            </button>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" ref="emailModal" id="emailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">
                                            {{user.email ? '邮箱解绑' : '邮箱绑定'}}
                                        </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="input-group mb-3">
                                            <!-- v-if="!user.eamil" -->
                                            <div class="input-group-prepend">
                                                <label class="input-group-text" for="inputGroupSelect01">邮箱</label>
                                            </div>
                                            <input type="email" class="form-control" placeholder="请输入正确的邮箱" :readonly="isReadonly" id="inputEmail" aria-label="请输入正确的邮箱" aria-describedby="button-addon2">
                                        </div>
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control" v-model.trim="code" placeholder="请输入验证码" aria-label="请输入验证码" aria-describedby="button-addon2">
                                            <div class="input-group-append">
                                                <button class="btn btn-success" type="button" :disabled="isDisable" @click="send" id="button-addon2">{{sendBtnText}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                            取消
                                        </button>
                                        <button type="button" @click="bind" class="btn btn-primary">
                                            提交
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Form Row-->
                    <div class="row gx-3 mb-3">
                        <!-- Form Group (phone number)-->
                        <div class="col-md-6">
                            <label class="small mb-1" for="inputPhone">手机号</label>
                            <input class="form-control" id="inputPhone" type="tel" v-model="user.tel" placeholder="Enter your phone number" />
                        </div>
                        <!-- Form Group (birthday)-->
                        <div class="col-md-6">
                            <label class="small mb-1" for="inputBirthday">注册时间</label>
                            <input class="form-control" type="text" readonly="readonly" :value="user.createTime" />
                        </div>
                    </div>
                    <!-- Save changes button-->
                    <button class="btn btn-primary" @click="updateUserInfo" type="button">
                        保存
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sendEmail } from "@/api/userApi";
import { bindEmail,getUserLevel } from "@/api/userApi";
export default {
    name: "UserProfile",
    data() {
        return {
            nickname: "",
            tel: "",
            brief: "",
            name: "",
            code: "",
            isDisable: false,
            isReadonly: false,
            sendBtnText: "发送验证码",
            userLevel : ''
        };
    },
    computed: {
        user() {
            return this.$store.state.user.userInfo;
        },
        token() {
            return localStorage.getItem("cst-token");
        }
    },mounted () {
      this.init()  
    },
    methods: {
        async init(){
           let res = await getUserLevel()
           if(res.resultCode == 200){
               this.userLevel = res.data
           }
        },
        updateUserInfo() {
            if(!this.user.nickname){
                this.$message.warning('呢称不能为空~')
                return
            }
            let userInfo = {
                nickname: this.user.nickname,
                tel: this.user.tel,
                brief: this.user.brief,
                name: this.user.name,
            };
            this.$store.dispatch("updateUserInfo", userInfo);
        },
        successHander(response) {
            console.log("response = ", response.data);
            if (!response.data) {
                this.$message.warning(response.resultMsg);
                return;
            }
            this.$message.success("头像更换成功!");
            this.$store.commit("UPDATE_USER_AVATAR", response.data);
        },
        errorHander(response) {
            console.log("response = ", response);
            this.$message.error("上传失败!");
        },
        uploadSectionFile(image) {
            this.$store.dispatch("uploadUserAvatar", image);
        },
        showModal() {
            console.log("this.user.email,", this.user.email);
            if (this.user.email) {
                $("#inputEmail").val(this.user.email);
                this.isReadonly = true;
            }
            $("#emailModal").modal("show");
        },
        async send() {
            console.log($("#inputEmail").val());
            let emailText = $("#inputEmail").val();
            if (emailText == "") {
                $("#inputEmail").focu();
                return;
            }
            this.sendBtnText = "发送中...";
            this.isDisable = true;
            let result = await sendEmail({
                email: emailText,
                way: this.user.email ? "RemoveBind" : "Bind",
                needBindFlag: "0",
            });
            if (result.resultCode == 200) {
                this.countDown(60);
                this.$message.success("发送成功，请注意接收!");
            } else {
                this.isDisable = false;
                this.sendBtnText = "获取验证码";
                this.$message.error("错误, " + result.resultMsg);
            }
        },
        async bind() {
            let result = await bindEmail({
                code: this.code,
                way: this.user.email ? "RemoveBind" : "Bind",
            });
            if (result.resultCode == 200) {
                this.$message.success("操作成功");
                location.reload();
            } else {
                this.$message.error("操作失败， " + result.resultMsg);
            }
        },
        countDown(time) {
            if (time === 0) {
                this.isDisable = false;
                if (!this.user.email) {
                    this.isReadonly = false;
                }
                this.sendBtnText = "获取验证码";
                return;
            } else {
                this.isDisable = true;
                this.isReadonly = true;
                this.sendBtnText = "重新发送(" + time + ")秒";
                time--;
            }
            setTimeout(() => {
                this.countDown(time);
            }, 1000);
        }
    },
};
</script>

<style>
</style>