import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import user from './user/index.js'
import ws from './ws/index.js'
export default new Vuex.Store({
    modules: {
        user,ws
    }
});
