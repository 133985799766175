<template>
  <div>
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">资产</h1>
      </div>

        <div class="row">
       
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-success text-uppercase
                      mb-1
                    "
                  >
                    账户余额
                  </div>
                  <div  @click="payDialog = true"  style="cursor:pointer;" class="h5 mb-0 font-weight-bold text-gray-800">
                    ¥ {{wallet.moneyYuan}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

       <div style="cursor:pointer" class="col-xl-3 col-md-6 mb-4" @click="$router.push('/reservation/userCoupon')">
          <div class="card border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-primary text-uppercase
                      mb-1
                    "
                  >
                    优惠卷数量
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {{wallet.couponNum}}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="el-icon-s-ticket fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="cursor:pointer" @click="$router.push('/reservation/fileHome')" class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-info text-uppercase
                      mb-1
                    "
                  >
                    已使用文件库容量
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {{getDoubleNum(wallet.usedFileSize , 30)}}%
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                         :style="{width: getDoubleNum(wallet.usedFileSize , 30)+'%'}"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="cursor:pointer" class="col-xl-3 col-md-6 mb-4" @click="$router.push('/reservation/userReward')">
          <div class="card border-left-warning shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="
                      text-xs
                      font-weight-bold
                      text-warning text-uppercase
                      mb-1
                    "
                  >
                    总积分
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{wallet.rewardNum}}</div>
                </div>
                <div class="col-auto">
                  <i class="el-icon-picture-outline-round fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
              <div style="float:right">
            <el-link type="primary" :underline="false" @click="$router.push('/reservation/payRecord')">交易记录</el-link>
        </div>
        <hr>
            <el-dialog
            title="选择充值金额"
            :visible.sync="payDialog"
             :width="$_isMobile() ? '95%' : '60%'">
             <div style="text-align:center;">
                  <el-radio-group v-model="payMoney">
                    <el-radio-button label="0.01">¥ <span style="font-size:24px;">0.01</span> <span style="font-size:4px;color:#67C23A">体验价</span></el-radio-button>
                    <el-radio-button label="0.9">¥ <span style="font-size:24px;">1  </span> <span style="font-size:4px;color:orange;">享9折</span></el-radio-button>
                    <el-radio-button label="6.66">¥ <span style="font-size:24px;">6.66</span></el-radio-button>
                    <el-radio-button label="9">¥ <span style="font-size:24px;">10</span> <span style="font-size:4px;color:orange;">立减1元</span></el-radio-button>
                    <el-radio-button label="18.88">¥ <span style="font-size:24px;">18.88</span></el-radio-button>
                    <el-radio-button label="50">¥ <span style="font-size:24px;">50</span></el-radio-button>
                    <el-radio-button label="85">¥ <span style="font-size:24px;">100 <span style="font-size:4px;color:orange;">优惠15元</span></span></el-radio-button>
                </el-radio-group>
            </div>
            <span slot="footer" class="dialog-footer">
                <div style="float:left;">
                    <el-button @click="pay" plain size="mini" round>自定义金额</el-button>  <span style="font-size:7px"> 备注：当前用户充值有机会享受随机立减</span>
                </div>
                <el-button @click="payDialog = false">取 消</el-button>
                <el-button type="primary" @click="payBySelect">立即支付  {{payMoney ? payMoney+'元' : ''}}</el-button>
            </span>
            </el-dialog>
  </div>
</template>

<script>
import { getMyWallet } from "@/api/userApi";
import { pay,checkOrder,listPayOrder } from "@/api/pay";
export default {
  name : 'UserWallet',
  data () {
      return {
          wallet : '',
         payDialog: false,
         payMoney: '',
      }
  },mounted () {
      this.getWallet()
  },methods: {
      async getWallet(){
          let res = await getMyWallet();
          this.wallet = res.data
      },pay(){
        this.payMoney = ''
         this.$prompt('请输入金额', '充值', {
          confirmButtonText: '立即支付',
          cancelButtonText: '取消',
          inputPattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
          inputErrorMessage: '金额格式不正确'
        }).then(({ value }) => {
           this.createPay(value)
        }).catch(() => {
        });
     },payBySelect(){
         if(!this.payMoney){
             this.$message.warning('请选择金额')
             return
         }
         this.createPay(this.payMoney)
     },async createPay(moneyVal){
          this.payDialog = false
         let discountPrice = ''
         if(moneyVal == '9'){
             discountPrice = '1'
         }else if(moneyVal == '85'){
             discountPrice = '15'
         }else if(moneyVal == '0.9'){
             discountPrice = '0.1'
         }
        const loading = this.$loading({lock: true,text: '正在跳转至支付页面，请稍等....',spinner: 'el-icon-loading',});
        let res = await pay({price: moneyVal,title:'账户钱包充值',content:'预约平台线上支付', type:'1',param:'', discountMoney: discountPrice,
        returnUrl:'https://reservation.cstweb.top/#/reservation/payResult'})
        // returnUrl:'http://localhost:8080/#/reservation/payResult'})
        if(res.resultCode == 200 && res.data){
            window.location.href = res.data;
        }else{
            this.$message.error(res.resultMsg)
        }
        loading.close();
     }, getDoubleNum(a,b){
          let res = a / b * 100;
          return res % 10== 0 ? res : res.toFixed(2)
        }
  }
}
</script>

<style scoped>
a:hover {
    text-decoration: none;
}
</style>