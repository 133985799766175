<template>
       <el-table height="600" :data="feedbacks.filter(data => !search || data.content.includes(search) || data.type.includes(search) || data.createTime.includes(search) || data.nickname.toString().includes(search))  " style="width: 100%" :stripe="true">
            <el-table-column prop="content" label="反馈内容"> </el-table-column>
            <el-table-column prop="type" label="类型"> </el-table-column>
            <el-table-column prop="contactWay" label="联系方式"> </el-table-column>
            <el-table-column prop="targetId" label="目标ID" show-overflow-tooltip width="90"></el-table-column>
            <el-table-column prop="createIp" label="IP地址"></el-table-column>
            <el-table-column prop="state" label="状态">
                <template slot-scope="temp">{{temp.row.state == '00' ? '待处理' : '已处理'}}</template>
            </el-table-column>
            <el-table-column prop="replyContent" label="回复内容"> </el-table-column>
            <el-table-column prop="nickname" label="用户">
                <template slot-scope="temp">
                    <el-link v-if="temp.row.createUser" type="primary" @click="$userInfoDialog(temp.row.createUser);">{{ temp.row.nickname }}</el-link>
                    <el-link v-else type="info">匿名用户</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="createTime">
                <template slot-scope="time">{{time.row.createTime}}</template>
                <template slot="header" slot-scope="searchFeedback">
                    <el-input v-model="search" size="mini" :id="searchFeedback" placeholder="搜索" />
                </template>
                <template slot-scope="scope">
                    <el-button v-if="scope.row.state == '00' && scope.row.createUser" size="mini" @click="dealFeedback(scope.row.id)" type="primary">回复</el-button>
                    <el-button v-if="scope.row.state == '01' && scope.row.createUser" size="mini" @click="dealFeedback(scope.row.id)" type="success">再次回复</el-button>
                    <el-button size="mini" @click="delFeedback(scope.row.id)" type="warning">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
</template>

<script>
import {list,deal,del} from '@/api/admin/feedback'
export default {
    name : 'FeedbackManage',
    data () {
        return {
            feedbacks:[],
            search:''
        }
    },mounted () {
        this.init()
    },methods: {
        async init(){
          let res = await list()
          if(res.resultCode == 200){
              this.feedbacks = res.data
          }
        },async dealFeedback(id){
            this.$prompt('请输入处理结果或回复内容', '提示', {
                confirmButtonText: '提交并通知反馈者',
                cancelButtonText: '取消',
            }).then(async({ value }) => {
                if(!value){
                     this.$message.warning('输入的内容不能为空')
                     return
                }
                let res = await deal({'id':id,'remark':value})
                if(res.resultCode == 200){
                    this.$message.success('回复成功！')
                    this.init()
                }else{
                    this.$message.error('回复失败！')
                }
            }).catch(() => { });
        }, delFeedback(id){
            this.$confirm('此操作将永久删除该反馈, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async () => {
                let res = await del({'id':id})
                if(res.resultCode == 200){
                    this.$message.success('删除反馈成功！');
                    this.init()
                }else{
                    this.$message.error('删除失败')
                }
            }).catch(() => {
            });
        }

    }
}
</script>

<style>

</style>