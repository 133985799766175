// export default {
//     namespaced: true,
//     state () {
//     },
//     // 定义mutations，用于同步修改状态
//     mutations: {
//         saveWebSocket(state,websocket){
//             state["websocket"] = websocket
//         },
//     },
//     // 定义actions，用于异步修改状态
//     actions: {},
//     // 定义一个getters
//     getters: {}
// }
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wsData:{},
  },
  mutations: {
    setWebSocket(state,data){
      state.wsData = data;
    }
  },
  actions: {
  },
  modules: {
  }
})