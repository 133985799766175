<template>
    <div class="main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部订单" name="all">
                <span slot="label"><i class="el-icon-date"></i>全部订单</span>
                <el-card class="box-card" v-for="vo in order" :key="vo.info.id" shadow="hover" style="margin-bottom:19px;">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-date"></i> {{vo.plan.title}}</span>
                        <el-button @click="setInfo(vo.plan,vo.info,vo.steps)" style="float: right; padding: 3px 0" type="text">查看详细</el-button>
                    </div>
                    <div class="text item" style="font-size:13px;">
                        活动地点：{{vo.plan.addr}}
                        <el-divider></el-divider>
                        <div class="el-row">
                            <div style="float:left">
                                订单状态（<strong>{{!due(vo.info.targetEndTime) || vo.info.state == '02' ? orderState(vo.info.state) : '已结束'}}</strong>）
                                <el-divider direction="vertical"></el-divider>
                                预约时间: {{vo.info.targetTime }} 至 {{vo.info.targetEndTime }}
                                <el-divider direction="vertical"></el-divider>
                                <template v-if="vo.plan.signCode">
                                    签到码: <strong>{{vo.plan.signCode}}</strong>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                            </div>
                            <div style="float:right">
                                <template v-if="!due(vo.info.targetEndTime)">
                                    <el-button v-if="vo.info.state == '00'" @click="signStart(vo.plan,vo.info.id)" size="small" type="success">签到</el-button>
                                    <el-button v-if="vo.info.state == '05'" @click="signOver(vo.plan,vo.info.id)" size="small" type="primary">我要签退</el-button>
                                    <el-button v-if="vo.info.state == '01' && vo.plan.needMoney >= 0" size="small" @click="payStart(vo.info,vo.plan.needMoney)" type="primary">支付 {{handerMoeny(vo.plan.needMoney)}} 元</el-button>
                                    <el-button v-if="vo.info.state == '01' || vo.info.state == '00' || vo.info.state == '05' || vo.info.state == '10'" @click="cancelOrder(vo.plan.id,vo.info.id)" size="small">取消预约</el-button>
                                    <el-button v-if="vo.info.state == '03'" type="info" size="small">已取消预约</el-button>
                                </template>
                                <el-button v-if="due(vo.info.targetEndTime) && vo.info.state != '02'" type="info" size="small">已结束</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane name="doing">
                <span slot="label"><i class="el-icon-odometer"></i>进行中</span>
                <el-card class="box-card" v-for="vo in order" :key="vo.info.id" shadow="hover" style="margin-bottom:19px;">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-date"></i> {{vo.plan.title}}</span>
                        <el-button @click="setInfo(vo.plan,vo.info,vo.steps)" style="float: right; padding: 3px 0" type="text">查看详细</el-button>
                    </div>
                    <div class="text item" style="font-size:13px;">
                        活动地点：{{vo.plan.addr}}
                        <el-divider></el-divider>
                        <div class="el-row">
                            <div style="float:left">
                                订单状态（<strong>{{!due(vo.info.targetEndTime) || vo.info.state == '02' ? orderState(vo.info.state) : '已结束'}}</strong>）
                                <el-divider direction="vertical"></el-divider>
                                预约时间: {{vo.info.targetTime }} 至 {{vo.info.targetEndTime }}
                                <el-divider direction="vertical"></el-divider>
                                <template v-if="vo.plan.signCode">
                                    签到码: <strong>{{vo.plan.signCode}}</strong>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                            </div>
                            <div style="float:right">
                                <template v-if="!due(vo.info.targetEndTime)">
                                    <el-button v-if="vo.info.state == '00'" @click="signStart(vo.plan,vo.info.id)" size="small" type="success">签到</el-button>
                                    <el-button v-if="vo.info.state == '05'" @click="signOver(vo.plan,vo.info.id)" size="small" type="primary">我要签退</el-button>
                                    <el-button v-if="vo.info.state == '01' && vo.plan.needMoney >= 0" size="small" @click="payStart(vo.info,vo.plan.needMoney)" type="primary">支付 {{handerMoeny(vo.plan.needMoney)}} 元</el-button>
                                    <el-button v-if="vo.info.state == '01' || vo.info.state == '00' || vo.info.state == '05' || vo.info.state == '10'" @click="cancelOrder(vo.plan.id,vo.info.id)" size="small">取消预约</el-button>
                                    <el-button v-if="vo.info.state == '03'" type="info" size="small">已取消预约</el-button>
                                </template>
                                <el-button v-if="due(vo.info.targetEndTime) && vo.info.state != '02'" type="info" size="small">已结束</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane name="done">
                <span slot="label"><i class="el-icon-circle-check"></i>已完成</span>
                <el-card class="box-card" v-for="vo in order" :key="vo.info.id" shadow="hover" style="margin-bottom:19px;">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-date"></i> {{vo.plan.title}}</span>
                        <el-button @click="setInfo(vo.plan,vo.info,vo.steps)" style="float: right; padding: 3px 0" type="text">查看详细</el-button>
                    </div>
                    <div class="text item" style="font-size:13px;">
                        活动地点：{{vo.plan.addr}}
                        <el-divider></el-divider>
                        <div class="el-row">
                            <div style="float:left">
                                订单状态（<strong>{{!due(vo.info.targetEndTime) || vo.info.state == '02' ? orderState(vo.info.state) : '已结束'}}</strong>）
                                <el-divider direction="vertical"></el-divider>
                                预约时间: {{vo.info.targetTime }} 至 {{vo.info.targetEndTime }}
                                <el-divider direction="vertical"></el-divider>
                                <template v-if="vo.plan.signCode">
                                    签到码: <strong>{{vo.plan.signCode}}</strong>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                            </div>
                            <div style="float:right">
                                <el-button v-if="due(vo.info.targetEndTime) && vo.info.state != '02'" type="info" size="small">已结束</el-button>
                                <el-button v-if="vo.info.state == '06'" type="warning" size="small" @click="showRating(vo.info.id)">去评价</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane name="cancel">
                <span slot="label"><i class="el-icon-remove-outline"></i>已取消/无效</span>
                <el-card class="box-card" v-for="vo in order" :key="vo.info.id" shadow="hover" style="margin-bottom:19px;">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-date"></i> {{vo.plan.title}}</span>
                        <el-button @click="setInfo(vo.plan,vo.info,vo.steps)" style="float: right; padding: 3px 0" type="text">查看详细</el-button>
                    </div>
                    <div class="text item" style="font-size:13px;">
                        订单号：{{vo.info.id}}
                        活动地点：{{vo.plan.addr}}
                        <el-divider></el-divider>
                        <div class="el-row">
                            <div style="float:left">
                                订单状态（<strong>{{!due(vo.info.targetEndTime) || vo.info.state == '02' ? orderState(vo.info.state) : '已结束'}}</strong>）
                                <el-divider direction="vertical"></el-divider>
                                预约时间: {{vo.info.targetTime }} 至 {{vo.info.targetEndTime }}
                                <el-divider direction="vertical"></el-divider>
                                <template v-if="vo.plan.signCode">
                                    签到码: <strong>{{vo.plan.signCode}}</strong>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                            </div>
                            <div style="float:right">
                                <el-button v-if="due(vo.info.targetEndTime) && vo.info.state != '02'" type="info" size="small">已结束</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-tab-pane>
            <el-tab-pane name="rate">
                <span slot="label"><i class="el-icon-chat-dot-square"></i>待评价</span>
                <el-card class="box-card" v-for="vo in order" :key="vo.info.id" shadow="hover" style="margin-bottom:19px;">
                    <div slot="header" class="clearfix">
                        <span> <i class="el-icon-date"></i> {{vo.plan.title}}</span>
                        <el-button @click="setInfo(vo.plan,vo.info,vo.steps)" style="float: right; padding: 3px 0" type="text">查看详细</el-button>
                    </div>
                    <div class="text item" style="font-size:13px;">
                        活动地点：{{vo.plan.addr}}
                        <el-divider></el-divider>
                        <div class="el-row">
                            <div style="float:left">
                                订单状态（<strong>{{!due(vo.info.targetEndTime) || vo.info.state == '02' ? orderState(vo.info.state) : '已结束'}}</strong>）
                                <el-divider direction="vertical"></el-divider>
                                预约时间: {{vo.info.targetTime }} 至 {{vo.info.targetEndTime }}
                                <el-divider direction="vertical"></el-divider>
                                <template v-if="vo.plan.signCode">
                                    签到码: <strong>{{vo.plan.signCode}}</strong>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                            </div>
                            <div style="float:right">
                                <el-button v-if="due(vo.info.targetEndTime) && vo.info.state != '02'" type="info" size="small">已结束</el-button>
                                <el-button v-if="vo.info.state == '06'" type="warning" size="small" @click="showRating(vo.info.id)">去评价</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-tab-pane>
        </el-tabs>

        <el-dialog title="用户评价" style="font-size:13px;" :visible.sync="rateDialogVisible" :width="$_isMobile() ? '80%' : '45%'">
            <p>订单号：{{infoId}}</p>
            <div class="el-row">
                流程评分：<el-rate v-model="rateValue1" :colors="rateColor"> </el-rate>
            </div><br>
            <div class="el-row">
                服务评分：<el-rate v-model="rateValue2" :colors="rateColor"> </el-rate>
            </div><br>
            <div class="el-row">
                使用体验评分：<el-rate v-model="rateValue3" :colors="rateColor"> </el-rate>
            </div><br>
            <div class="el-row">
                评论或建议：<el-input type="textarea" v-model="comment"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="rateDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="rating">提交评价</el-button>
            </span>
        </el-dialog>
        <el-dialog title="预约订单详情" :visible.sync="dialogVisible" width="80%">
            <div class="el-row">
                标题：<a :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">{{plan.title}} </a>
            </div><br>
            <div class="el-row">
                订单号：{{info.id}}（<strong>{{!due(info.targetEndTime) || info.state == '02' ? orderState(info.state) : '已结束'}}</strong>） <el-divider direction="vertical"></el-divider> <span v-if="plan.needMoney > 0">订单金额：{{handerMoeny(plan.needMoney)}}元</span>
            </div><br>
            <el-divider content-position="center"><span style="font-size:12px;">预约时间线</span></el-divider>
            <div class="block">
                <el-timeline>
                    <el-timeline-item v-for="(vo, index) in step" :key="index" :icon="vo.remark.split('_')[0]" :type="vo.remark.split('_')[1]" :size="'large'" :timestamp="vo.createTime">
                        {{vo.title }}{{vo.remark.split('_')[2] != '无'? (' ('+vo.remark.split('_')[2]+')') : ''}}
                    </el-timeline-item>
                </el-timeline>
                <hr>
                <div class="el-row">
                    <span style="font-size:12px;">预约时间：{{info.targetTime}} 至 {{info.targetEndTime}}</span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;"> 限制人数：{{plan.maxAmount}}</span>
                    <el-divider direction="vertical"></el-divider>
                </div><br>
                <el-collapse v-model="activeNames">
                    <el-collapse-item title="查看预约说明" name="planDetail">
                        <div class="mb-3" v-html="plan.detail">
                        </div>
                        <div class="tag-group" v-if="plan.submitTags">
                            <el-tag :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in plan.submitTags.split(',')" :key="index">{{item}}</el-tag>
                        </div>
                        <div style="float:right">
                            <el-link :underline="false" type="primary" @click="$userInfoDialog(plan.createUser);">查看发布者</el-link>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <el-collapse v-if="info.content">
                    <el-collapse-item title="查看已填信息" name="userInput">
                        <p v-for="(info,index) in info.content.split(',')" :key="index">{{info.replace('@#@', '：')}} </p>
                    </el-collapse-item>
                    <el-collapse-item v-if="info.fileAddr" title="查看已提交附件" name="userUpload">
                        <a :href="info.fileAddr" target="_blank"><em>下载附件</em></a>
                    </el-collapse-item>
                </el-collapse>
                <div>

                </div>
            </div>
        </el-dialog>

        <el-dialog title="订单付款" :visible.sync="payDialog" :width="$_isMobile() ? '93%' : '50%'">
            <div style="text-align:center">
                <strong>¥ <span style="font-size:45px">{{payMoney}}</span></strong>
            </div>
            <div>
                <el-collapse v-model="activeNames" @change="getUserCoupon">
                    <el-collapse-item title="使用优惠卷" name="1">
                        <el-radio-group v-model="couponId" @change="selectCoupon">
                            <el-popover  v-for="(coupon, index) in coupons" :key="index"  placement="bottom" :title="curMoneyFen <= coupon.amount ? '不可使用' : ''" width="200" trigger="click" 
                                :content="curMoneyFen <= coupon.amount ? '原因：订单实际金额小于优惠卷金额 ' + formatMoney(coupon.amount) : ''">
                                <el-radio :title="'抵扣'+formatMoney(coupon.amount)" style="margin-right:10px;" slot="reference"  :disabled="curMoneyFen <= coupon.amount" :label="coupon.id" border>{{coupon.name}}</el-radio>
                            </el-popover>
                        </el-radio-group>
                        <el-empty :image-size="50" v-if="coupons.length == 0 " description="没有可用的优惠卷"></el-empty>
                    </el-collapse-item>
                </el-collapse>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <hr>
                <span style="float:left;font-size:8px"><i style="color:green" class="el-icon-circle-check"></i> 使用账户钱包支付</span>
                <el-button @click="submitPay" :loading="submiting" type="primary">立即支付 {{formatMoney(this.curMoneyFen - this.couponMoney)}}</el-button>
            </span>
        </el-dialog>
        <input type="hidden" id="addr" />
        <el-empty v-show="!this.order.length" description="这儿没有订单..."></el-empty>
    </div>
</template>

<script>
import { getOrder } from "@/api/reservation";
import { inAuth } from "@/api/reservation";
import { rate } from "@/api/reservation";
import { getCoupon } from "@/api/coupon";
import { createReservationOrder } from "@/api/pay";
import { updateOrder } from "@/api/reservation";
import { regFenToYuan } from "@/util";
export default {
    name: "ReservationOrder",
    data() {
        return {
            activeName: "all",
            isEmpty: false,
            order: "",
            orderCopy: [],
            plan: "",
            info: "",
            infoId: "",
            step: "",
            dialogVisible: false,
            rateDialogVisible: false,
            activeNames: [],
            rateValue1: null,
            rateValue2: null,
            rateValue3: null,
            comment: "",
            rateColor: ["#99A9BF", "#F7BA2A", "#FF9900"],
            iconClasses: [
                "icon-rate-face-1",
                "icon-rate-face-2",
                "icon-rate-face-3",
            ],
            curMoneyFen: 0,
            payMoney: "",
            payDialog: false,
            submiting: false,
            coupons: [],
            activeNames: [],
            couponMoney: 0,
            couponId: "",
        };
    },
    mounted() {
        this.initOrder();
    },
    methods: {
        handleClick(tab, event) {
            if (tab.name == "all") {
                this.order = this.orderCopy;
            } else if (tab.name == "doing") {
                this.order = this.orderCopy.filter(
                    (vo) =>
                        (vo.info.state == "00" ||
                            vo.info.state == "10" ||
                            vo.info.state == "01" ||
                            vo.info.state == "05") &&
                        new Date().getTime() <=
                            new Date(vo.info.targetEndTime).getTime()
                );
            } else if (tab.name == "done") {
                this.order = this.orderCopy.filter(
                    (vo) => vo.info.state == "02" || vo.info.state == "06"
                );
            } else if (tab.name == "cancel") {
                this.order = this.orderCopy.filter(
                    (vo) =>
                        vo.info.state == "03" ||
                        vo.info.state == "04" ||
                        (new Date().getTime() >
                            new Date(vo.info.targetEndTime).getTime() &&
                            vo.info.state != "02" &&
                            vo.info.state != "06")
                );
            } else if (tab.name == "rate") {
                this.order = this.orderCopy.filter(
                    (vo) => vo.info.state == "06"
                );
            }
        },
        async initOrder() {
            let res = await getOrder();
            if (res.resultCode == 200 && res.data) {
                this.isEmpty = false;
                this.order = res.data;
                this.orderCopy = res.data;
            } else {
                this.isEmpty = true;
            }
        },
        orderState(state) {
            if (state == "00") {
                return "进行中，待签到";
            } else if (state == "10") {
                return "审核中";
            } else if (state == "01") {
                return "进行中，待支付";
            } else if (state == "02") {
                return "已完成";
            } else if (state == "06") {
                return "交易成功，待评价";
            } else if (state == "03") {
                return "已取消";
            } else if (state == "04") {
                return "已过期";
            } else if (state == "05") {
                return "进行中，已签到";
            } else {
                return "订单异常";
            }
        },
        handerMoeny(fen) {
            if (fen <= 0) return 0;

            return parseFloat(regFenToYuan(fen));
        },
        due(time) {
            return new Date().getTime() > new Date(time).getTime();
        },
        setInfo(plan, info, step) {
            this.plan = plan;
            this.info = info;
            this.step = step;
            this.dialogVisible = true;
        },
        signStart(plan, infoId) {
            console.log(plan);
            console.log(plan.signLocation);
            if (plan.signLocation == "1") {
                this.$confirm(
                    "此预约签到前需要定位获取您的地址，是否同意?",
                    "定位",
                    {
                        confirmButtonText: "同意",
                        cancelButtonText: "拒绝",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.getCurrentLocation();
                    })
                    .catch(() => {});
            }
            if ($("#addr").val() == "" && plan.signLocation == "1") {
                // this.$message.warning("没有定位获取到您的地址，请检查是否开启了定位权限");
                //不加这个if就直接跳过执行下面了，我也不知道为什么
                return;
            }
            if (!$("#addr").val() && plan.signLocation == "1") {
                this.$message.warning(
                    "没有定位获取到您的地址，请检查是否开启了定位权限"
                );
                return;
            }
            this.$prompt(
                plan.signLocation == "1"
                    ? "当前地址：( " + $("#addr").val() + " )"
                    : "",
                "请输入签到码",
                {
                    confirmButtonText: "立即签到",
                    cancelButtonText: "取消",
                }
            )
                .then(async ({ value }) => {
                    if (!value) {
                        this.$message.warning("签到码输入不能为空！");
                        return;
                    }
                    if (!$("#addr").val() && plan.signLocation == "1") {
                        this.$message.warning("未获取到您的地址，请重试");
                        return;
                    }
                    let res = await inAuth({
                        code: value,
                        planId: plan.id,
                    });
                    if (res.resultCode == 200) {
                        let update = await updateOrder({
                            infoId: infoId,
                            state: "05",
                            title: "签到成功",
                            iconAndRemark:
                                "el-icon-check_success_" +
                                (plan.signLocation == "1"
                                    ? $("#addr").val()
                                    : "无"),
                        });
                        if (update.resultCode == 200) {
                            this.$message.success("签到成功！");
                            this.initOrder();
                        } else {
                            this.$message.error(update.resultMsg);
                        }
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                })
                .catch(() => {
                    $("#addr").val("");
                });
        },
        signOver(plan, infoId) {
            console.log(plan);
            if (plan.signLocation == "1") {
                this.$confirm(
                    "此预约签退前需要定位获取您的地址，是否同意?",
                    "定位",
                    {
                        confirmButtonText: "同意",
                        cancelButtonText: "拒绝",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.getCurrentLocation();
                    })
                    .catch(() => {});
            }
            console.log($("#addr").val());
            if ($("#addr").val() == "" && plan.signLocation == "1") {
                // this.$message.warning("没有定位获取到您的地址，请检查是否开启了定位权限");
                //不加这个if就直接跳过执行下面了，我也不知道为什么
                return;
            }
            if (!$("#addr").val() && plan.signLocation == "1") {
                this.$message.warning(
                    "没有定位获取到您的地址，请检查是否开启了定位权限"
                );
                return;
            }
            this.$confirm(
                plan.signLocation == "1"
                    ? "当前地址：( " + $("#addr").val() + " )"
                    : "确定签退吗？签退后代表本次预约流程结束。",
                "确定签退？",
                {
                    confirmButtonText: "立即签退",
                    cancelButtonText: "取消",
                    type: "success",
                }
            )
                .then(async () => {
                    let update = await updateOrder({
                        infoId: infoId,
                        state: "06",
                        title: "签退完成",
                        iconAndRemark:
                            "el-icon-check_success_" +
                            (plan.signLocation == "1"
                                ? $("#addr").val()
                                : "无"),
                    });
                    if (update.resultCode == 200) {
                        this.$alert(
                            "签退完成！感谢您的参与，祝您生活愉快~",
                            "已签退",
                            {
                                confirmButtonText: "好的",
                                callback: (action) => {},
                            }
                        );
                        this.initOrder();
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                })
                .catch(() => {
                    $("#addr").val("");
                });
        },
        showRating(infoId) {
            this.infoId = infoId;
            this.rateDialogVisible = true;
        },
        async rating() {
            if (!this.rateValue3 || !this.rateValue2 || !this.rateValue1) {
                this.$message.warning("请您先进行评分~");
                return;
            }
            let content =
                "A=" +
                this.rateValue1 +
                "&&B=" +
                this.rateValue2 +
                "&&C=" +
                this.rateValue3 +
                "&&comment=" +
                this.comment;
            let res = await rate({ infoId: this.infoId, content: content });
            if (res.resultCode == 200) {
                this.rateDialogVisible = false;
                this.$alert("评价成功！感谢您的评价，祝您生活愉快~", "提示", {
                    confirmButtonText: "好的",
                    callback: (action) => {},
                });
                this.initOrder();
            }
        },
        cancelOrder(planId, infoId) {
            this.$confirm("确定取消预约吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let update = await updateOrder({
                        infoId: infoId,
                        state: "03",
                        title: "已取消预约",
                        iconAndRemark: "el-icon-minus_warning_无",
                    });
                    if (update.resultCode == 200) {
                        this.$alert("取消预约成功！", "提示", {
                            confirmButtonText: "知道了",
                            callback: (action) => {},
                        });
                        this.initOrder();
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                })
                .catch(() => {});
        },
        getCurrentLocation() {
            var geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition(
                function (r) {
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        var myGeo = new BMap.Geocoder();
                        myGeo.getLocation(
                            new BMap.Point(r.point.lng, r.point.lat),
                            (result) => {
                                if (result) {
                                    $("#addr").val(result.address);
                                    alert(
                                        "获取地址成功（" + result.address + "）"
                                    );
                                } else {
                                    alert("定位信息获取失败，请打开定位或重试");
                                }
                            }
                        );
                    } else {
                        alert("定位信息获取失败，请打开定位或重试");
                    }
                },
                { enableHighAccuracy: true }
            );
        },
        payStart(vo, money) {
            this.info = vo;
            this.curMoneyFen = money;
            this.payMoney = this.handerMoeny(money);
            this.payDialog = true;
        },
        async submitPay() {
            this.submiting = true;
            let vo = {
                orderId: this.info.id,
                payMoney: this.payMoney,
                couponId : this.couponId,
                title: "预约订单付款",
                content: "预约订单",
            };
            console.log("v ", vo);
            let res = await createReservationOrder(vo);
            this.submiting = false;
            if (res.resultCode == 200) {
                this.$alert('支付成功！', '付款通知', {confirmButtonText: '好的',type: 'success' });
                this.payDialog = false;
            } else {
                if (res.resultCode == 520) {
                    this.$alert("账户余额不足！", "温馨提示", {
                        confirmButtonText: "去充值",type: 'warning',
                        callback: (action) => {
                            if (action == "confirm") {
                                this.$router.push("/reservation/payRecord");
                            }
                        },
                    });
                } else {
                    this.$alert(res.resultMsg, '付款失败', {confirmButtonText: '确定',type: 'error'});
                }
            }
            this.initOrder();
        },
        async getUserCoupon(val) {
            if (val.length == 1) {
                let res = await getCoupon();
                if (res.resultCode == 200) {
                    this.coupons = res.data;
                }
            }else{
                this.couponMoney = 0
                this.couponId = ''
            }
        },
        selectCoupon() {
            console.log("this.couponId = ", this.couponId);
            let res = this.coupons.find((item) => item.id === this.couponId);
            this.couponMoney = res.amount;
        },
        formatMoney(fen) {
            if (fen <= 0) return "";
            return regFenToYuan(fen) + "元";
        },
    },
};
</script>

<style>
.main {
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
</style>