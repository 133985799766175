<template>

    <div class="main">
        <div>
        <div style="float:right;">
            <a style="cursor: pointer;" @click="$router.push('/adminHome/reservation')"> <i class="el-icon-arrow-left" />返回</a>
        </div>
            <div class="row" style="width:60%">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-white">标题</span>
                    </div>
                    <input type="text" class="form-control" v-model="title"  aria-describedby="basic-addon1">
                </div>
            </div>

            <div class="row">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white">时间</span>
                </div>
                <el-date-picker style="margin-right:10px;" v-model="startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择活动开始时间">
                </el-date-picker>
                <el-date-picker v-model="endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择活动结束时间">
                </el-date-picker>
            </div><br>

            <div class="row">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white">活动地址</span>
                </div>
                <div class="col-5">
                    <el-input placeholder="请输入地址或点击获取定位" v-model="addr" id="addr"></el-input>
                </div>
                <div class="col-3">
                    <el-button-group>
                        <el-button icon="el-icon-location-information" type="primary" :loading="getAddr" plain @click="getCurrentLocation">获取定位</el-button>
                        <el-button type="success" plain icon="el-icon-search" @click="dialogVisible =true">查看地图</el-button>
                    </el-button-group>
                </div>
            </div><br>
            <div class="row">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white">预约者需提交</span>
                </div>
                <el-select style="width:60%" v-model="value" multiple filterable allow-create default-first-option placeholder="请选择标签 或者 输入自定义内容然后按回车">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <br><br>

            <div class="row">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white">设置签到码</span>
                    <input type="text" class="form-control" maxlength="6" v-model="signCode" placeholder="请输入签到码" aria-describedby="basic-addon1">
                </div>
                <div class="input-group-prepend" style="margin-left:20px;">
                    <span class="input-group-text bg-white">最大预约人数</span>
                    <el-input-number v-model="maxAmount" :min="1"></el-input-number>
                </div>
                <div class="input-group-prepend" style="margin-left:20px;">
                    <span class="input-group-text bg-white">预约费用(单位：分)</span>
                    <el-input-number v-model="money" :min="0" ></el-input-number>
                </div>
            </div><br><br>

            <!-- 自定义标签 -->
            <div class="row">
                预约标签：
                <el-tag size="medium" :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
                    {{tag}}
                </el-tag>
                <el-input class="input-new-tag" v-if="inputVisible" style="width:15%;" v-model="inputValue" ref="saveTagInput" size="medium" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                </el-input>
                <el-button v-else class="button-new-tag" size="medium" @click="showInput">+新建标签</el-button>
            </div>
            <br>

            <div class="row">
                <span>常用设置 &nbsp;&nbsp;</span>
                <el-tooltip class="item" effect="dark" content="用户提交预约后，是否预约成功，需要您的手动审批【我的发布-预约情况】" placement="top-start">
                    <el-switch style="margin-right:20px;" v-model="mustAudit" active-color="#13ce66" inactive-color="#E8E9EB" active-text="需要人工审核"> </el-switch>
                </el-tooltip>
                <el-switch style="margin-right:20px;" v-model="qrCode" active-color="#13ce66" inactive-color="#E8E9EB" active-text="开启扫码预约"> </el-switch>
                <el-switch style="margin-right:20px;" v-model="needFile" active-color="#13ce66" inactive-color="#E8E9EB" active-text="预约需要提交附件"> </el-switch>
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">启用后，预约情况系统会通过邮件通知您，例如有新的预约订单、用户取消了订单等等。<br/>提示：如您没绑定邮箱，请前往【账户信息-邮箱绑定】</div>
                    <el-switch style="margin-right:20px;" v-model="emailNotify" active-color="#13ce66" inactive-color="#E8E9EB" active-text="开启邮件提醒"> </el-switch>
                </el-tooltip>
                

            </div>
            <br> <br>
            预约事项说明
            <!-- <quill-editor ref="text" v-model="content" class="myQuillEditor" :options="editorOption" /><br><br> -->
            <div style="border: 1px solid #ccc;">
                    <Toolbar
                        style="border-bottom: 1px solid #ccc"
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                        :mode="mode"
                    />
                    <Editor
                        style="height: 400px; overflow-y: hidden;"
                        v-model="html"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onCreated="onCreated"
                    />
            </div>

            <hr>
            <div>
                <el-button type="primary" style="width:220px;" :loading="creating" @click="addReservation">提 交</el-button>
                <el-button type="warning" @click="showMoreSetDialog">更多设置</el-button>
            </div>

        <el-dialog title="查看地图，选择地址" :visible.sync="dialogVisible" width="80%">
                <el-input label="地址" style="padding-bottom:5px;" placeholder="点击下方地图，获取地址" v-model="selectAddrText" id="addrByMap" clearable></el-input>
                <baidu-map class="bm-view" :max-zoom="21" @click="getLocationByClick($event)" :scroll-wheel-zoom="true" center="厦门市">
                    <bm-city-list anchor="BMAP_ANCHOR_BOTTOM_RIGHT" ></bm-city-list>
                    <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
                    <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT " :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                </baidu-map>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="setAddr">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 更多设置 -->
            <el-dialog title="更多设置" :visible.sync="showMoreSet" :width="moreSetWidth">
                <el-date-picker v-model="pubTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="指定时间发布">
                </el-date-picker>
                <br><br>
                <el-switch v-model="multSign" active-color="#13ce66" inactive-color="#E8E9EB" active-text="可以重复预约"> </el-switch>
                <br><br>
                <el-switch v-model="signLocation" active-color="#13ce66" inactive-color="#E8E9EB" active-text="签到时需要用户定位"> </el-switch>
            </el-dialog>
        </div>

        <el-dialog 
            title="系统通知"
            :visible.sync="resultShow"
            width="50%"
            :destroy-on-close="true"
            >
            <el-result icon="success" title="预约事项创建成功！" subTitle="用户可通过下方二维码或链接进行预约，建议保存二维码至本地 ~"></el-result>
            <div class="row justify-content-center">
                <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div>
            </div>
            <div class="row justify-content-center" style="margin:5px">
                <div class="row">
                        预约链接：<a style="text-decoration: none" :href="QR_URL" target="_blank">{{QR_URL}}</a>
                </div>
            </div>
            </el-dialog>
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { getDetail,updatePlan } from "@/api/reservation";
import { Message } from "element-ui";
export default {
    name:'UpdatePlanAdmin',
    components: { Editor, Toolbar },
    data() {
        return {
            title: "",
            startTime: "",
            endTime: "",
            endTime: "",
            addr: "",
            selectAddrText: "",
            maxAmount: "",
            signCode: "",
            getAddr: false,
            dialogVisible: false,
            showMoreSet: false,
            editorOption: {},
            nowCreate: true,
            qrCode: true,
            delay: false,
            options: [
                {
                    value: "手机号",
                    label: "手机号",
                },
                {
                    value: "邮箱",
                    label: "邮箱",
                },
                {
                    value: "地址",
                    label: "地址",
                },
                {
                    value: "学校",
                    label: "学校",
                },
                {
                    value: "身份证号",
                    label: "身份证号",
                },
            ],
            value: [],
            dynamicTags: [],
            inputVisible: false,
            inputValue: "",
            tag: "",
            pubTime: "",
            delayAmount: "",
            money: "",
            emailNotify : false,
            comment: true,
            multSign: false,
            banCoupons: false,
            creating: false,
            resultShow: false,
            mustLogin: true,
            mustAudit: false,
            needFile: false,
            signLocation: false,
            QR_URL: "",
            editor: null,
            html: '',
            toolbarConfig: {},
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
            moreSetWidth:'20%',
            planId:''
        };
    },mounted() {
        this.planId = this.$route.query.planId
        this.init()
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        async init(){
            console.log('ccccc');
           let res =  await getDetail(this.planId)
           if(res.resultCode == 200 && res.data){
                let planData = res.data
                this.html = planData.detail
                this.title = planData.title
                this.signCode = planData.signCode
                this.maxAmount = planData.maxAmount
                this.value = planData.tags ? planData.tags.split(',') : []
                this.dynamicTags = planData.submitTags ? planData.submitTags.split(',') : [],
                this.qrCode = planData.qrCodeSign == "1" 
                this.emailNotify = planData.emailNotify == "1" 
                this.addr = planData.addr,
                this.money = planData.needMoney,
                this.pubTime =  planData.pubTime
                this.startTime =  planData.startTime
                this.endTime  =  planData.endTime
                this.mustAudit =  planData.mustAudit == "1" 
                this.needFile =  planData.needFile == "1" 
                this.signLocation =  planData.signLocation == "1"
           }else{
               this.$message.warning('无此预约计划信息')
           }
        },onCreated(editor) {
           this.editor = Object.seal(editor)
           this.initUploadImage();
           this.initUploadVideo();
        },initUploadImage(){
            var config = this.editor.getMenuConfig('uploadImage')
            config.server = '/api/file/imageHome/upload'
            config.maxFileSize= 8 * 1024 * 1024
            config.fieldName = 'file',
            config.headers={
                'cst-token': localStorage.getItem('cst-token'),
            }
            config.withCredentials= true
            config.onError = this.uploadImgError;
            config.onFailed = this.uploadImgFailed;
            config.onSuccess = this.uploadImgSuccess;
        },initUploadVideo(){
            var config = this.editor.getMenuConfig('uploadVideo')
            config.server = '/api/file/videoHome/upload'
            config.maxNumberOfFiles=3
            config.maxFileSize= 20 * 1024 * 1024
            config.fieldName = 'file',
            config.headers={
                'cst-token': localStorage.getItem('cst-token'),
            }
            console.log('config.headers = ',config.headers);
            config.withCredentials= true
            config.onError = this.uploadImgError;
            config.onFailed = this.uploadImgFailed;
            config.onSuccess = this.uploadImgSuccess;
        }
        ,uploadImgError(file){
            this.$message.error("上传失败，请稍后再试 ("+file.name+")")
        },uploadImgFailed(file){
            this.$message.error("添加失败!("+file.name+")")
        },uploadImgSuccess(){
            this.$message.success("添加成功！")
        },
        submitForm() {},
        getCurrentLocation() {
            this.getAddr = true;
            console.log("enter");
            // $("#getAddr").attr()
            var geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition(
                function (r) {
                    // 定位成功事件
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        var myGeo = new BMap.Geocoder();
                        // 根据坐标得到地址描述
                        myGeo.getLocation(
                            new BMap.Point(r.point.lng, r.point.lat),
                            (result) => {
                                if (result) {
                                    $("#addr").val(result.address);
                                    Message.success(
                                        "定位成功！您的地址是：" +
                                            result.address
                                    );
                                } else {
                                    Message.error(
                                        "定位信息获取失败，请手动输入"
                                    );
                                }
                            }
                        );
                    } else {
                        Message.error("定位信息获取失败，请手动输入");
                    }
                },
                { enableHighAccuracy: true }
            );
            setTimeout(() => {
                this.getAddr = false;
            }, 2000);
        },
        getLocationByClick(e) {
            console.log(e);
            var myGeo = new BMap.Geocoder();
            // 根据坐标得到地址描述
            myGeo.getLocation(
                new BMap.Point(e.point.lng, e.point.lat),
                (result) => {
                    $("#addrByMap").val(result.address);
                    Message.success("地址获取成功，" + result.address);
                }
            );
        },
        setAddr() {
            $("#addr").val($("#addrByMap").val());
            this.dialogVisible = false;
        },
        handleClose(tag) {
            console.log("dynamicTags = ", this.dynamicTags);
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                console.log("dynamicTags = ", this.dynamicTags);
                this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },
        addReservation() {
            if(!this.checkInput()){return;}
            this.$confirm('确定修改此预约事项吗', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.creating = true
                this.value.forEach((val, index) => {
                    if(val){this.value[index] =  val.replace(',','，') }
                });
                this.dynamicTags.forEach((val, index) => {
                    if(val){ this.dynamicTags[index] = val.replace(',','，') }
                });
                 
                let vo = {
                    title: this.title,
                    detail: this.html,
                    signCode: this.signCode,
                    maxAmount: this.maxAmount,
                    tags: this.value.toString(),
                    submitTags: this.dynamicTags.toString(),
                    qrCodeSign: this.qrCode ? "1" : "0",
                    emailNotify: this.emailNotiy ? "1" : "0",
                    addr: $("#addr").val(),
                    needMoney : this.money,
                    pubTime: this.pubTime,
                    startTime: this.startTime,
                    endTime: this.endTime,
                    mustAudit: this.mustAudit ? "1" : "0",
                    needFile: this.needFile ? "1" : "0",
                    signLocation: this.signLocation ? "1" : "0",
                    id:this.planId
                };
                let res = await updatePlan(vo);
                if (res.resultCode == 200) {
                    this.$message.success('修改成功');
                } else {
                    this.$message.error(res.resultMsg);
                }
            }).catch(() => {});
           
        },
        checkInput(){
            if(!this.startTime || !this.endTime){
                this.$message.warning('预约时间设定不能为空')
                return false;
            }
            if(this.startTime > this.endTime){
                this.$message.warning('开始时间不能超过结束时间')
                return false;
            }
            if(this.nowCreate == false){
                if(!this.pubTime){
                     this.$message.warning('请指定发布时间 或 开启“立即发布”')
                     return false;
                }
            }
            return true;
        },showMoreSetDialog(){
            if(!this.$_isMobile()){
                this.moreSetWidth = '25%'
            }else{
                this.moreSetWidth = '80%'
            }
            this.showMoreSet = true
        }
    },
};
</script>

<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.qrcode {
    display: inline-block;
    width: 180px;
    height: 180px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
}
.main {
    width: 87%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.bm-view {
  width: 100%;
  height: 400px;
}
</style>