import requests  from "../request";


export const list = () => {
    return requests({
         url:'/api/admin/feedback/list', method:'post'
     })
 }

 
export const deal = (vo) => {
    return requests({
         url:'/api/admin/feedback/deal', method:'post',params:vo
     })
 }

 
export const del = (id) => {
    return requests({
         url:'/api/admin/feedback/del', method:'post',params:id
     })
 }