
<template>
<div style="width:98%;margin: 0 auto">
    <div>
        <div class="row">
            <div ref="chart" style="width:50%;height:376px"></div>
            <div ref="mydiv" style="width:50%;height: 376px;">
            </div>
        </div>
        <hr>
        <div class="row">
            <div ref="chart1" style="width:50%;height:376px"></div>
            <div  style="width:50%;height:376px">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary">今日预约平台情况</h6>
                                </div>
                                <div class="card-body">
                                    <h4 class="small font-weight-bold">{{todayProgress.online.title}} <span
                                            class="float-right">{{getDoubleNum(todayProgress.online.done, todayProgress.online.total)}}%</span></h4>
                                    <div class="progress mb-4">
                                        <div class="progress-bar bg-info" role="progressbar" :style="{width: getDoubleNum(todayProgress.online.done, todayProgress.online.total)+'%'}"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>

                                    <h4 class="small font-weight-bold">{{todayProgress.sign.title}}  <span
                                            class="float-right">{{getDoubleNum(todayProgress.sign.done, todayProgress.sign.total)}}%</span></h4>
                                    <div class="progress mb-4">
                                        <div class="progress-bar bg-warning" role="progressbar" :style="{width: getDoubleNum(todayProgress.sign.done, todayProgress.sign.total)+'%'}"
                                             aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h4 class="small font-weight-bold">{{todayProgress.reservationOrder.title}} <span
                                            class="float-right">{{getDoubleNum(todayProgress.reservationOrder.done, todayProgress.reservationOrder.total)}}%</span></h4>
                                    <div class="progress mb-4">
                                        <div class="progress-bar" role="progressbar" :style="{width: getDoubleNum(todayProgress.reservationOrder.done, todayProgress.reservationOrder.total)+'%'}"
                                             aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h4 class="small font-weight-bold">{{todayProgress.payOrder.title}} <span
                                            class="float-right">{{getDoubleNum(todayProgress.payOrder.done, todayProgress.payOrder.total)}}%</span></h4>
                                    <div class="progress mb-4">
                                        <div class="progress-bar bg-danger" role="progressbar" :style="{width: getDoubleNum(todayProgress.payOrder.done, todayProgress.payOrder.total)+'%'}"
                                             aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h4 class="small font-weight-bold">{{todayProgress.reward.title}} <span
                                            class="float-right">{{getDoubleNum(todayProgress.reward.done, todayProgress.reward.total)}}%</span></h4>
                                    <div class="progress">
                                        <div class="progress-bar bg-success" role="progressbar" :style="{width: getDoubleNum(todayProgress.reward.done, todayProgress.reward.total)+'%'}"
                                             aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as echarts from 'echarts'
import {getOrderState,getProgressBar} from '@/api/admin/dataView'
export default {
    name: "DataView",
    data() {
        return {
            arr: [12, 14, 23, 34, 17],
            com: ["进行中", "已完成", "审核中", "已取消", "待签退"],
            data: [],
            todayProgress:{'online':'', 'sign':'', 'reservationOrder':'', 'payOrder':'', 'reward':''}
        };
    },
    mounted() {
        this.getEchartData();
        this.getEchartData1();
        this.setCharts();
        this.getProgress()
    },
    methods: {
        async getProgress(){
            let res = await getProgressBar()
            if(res.resultCode == 200){
                this.todayProgress = res.data
            }
        },
        getEchartData() {
            const chart = this.$refs.chart;
            if (chart) {
                const myChart = echarts.init(chart);
                const option = {
                    title: {
                        text: "本周订单营业总额",
                        left: "center",
                    },
                    legend: {},
                    tooltip: {},
                    dataset: {
                        source: [
                            ["周一", 43.3, 85.8],
                            ["周二", 83.1, 73.4],
                            ["周三", 86.4, 65.2],
                            ["周四", 72.4, 53.9],
                            ["周五", 82.4, 53.9],
                            ["周六", 42.4, 53.9],
                            ["周天", 72.4, 53.9],
                        ],
                    },
                    xAxis: { type: "category" },
                    yAxis: {},

                    series: [{ type: "bar" }, { type: "bar" }],
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            }
            this.$on("hook:destroyed", () => {
                window.removeEventListener("resize", function () {
                    myChart.resize();
                });
            });
        },
        getEchartData1() {
            const chart1 = this.$refs.chart1;
            if (chart1) {
                const myChart = echarts.init(chart1);
                const option = {
                    title: {
                        text: "流量折线图",
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    legend: {
                        data: [
                            "论坛区活跃",
                            "订单交易次数",
                            "预约人数",
                            "积分发放情况",
                            "接口请求量",
                        ],
                    },
                    grid: {
                        left: "3%",
                        right: "4%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {},
                        },
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: [
                            "一月",
                            "二月",
                            "三月",
                            "四月",
                            "五月",
                            "六月",
                            "七月",
                            "八月",
                            "九月",
                            "十月",
                            "十一月",
                            "十二月",
                        ],
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: [
                        {
                            name: "预约人数",
                            type: "line",
                            stack: "Total",
                            data: [
                                120, 132, 101, 134, 90, 230, 210, 120, 132, 101,
                                134, 90, 230,
                            ],
                        },
                        {
                            name: "订单交易次数",
                            type: "line",
                            stack: "Total",
                            data: [
                                220, 182, 191, 234, 290, 330, 310, 220, 182,
                                191, 234, 290, 330,
                            ],
                        },
                        {
                            name: "论坛区活跃",
                            type: "line",
                            stack: "Total",
                            data: [
                                150, 232, 201, 154, 190, 330, 410, 150, 232,
                                201, 154, 190, 330,
                            ],
                        },
                        {
                            name: "积分发放情况",
                            type: "line",
                            stack: "Total",
                            data: [
                                320, 332, 301, 334, 390, 330, 320, 320, 332,
                                301, 334, 390, 330,
                            ],
                        },
                        {
                            name: "接口请求量",
                            type: "line",
                            stack: "Total",
                            data: [
                                820, 932, 901, 934, 1290, 1330, 1320, 820, 932,
                                901, 934, 1290, 1330,
                            ],
                        },
                    ],
                };
                myChart.setOption(option);
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            }
            this.$on("hook:destroyed", () => {
                window.removeEventListener("resize", function () {
                    myChart.resize();
                });
            });
        },
        async setCharts() {
            let res = await getOrderState()
            if(res.resultCode == 200){
                let arr = res.data
                for (var i = 0; i < arr.length; i++) {
                    this.data.push({
                        value: arr[i],
                        name: this.com[i],
                    });
                }
                let myChart =echarts.init(this.$refs.mydiv);
                let option = {
                    title: {
                        //标题
                        text: "订单状态占比",
                        left: "center",
                    },
                    tooltip: {
                        //提示框组件
                        trigger: "item",
                    },
                    legend: {
                        //图例
                        bottom: '7%',
                        left: "center",
                    },
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderWidth: 2,
                    },
                    series: [
                        {
                            //系列列表，通过type设置想要的类型
                            name: "订单数",
                            type: "pie",
                            radius: "60%",
                            data: this.data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);
            }
        }, getDoubleNum(a,b){
          let res = a / b * 100;
          return res % 10== 0 ? res : res.toFixed(2)
        },
    },
    watch: {},
    created() {},
};
</script>



