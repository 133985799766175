<template>
    <div class="container">
        <!-- Outer Row -->
        <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-12 col-md-9">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block">
                              <img  style="margin-top:30px;margin-left:45px" width="350px" height="350px"  src="https://reservation-platform.oss-cn-shenzhen.aliyuncs.com/images/undraw_rocket.svg?versionId=CAEQMhiBgMCLjr7ehhgiIGUxZjExM2I0MDVmZTQ0ZTBhMTliZTM0NTFkYmIyNDMw" alt="">
                            </div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">预约平台</h1>
                                    </div>
                                    <form class="user">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user"
                                                v-model="username"
                                                placeholder="账号">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-user"
                                                v-model="password" placeholder="密码">
                                        </div>
                             
                                        <button @click="login" :disabled="isDisable" class="btn btn-primary btn-user btn-block">
                                            {{loginBtnText}}
                                        </button>
                                        <hr>
                                        <div class="form-group row">
                                          <div class="col-sm-6 mb-3 mb-sm-0">
                                            <span data-toggle="modal" data-target="#emailModal"  class="btn btn-user btn-block">
                                                 邮箱登录
                                            </span>
                                          </div>
                                          <div class="col-sm-6">
                                             <router-link to="/register" style="text-decoration-line: none;">
                                                <button class="btn btn-user btn-block">
                                                    注册账号
                                                </button>
                                            </router-link>
                                          </div>
                                        </div>

                                           <div
                    class="modal fade"
                    ref="emailModal"
                    id="emailModal"
                    tabindex="-1"
                    aria-labelledby="emailModal"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            登录
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="input-group mb-3">
                            <!-- v-if="!user.eamil" -->
                              <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01" >邮箱</label>
                              </div>
                              <input type="email" class="form-control"  placeholder="请输入正确的邮箱" id="inputEmail" aria-label="请输入正确的邮箱" aria-describedby="button-addon2">
                          </div>
                          <div class="input-group mb-3">
                            <input type="text" class="form-control"  v-model.trim="code"  placeholder="请输入验证码" aria-label="请输入验证码" aria-describedby="button-addon2">
                            <div class="input-group-append">
                              <button class="btn btn-success" type="button" :disabled="isDisableSend" @click="send" id="button-addon2">{{sendBtnText}}</button>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal">
                           取消
                          </button>
                          <button type="button" @click="loginByEmail" :disabled="isDisable" class="btn btn-primary">
                            {{loginBtnText}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>


</template>

<script>

import { userLogin,sendEmail,userLoginByEmail } from "@/api/userApi";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      code: "",
      isDisable: false,
      isDisableSend: false,
      sendBtnText: '获取验证码',
      loginBtnText: '登 录',
    };
  },
  methods: {
    async login() {
      if(!this.username || !this.password) {
        this.$message.warning("请输入账号或密码")
        return
      }
      this.isDisable = true
      this.loginBtnText = '登录中...'
      try {
        let res = await userLogin({username: this.username,password: this.password})
        if (res.data) {
          localStorage.setItem("cst-token", res.data);
          this.$router.replace('/reservation/plan')
        } else {
            this.$message.error(res.resultMsg);
        }
      }finally{
         this.isDisable = false
         this.loginBtnText = '登 录'
      }


    },async send(){
       console.log($("#inputEmail").val());
       let emailText = $("#inputEmail").val()
       if(emailText == '' ){
          $("#inputEmail").focus();
           return;  
       } 
       this.sendBtnText = "发送中...";
       this.isDisableSend = true;
        let result = await sendEmail({email: emailText , way: 'Login', needBindFlag:'1'});
        if(result.resultCode == 200){
            this.countDown(60)
            this.$message.success("发送成功，请注意接收!");
        }else{
           this.isDisableSend = false;
           this.sendBtnText = "获取验证码";
           this.$message.error("获取错误, "+result.resultMsg);
        }
    },async loginByEmail(){
      if(!$("#inputEmail").val()){
        $("#inputEmail").focus()
        return;
      }
      if(!this.code){
        this.$message.warning('请输入验证码')
        return
      }
       try {
          this.isDisable = true
          this.loginBtnText = '登录中...'
          let res = await userLoginByEmail(this.code)
          if(res.data){
            $('#emailModal').modal('hide');
            localStorage.setItem("cst-token", res.data);
            if(this.$route.query.jumpLocaltion){
              alert(this.$route.query.jumpLocaltion)
              window.location.href= this.$route.query.jumpLocaltion
              this.$route.query.jumpLocaltion=undefined
            }else{
              this.$router.replace('/reservation/plan')
            }
            
          } else {
              this.$message.error(res.resultMsg);
          }
       }finally{
         this.isDisable = false
         this.loginBtnText = '登 录'
       }
    },countDown(time) {
        if (time === 0) {
            this.isDisableSend = false;
            this.sendBtnText = "获取验证码";
            return;
        } else {
            this.isDisableSend = true;
            this.sendBtnText = "重新发送(" + time + ")秒";
            time--;
        }
        setTimeout(() => {
            this.countDown(time);
        }, 1000);
    }
  }
};
</script>

<style>

</style>