<template>
  <div>
       <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; CST-Reservation Platform 2022</span>
                    </div>
                </div>
        </footer>
  </div>
</template>

<script>
export default {
    name :'HomeFooter'
}
</script>

<style>

</style>