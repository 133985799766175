<template>
    <div class="main" v-loading="fullscreenLoading" element-loading-text="加载中...">
        <div v-if="plan">
            <div class="card mb-4">
                <div class="card-header" style="text-align:center">
                    {{plan.title}}
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label class="small mb-1">主题标签：</label>
                        <div class="tag-group">
                        <el-tag :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in plan.submitTags" :key="index">{{item}}</el-tag>
                        </div>
                    </div>
                    <form>
                        <div class="mb-3">
                            <label class="small mb-1">活动地点：{{plan.addr}}</label>
                        </div>
                        <div class="mb-3">
                            <label class="small mb-1">允许时段：{{plan.startTime}} 至 {{plan.endTime}} <el-divider direction="vertical"></el-divider>  预约人次({{plan.remainAmount}}/{{plan.maxAmount}})</label>
                        </div>
                        <div class="mb-3">
                            <div v-html="plan.detail"></div>
                        </div>
                        <div v-if="plan.tags" class="mb-3">
                            <el-collapse v-model="activeNames" >
                                <el-collapse-item :title="titleInfo" name="userInputItem">
                                    <div class="mb-3" v-for="(item, index) in plan.tags" :key="index">
                                        <label class="small mb-1">{{item}}</label>
                                        <input class="form-control" style="font-size:12" :id="'tag'+index" type="text" placeholder="请输入" />
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="mb-3">
                            <label class="small mb-1" style="margin-right:10px">选择时间（允许时段：{{plan.startTime}} 至 {{plan.endTime}}） </label>
                            <div class="mb-3">
                                <el-date-picker style="margin-right:10px;" v-model="startTime" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间">
                                </el-date-picker>
                                <el-date-picker style="margin-top:5px" v-model="endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable placeholder="选择结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="mb-3" v-if="plan.needFile == '1'">
                            <label class="small mb-1" style="margin-right:10px">提交附件 </label>
                            <div class="mb-3">
                               <el-upload 
                                    class="upload-demo"
                                    :limit="1" drag
                                    :list-type="fileListType" :on-exceed="exceedHander" :before-upload="beforeUpload"
                                    :on-success="successHander" :on-error="errorHander" :on-remove="removeHander" :headers="{ 'cst-token': token }"
                                    action="/api/file/reservation/upload">
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                    </el-upload>
                            </div>
                        </div>
                        <!-- Form Group (confirm password)-->
                        <el-divider><i class="el-icon-present"></i></el-divider>
                        <div style="text-align:center">
                            <br>
                            <el-button type="primary" v-if="loginFlag" style="width:280px;" :loading="submiting" @click="submitInfo">{{submitBtnText}}</el-button>
                            <el-button type="warning" v-else style="width:200px;" @click="goLogin">去登录</el-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <el-empty v-show="isEmpty" description="没有任何预约事项..."></el-empty>
        <div style="text-align:center">
            <p>Welcome to Reservation Platform By <a href="https://reservation.cstweb.top" target="_blank">首页reservation.cstweb.top</a> CST@2022</p>
        </div>
        <el-backtop :visibility-height="350" :right="10" :bottom="15"> </el-backtop>
    </div>

</template>

<script>
import { getInfo } from "@/api/reservation";
import { sign } from "@/api/reservation";
import { auth } from "@/api/userApi";
import '@wangeditor/editor/dist/css/style.css'
export default {
    name: "PlanDetail",
    data() {
        return {
            planId: "",
            plan: "",
            startTime: "",
            endTime: "",
            submiting: false,
            fullscreenLoading: true,
            isEmpty: false,
            loginFlag: true,
            activeNames: ['userInputItem'],
            titleInfo: '需填信息',
            submitBtnText: '提 交',
            fileAddr: '',
            fileListType : 'picture',
            showFileUplaod : true
        };
    },computed:{
          token() {
            return localStorage.getItem("cst-token");
        }
    },
    mounted() {
        document.title="在线预约平台"
        this.getReservationInfo();
    },
    methods: {
        async getReservationInfo() {
            this.planId = this.$route.query.id;
            let res = await getInfo(this.$route.query.id);
            this.fullscreenLoading = false;
            if (res.resultCode == 200) {
                this.plan = res.data;
                document.title = this.plan.title ? this.plan.title : '预约未找到'
                if (!res.data) {
                    this.isEmpty = true;
                    return;
                }
                 this.plan.tags = this.plan.tags
                    ? this.plan.tags.split(",")
                    : undefined;
                if(this.plan.tags){
                        this.titleInfo = '需填信息（共'+this.plan.tags.length+'项）'
                }
                if(new Date().getTime() < new Date(res.data.pubTime).getTime()){
                    this.submitBtnText = '请在 '+res.data.pubTime+' 后进行预约'
                }
                if(new Date().getTime() > new Date(this.plan.endTime).getTime() ){
                    this.submitBtnText = '预约已截止'
                }
                this.plan.submitTags = this.plan.submitTags.split(",");
                if (this.plan.mustLogin == "1") {
                    let token = await auth();
                    if (!token.data) {
                        this.loginFlag = false;
                        this.$confirm("当前预约需要先登录 ~", "提示", {
                            confirmButtonText: "现在去登录",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                this.goLogin();
                            })
                            .catch(() => {});
                    }
                }
            } else {
                this.isEmpty = true;
                this.$message.error(res.resultMsg);
            }
        },
        submitInfo() {
            if (!this.checkInput()) {
                return;
            }
            this.$confirm("确定提交预约吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
            })
                .then(async () => {
                    this.submiting = true;
                    let info = [];
                    if (this.plan.tags) {
                        this.plan.tags.forEach((tag, index) => {
                            let val = $("#tag" + index).val()
                            if(val){ val = val.replace(',','，') }
                            info.push(tag + "@#@" + val);
                        });
                    }
                    let vo = {
                        content: info.toString(),
                        planId: this.planId,
                        moduleId: 1,
                        fileAddr: this.fileAddr,
                        targetTime: this.startTime,
                        targetEndTime: this.endTime,
                    };
                    try{
                        let res = await sign(vo);
                        this.submiting = false;
                        if (res.resultCode == 200 && res.data == true) {
                            this.$message.success("预约成功！");
                            let t = "预约成功！"
                            let msg = "请注意按时赴约哦~"
                            if(this.plan.mustAudit == "1"){
                                t = "已提交预约申请"
                                msg = "请等待后台审核，预约结果会通过系统消息或邮件通知您。"
                            }
                            this.$router.replace({
                                name: "result",
                                params: {
                                    title: t,
                                    message: msg,
                                    iconType: "success",
                                },
                            });
                        } else {
                            this.$message.error("预约失败！");
                            this.$router.replace({
                                name: "result",
                                params: {
                                    title: "预约失败！",
                                    message: res.resultMsg,
                                    iconType: "warning",
                                },
                            });
                        }
                    }finally{
                         this.submiting = false;
                    }
                })
                .catch(() => {});
        },
        checkInput() {
            if (!this.startTime || !this.endTime) {
                this.$message.warning("预约时间不能为空");
                return false;
            }
            if (this.startTime > this.endTime) {
                this.$message.warning("开始时间不能超过结束时间");
                return false;
            }
            if (
                this.startTime < this.plan.startTime ||
                this.endTime > this.plan.endTime
            ) {
                this.$message.warning("预约的时间不在允许范围内！");
                return false;
            }

             if (new Date().getTime() > new Date(this.endTime).getTime() ) {
                this.$message.warning("结束时间不能早于当前时间");
                return false;
            }
             if (new Date().getTime() > new Date(this.plan.endTime).getTime() ) {
                this.$message.warning("预约已截止，无法预约。");
                return false;
            }
            return true;
        },goLogin() {
            localStorage.setItem(
                "reservation-jumpURL",
                "/reservation/detail?id=" + this.planId
            );
            this.$router.replace("/login");
        },successHander(response) {
            console.log("response = ", response.data);
            if (!response.data) {
                this.$message.warning(response.resultMsg);
                return;
            }
            this.fileAddr = response.data
            console.log('this.fileAddr=',this.fileAddr);
            this.$message.success("附件选择成功!");
        },
        errorHander(response) {
            console.log("response = ", response);
            this.$message.error("附件上传失败!");
        },
        removeHander(file) {
            this.fileAddr = ''
        },exceedHander(file){
            this.$message.warning('您已经选择附件了..不能选择多个哦')
        },beforeUpload(file){
            if(!this.isImage(file.name)){
                console.log('text',file.name);
                this.fileListType = 'text'
            }else{
                 this.fileListType = 'picture'
            }
        } ,isImage(fileName) {
            if(!fileName) return false
            var index= fileName.lastIndexOf(".");
            var ext = fileName.substr(index+1);
            return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1;
            }
        },
};
</script>

<style scoped>
.main {
    width: 85%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.main /deep/ .el-upload{
  width: 100%;
}
.main /deep/ .el-upload .el-upload-dragger{
  width: 100%;
}
</style>