<template>
  <div style="width:80%;margin:0 auto;">
         <el-card   class="box-card" v-for="vo in infos" :key="vo.id" shadow="hover" style="margin-top:10px;" >
            <div slot="header" class="clearfix">
                <span><i class="el-icon-message-solid"></i> {{vo.title}}</span>
                <!-- <el-button @click="setInfo(vo.plan,vo.info,vo.steps)" style="float: right; padding: 3px 0" type="text">查看详细</el-button> -->
            </div>
            <div class="text item" style="font-size:13px;" >
                <strong style="cursor:pointer;" @click="toDetail(vo.clickUrl)"><h5>{{vo.content}}</h5></strong><span v-if="vo.type == '00'"><br> 操作人：<el-link :underline="false" type="primary" @click="$userInfoDialog(vo.createUser);">{{vo.createUserNickname}}</el-link>  {{vo.remark}}</span>
                <hr>
                <el-collapse v-if="vo.imageUrl" v-model="activeNames">
                    <el-collapse-item  title="展开查看图片" name="1">
                        <div class="block">
                            <span class="demonstration">{{vo.remark}}</span>
                            <el-carousel indicator-position="outside">
                                <el-carousel-item v-for="(img,index) in vo.imageUrl.split('&**&')" :key="index">
                                    <div style="text-align:center"><img height="200px" :src="img" alt="图片"></div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <div class="el-row">
                    <div style="float:left">
                        时间：{{vo.sendTime ? vo.sendTime : vo.createTime}}
                        <el-divider direction="vertical"></el-divider>
                    </div>
                    <div style="float:right">
                        <el-link :underline="false" type="primary" @click="$userInfoDialog(vo.createUser);">{{vo.type == '00' ? '系统消息' : vo.createUserNickname}}</el-link>
                    </div>
                </div>
            </div>
        </el-card>

        <el-empty v-if="!infos.length" description="加载消息中..."></el-empty>
  </div>
</template>

<script>
import { listNotify } from "@/api/notify";
export default {
 name:'NotifyDetail',
 data () {
     return {
         infos:[],
         activeNames: []
     }
 },mounted () {
     this.initNotify()
 },
 methods: {
    async initNotify(){
        let res = await listNotify();
        if(res.resultCode == 200 && res.data){
            this.infos = res.data
        }
    },toDetail(url){
            if(!url){return}
            if(url.indexOf("http") == -1){
                this.$router.push(url)
            }else{
                window.location.href = url;
            }
        }
 }
}
</script>

<style>

</style>