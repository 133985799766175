<template>
    <div>
        <div style="float:right;">
            <a style="cursor: pointer;" @click="goBack"> <i class="el-icon-arrow-left" />返回</a>
        </div>
        <div class="card" style="width:90%;margin:0 auto">
            <div class="card-header mb-10">
                <div style="height: 40px;margin-left: 0;" class="panel-heading">
                    <div style="width:53px;float: left;">
                        <img @click="showUser(topic.createUser)" style="height: 40px;width: 40px;border-radius: 8px;border: 1px solid white;cursor: pointer;" :src="topic.userAvatar">
                    </div>
                    <div style="width:75%;float: left;">
                        <div>
                            <span class="panel-title" style="position:relative;text-align: left;"><a @click="showUser(topic.createUser)" style="font-size: 18px;text-decoration: none;" href="javascript:void(0)">{{topic.nickname}}</a></span>
                        </div>
                        <div>
                            <span class="panel-title" style="text-align: left;">{{topic.title}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div style="clear: left;font-size: 13px;">
                    <div v-html="topic.content"></div>
                </div>
                <div class="tag-group" v-if="topic.tags">
                    <el-tag size="mini" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in topic.tags.split(',')" :key="index">{{item}}</el-tag>
                </div>
            </div>
            <div class="card-footer">

                <div style="text-align: center;height:20px;">
                    <div style="float: left;">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                <i class="fas fa-ellipsis-h" aria-hidden="true" @click="chechFavorite(topic.id)"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="userFavorite(topic.id)"><i style="color:#409EFF;" :class="[isFavorite ?'el-icon-star-on':'el-icon-star-off']"></i>收藏</el-dropdown-item>
                                <el-dropdown-item @click.native="reportTopic(topic.id)"><i class="el-icon-warning-outline"></i>举报</el-dropdown-item>
                                <el-dropdown-item @click.native="cai"><i class="el-icon-caret-bottom"></i>踩踩此贴</el-dropdown-item>
                                <el-dropdown-item @click.native="createQrCodeTopicShare"><i class="fas fa-qrcode"></i> 二维码分享</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-divider direction="vertical"></el-divider>
                        <i class="fa fa-eye fa-1x" title="浏览量" aria-hidden="true" style="margin-right:10px">
                            <span id="topicVisitorNum" style="font-size:9px;" title="浏览量"> {{topic.visitorNum}}</span>
                        </i>
                        <el-divider direction="vertical"></el-divider>
                        <span style="font-size: 9px;" name="showTime" v-html="dateFormat(topic.createTime)"></span>
                    </div>

                    <div style="float: right;font-size:12px;">
                        <i @click="like(topic.id)" style="cursor: pointer;font-size: 13px;color:#FF6685" title="赞" :id="'like'+topic.id" :class="isLike(topic.likeUser,topic.id)" aria-hidden="true">
                        </i> <span style="font-size: 13px;" :id="'likeNum'+topic.id">{{topic.likeUser ? topic.likeUser.split('-').length : ''}}</span>
                        <el-divider direction="vertical"></el-divider>
                        <!-- <i style="cursor: pointer;font-size: 17px;" title="赞" class="far fa-heart" aria-hidden="true"></i> -->
                        <i style="cursor: pointer;" title="回复" class="el-icon-chat-dot-round" aria-hidden="true" @click="showCommentDialog('','','','')">
                            <span>评论 <span v-if="totalSize" v-html="totalSize"></span></span>
                        </i>

                        <span style="cursor: pointer;" v-if="isMe(topic.createUser)"><a @click="deleteTopic(topic.id)" style="cursor: pointer;">
                                <el-divider direction="vertical"></el-divider> <i class="el-icon-delete-solid" /> 删除
                            </a></span>
                    </div>
                </div>
            </div>

        </div>
        <el-divider></el-divider>
        <!-- 评论 -->
        <div style="width:80%;margin:0 auto" v-for="comment in comments" :key="comment.id" :id="comment.id">
            <el-card class="box-card">
                <div style="height: 42px;border: 0px solid red;">
                    <div style="float: left; ">
                        <img @click="showUser(topic.createUser)" style="height: 33px;width: 33px;border-radius: 20px;border: 1px solid white;cursor: pointer;" :src="comment.userAvatar">
                        <span style="position: absolute;padding-top:-3px;padding-left:4px;font-size: 15px;"> <b><a @click="showUser(topic.createUser)" style="text-decoration: none;" href="javascript:void(0)">{{comment.nickname}}</a></b></span>
                    </div>
                    <div style="float: right;">
                        <!-- <span style="font-size: 13px;" class="label label-info">楼主</span> -->
                        <span style="font-size: 9px;color:#666666" name="showTime" v-html="dateFormat(comment.createTime)"></span>
                    </div>
                </div>
                <!--评论内容-->
                <div style="border: 0px solid black;clear: left;">
                    <div v-if="!comment.toUser" style="margin: 1px;padding-left: 15px;font-size: 14px;" v-html="comment.content"></div>
                    <p v-once v-if="comment.targetCommentId" :id="'replyText'+comment.id" v-html="findSourceComment(comment.toUser,comment.targetCommentId,comment.id)" style='background:#EEEEEE;padding:6px;border-radius:5px;font-size:8px;color:#666666'></p>
                    <div v-if="comment.toUser" style="margin: 1px;padding-top: 3px;padding-left: 15px;font-size: 14px;" v-html="'回复<strong>'+'@'+comment.toUser + '</strong>'+'：'+comment.content"></div>
                </div>
                <!--点赞、回复-->
                <div style="float: right;font-size: 10px;border: 0px solid green;padding-bottom:8px;">
                    <i @click="likeComment(comment.id)" style="cursor: pointer;font-size: 13px;color:#FF6685" title="赞" :id="'like'+comment.id" :class="isLike(comment.likeUser,comment.id)" aria-hidden="true">
                    </i> <span :id="'likeNum'+comment.id">{{comment.likeUser ? comment.likeUser.split('-').length : ''}}</span>
                    <el-divider direction="vertical"></el-divider>

                    <i style="cursor: pointer;" title="弹" class="el-icon-chat-dot-round" @click="showCommentDialog(comment.createUser,comment.nickname,comment.id,comment.content)" aria-hidden="true"><span>回复</span></i> &nbsp;

                    <span style="cursor: pointer;" v-if="isMe(comment.createUser)" @click="deleteComment(comment.id)">
                        <el-divider direction="vertical"></el-divider><i class="el-icon-delete-solid" /> 删除
                    </span>
                </div>
            </el-card><br>
        </div>
        <div style="width:80%;margin:0 auto;margin-bottom:15px;" v-if="comments.length">
            <el-pagination @current-change="handleCurrentChange" background layout="total, prev, pager, next" :page-size="8" :total="totalSize">
            </el-pagination>
        </div>
        <el-empty v-show="!comments.length" description="还没有人发表评论..."></el-empty>
        <el-drawer :title="commentDialogTitle" :modal="false" :size="'30%'" :visible.sync="drawer" direction="btt">
            <div class="row" style="margin-left:10%;margin-right:10%">
                <div style="width:90%;">
                    <textarea class="form-control"  placeholder="请输入..." id="commentInput"></textarea>
                </div>
                <div>
                    <el-button size="small" @click="comment" style="width:100px" plain type="primary">评论</el-button>
                </div>
            </div>
        </el-drawer>

        <el-dialog title="举报帖子" :visible.sync="userReportDialog" width="60%">
            <div class="form-group col-md-12">
                <label for="inputState">举报类型</label>
                <select class="form-control" id="reportType">
                    <option selected value="发布低俗/违法信息">发布低俗/违法信息</option>
                    <option value="存在欺诈行为">存在欺诈行为</option>
                    <option value="内容和标题不符">内容和标题不符</option>
                    <option value="侵犯未成年人权益">侵犯未成年人权益</option>
                    <option value="侵犯个人隐私">侵犯个人隐私</option>
                    <option value="其他违规行为">其他违规行为</option>
                </select>
                 <br>
                <textarea class="form-control" id="reportContent" placeholder="描述一下情况..."></textarea>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="userReportDialog = false">取 消</el-button>
                <el-button type="primary" @click="userReport">提 交</el-button>
            </span>
        </el-dialog>

        <el-dialog close="el-col-12" title="帖子分享" :visible.sync="topicShareDialog" width="75%">
            <div class="row justify-content-center">
                <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div>
            </div>
            <span slot="footer" class="row justify-content-center">
                <br>
                <el-divider style="font-size:12px;"><i class="el-icon-mobile-phone"></i>扫一扫浏览帖子</el-divider>
                <el-button type="primary" @click="topicShareDialog = false">好的，知道了</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
import {
    detail,
    deleteTopic,
    deleteComment,
    listComment,
    addComment,
    topicLike,
    commentLike,
  
    detailComment,
} from "@/api/topic";
import { getDateDiff } from "@/util";
import { check, favorite } from "@/api/favorite";
import { report } from "@/api/userApi";
import QRCode from "qrcodejs2";
export default {
    name: "TopicDetail",
    data() {
        return {
            drawer: false,
            commentInfo: "",
            topicId: "",
            topic: "",
            toUser: "",
            targetCommentId: 0,
            comments: [],
            totalSize: 0,
            commentDialogTitle: "发表评论",
            isFavorite: false,
            userReportDialog: false,
            topicShareDialog: false,
            curTopicId:'',
        };
    },
    computed: {
        token() {
            return localStorage.getItem("cst-token");
        },
    },
    mounted() {
        this.topicId = this.$route.query.topicId;
        this.initTopic();
        this.initComment();
    },
    methods: {
        goBack() {
            this.$router.push("/reservation/forumArea");
        },
        async initTopic() {
            let res = await detail(this.topicId);
            this.topic = res.data;
        },
        async initComment() {
            let request = {
                pageNo: 1,
                pageSize: 8,
                orderType: "create_time",
                content: this.topicId,
            };
            let res = await listComment(request);
            this.totalSize = res.totalSize;
            this.comments = res.data;
        },
        deleteTopic(topicId) {
            this.$confirm("确定删除贴子吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let res = await deleteTopic({ id: topicId });
                    if (res.resultCode == 200) {
                        this.$message.success("帖子删除成功！");
                        this.$router.push("/reservation/forumArea");
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                })
                .catch(() => {});
        },
        deleteComment(commentId) {
            this.$confirm("确定删除评论吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let res = await deleteComment({ id: commentId });
                    if (res.resultCode == 200) {
                        $("#" + commentId).hide(100);
                        this.$message.success("评论删除成功！");
                        this.totalSize--;
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                })
                .catch(() => {});
        },
        isMe(userId) {
            return (
                this.$store.state.user.userInfo &&
                userId == this.$store.state.user.userInfo.userId
            );
        },
        async handleCurrentChange(val) {
            let request = {
                pageNo: val,
                pageSize: 8,
                orderType: "create_time",
                content: this.topicId,
            };
            let res = await listComment(request);
            this.comments = res.data;
            this.totalSize = res.totalSize;
        },
        showCommentDialog(toUser, nickname, toId, content) {
            this.toUser = toUser;
            if (this.toUser) {
                this.commentDialogTitle =
                    "回复 @" +
                    nickname +
                    ": " +
                    (content.length < 30
                        ? content
                        : content.substring(0, 30) + " ....");
                this.targetCommentId = toId;
            } else {
                this.commentDialogTitle = "发表评论";
                this.targetCommentId = "";
            }
            this.drawer = true;
        },
        async comment() {
            let commentInfo = $("#commentInput").val();
            if (!commentInfo) return;
            if(commentInfo.toLowerCase().indexOf("<script>") != -1 ){
                 this.$message.warning('请不要输入这个敏感内容: <script>')
                 return;
             }
            let res = await addComment({
                topicId: this.topicId,
                content: commentInfo,
                toUser: this.toUser,
                targetCommentId: this.targetCommentId,
            });
            if (res.resultCode == 200) {
                this.drawer = false;
                this.$message.success("评论成功！");
                $("#commentInput").val("");
                this.initComment();
            } else {
                this.$message.error(res.resultMsg);
            }
        },
        async like(id) {
            let num = $("#likeNum" + id).text();
            num = num ? num : 0;
            let res = await topicLike({ id: id });
            if (res.resultCode == 200) {
                if (res.data == true) {
                    $("#like" + id).css({ color: "#FF6685" });
                    $("#like" + id).attr("class", "fas fa-heart");
                    $("#likeNum" + id).text(parseInt(num) + 1);
                } else {
                    $("#like" + id).attr("class", "far fa-heart");
                    $("#likeNum" + id).text(parseInt(num) - 1);
                    if (parseInt(num) - 1 == 0) {
                        $("#likeNum" + id).text("");
                    }
                }
            } else {
                this.$message.error("点赞失败");
            }
        },
        isLike(likeUser, id) {
            likeUser = likeUser ? likeUser : "";
            if (this.$store.state.user.userInfo) {
                let like =
                    likeUser.indexOf(this.$store.state.user.userInfo.userId) !=
                    -1;
                if (like) {
                    $("#like" + id).css({ color: "#FF6685" });
                    $("#like" + id).attr("class", "fas fa-heart");
                    return "fas fa-heart";
                } else {
                    $("#like" + id).attr("class", "far fa-heart");
                    return "far fa-heart";
                }
            } else {
                return "far fa-heart";
            }
        },
        async likeComment(id) {
            let num = $("#likeNum" + id).text();
            num = num ? num : 0;
            let res = await commentLike({ id: id });
            if (res.resultCode == 200) {
                if (res.data == true) {
                    $("#like" + id).css({ color: "#FF6685" });
                    $("#like" + id).attr("class", "fas fa-heart");
                    $("#likeNum" + id).text(parseInt(num) + 1);
                } else {
                    $("#like" + id).attr("class", "far fa-heart");
                    $("#likeNum" + id).text(parseInt(num) - 1);
                    if (parseInt(num) - 1 == 0) {
                        $("#likeNum" + id).text("");
                    }
                }
            } else {
                this.$message.error("点赞失败");
            }
        },
        findSourceComment(toUser, id, flagId) {
            let source = this.comments;
            for (let key of Object.keys(source)) {
                if (source[key].id == id) {
                    return source[key].content.length < 120
                        ? toUser + ": " + source[key].content
                        : toUser +
                              ": " +
                              source[key].content.substring(0, 60) +
                              " .....";
                }
            }
            //从数据库中捞，不能return ，因为方法是异步,flagId
            this.getSourceComment(id, flagId);
        },
        dateFormat(date) {
            return getDateDiff(date);
        },
        async getSourceComment(id, flagId) {
            let res = await detailComment({ id: id });
            if (res.resultCode == 200 && res.data) {
                $("#replyText" + flagId).html(
                    res.data.content.length < 120
                        ? res.data.nickname + ": " + res.data.content
                        : res.data.nickname +
                              ": " +
                              res.data.content.substring(0, 60) +
                              " ....."
                );
            } else {
                $("#replyText" + flagId).html("内容已被删除");
            }
        },
        async chechFavorite(id) {
            let res = await check({ targetId: id, type: "topic" });
            if (res.resultCode == 200) {
                console.log("res-flag", res.data);
                this.isFavorite = res.data;
            }
        },
        async userFavorite(id) {
            let res = await favorite({ targetId: id, type: "topic",remark:'帖子收藏'});
            if (res.resultCode == 200) {
                console.log("res-flag2", res.data);
                this.isFavorite = res.data;
                if(res.data ){
                    this.$message.success("收藏成功");
                }else{
                     this.$message.warning("已取消收藏");
                }
            } else {
                this.$message.error("收藏失败");
            }
        },
        async userReport() {
            let vo = {
                content:
                    $("#reportType").val() + "_" + $("#reportContent").val(),
                type: "举报帖子",
                targetId: this.curTopicId
            };
            let res = await report(vo);
            if (res.resultCode == 200) {
                this.$message.success(
                    "举报成功，感谢您的反馈！我们会尽快处理。"
                );
                this.userReportDialog = false;
            } else {
                this.$message.error("举报失败");
            }
        },reportTopic(id){
            this.curTopicId = id
            this.userReportDialog = true
        }
        ,cai(){
            this.$message.success('您踩了这个帖子一脚!')
        }
        ,createQrCodeTopicShare() {
            this.topicShareDialog = true;
                 this.$nextTick(() => {
                document.getElementById("qrCodeUrl").innerHTML = "";
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: window.location.href, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
                });
            });
            
       }, showUser(userId){
            this.$userInfoDialog(userId);
        }
    },
};
</script>

<style>
a:hover{
    text-decoration: none;
}
</style>