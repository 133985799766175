<template>
    <!-- col-xl-6 col-md-12  mb-12 -->

    <div class="main">
        <div>

            <el-link style="primary" :underline="false"><i :class="showStyle ? 'el-icon-s-grid' : 'el-icon-s-operation'" @click="showStyle = !showStyle" style="cursor:pointer;"></i></el-link>
        </div>
        <el-row :gutter="25" v-if="showStyle">
            <el-col :span="$_isMobile() ? 24 : 8" v-for="(plan) in plans" :key="plan.id">
                <el-card class="box-card" style="margin-bottom:25px;">
                    <div slot="header" class="clearfix">
                        <span><i class="el-icon-s-flag"></i> {{plan.title}}</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="showDetail(plan)">查看详情</el-button>
                    </div>
                    <div style="font-size:13px">
                        <p><i class="el-icon-location-information"></i> 活动地点：{{plan.addr}}</p>
                        <p><i class="el-icon-alarm-clock"></i> 截止时间：{{plan.endTime}}</p>
                        <div class="tag-group" v-if="plan.submitTags">
                            <el-tag size="mini" style="margin-right:5px;margin-bottom:3px" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in plan.submitTags.split(',')" :key="index">{{item}}</el-tag>
                        </div>
                        <div style="float:right;margin-bottom:8px">
                            <el-button type="primary" icon="el-icon-mobile-phone" plain v-if="plan.qrCodeSign == '1'" @click="open(plan)">扫码预约</el-button>
                            <el-button v-else @click="showDetail(plan)">点击预约</el-button>
                        </div><br>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-table v-else :data="plans" style="width: 100%" :stripe="true">
            <el-table-column prop="title" label="事项标题" width="210"> </el-table-column>
            <el-table-column prop="submitTags" label="预约类别标签" width="410">
                <!-- <template slot-scope="scope">
                <p v-html='scope.row.detail'></p>
            </template> -->
                <div class="tag-group" slot-scope="scope" >
                    <el-tag :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in scope.row.submitTags.split(',')" :key="index">{{item}}</el-tag>
                </div>
            </el-table-column>
            <el-table-column prop="addr" label="活动地点"> </el-table-column>
            <el-table-column prop="endTime" label="预约截止时间"> </el-table-column>
            <el-table-column label="预约操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.qrCodeSign == '1'" @click="open(scope.row)">扫码预约</el-button>
                    <el-button @click="showDetail(scope.row)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-empty v-show="plans.length == 0" description="没有找到任何预约事项"></el-empty>
        <el-pagination style="float: right" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :page-size="6" :total="totalSize">
        </el-pagination>
        <el-dialog title="扫码预约" :visible.sync="dialogVisible" :width="$_isMobile() ? '96%' : '40%'">
            <div class="row justify-content-center">
                <div class="qrcode" id="qrCodeUrl" ref="qrCodeUrl"></div>
            </div>
            <span slot="footer" class="row justify-content-center">
                <el-divider style="font-size:12px;"><i class="el-icon-mobile-phone"></i>使用手机扫一扫预约</el-divider>
                <el-button type="primary" @click="dialogVisible = false">好的，知道了</el-button>
            </span>
        </el-dialog>

        <br><br>
        <hr>
        <el-divider style="font-size:12px;">地图定位信息一览</el-divider>
        <baidu-map class="map" :max-zoom="21" @click="setClick($event)" :scroll-wheel-zoom="true" center="厦门市">
            <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
            <bm-city-list anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-city-list>
            <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        </baidu-map>

        <el-dialog title="预约事项详情" :visible.sync="detailDialog" :width="$_isMobile() ? '99%' : '80%'">
            <div class="el-row">
                主题：<a :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">{{plan.title}} </a>
                <i style="float:right;color:#409EFF;cursor:pointer" :class="[isFavorite ?'el-icon-star-on':'el-icon-star-off']" @click="userFavorite(plan.id)">收藏</i>

            </div><br>
            <el-divider content-position="center"><span style="font-size:12px;">预约事项说明</span></el-divider>
            <div class="block">
                <div class="el-row">
                    <span style="font-size:12px;">活动地点：<strong>{{plan.addr}}</strong></span>
                    <el-divider direction="vertical"></el-divider>
                    <span style="font-size:12px;">预约时间：<strong>{{plan.startTime}} 至 {{plan.endTime}}</strong></span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;"> 限制人数：{{plan.maxAmount}}</span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;"> 模式:{{plan.needMoney ? '【需付费'+handerMoeny(plan.needMoney)+'元】' : '免费预约'}}</span>
                    <el-divider direction="vertical"></el-divider> <span style="font-size:12px;">{{plan.signLocation == '1' ? '【需要定位】' : ''}}</span>
                </div><br>
                <el-collapse v-model="activeNames">
                    <el-collapse-item title="查看详细说明" name="planDetail">
                        <div class="mb-3" v-html="plan.detail">
                        </div>
                        <div class="tag-group" v-if="plan.submitTags">
                            <el-tag style="margin-right:7px" :type="index % 2 ? 'success' : index % 3 ? 'primary' : 'warning'" v-for="(item, index) in plan.submitTags.split(',')" :key="index">{{item}}</el-tag>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="预约时需填信息" name="planTags">
                        <div class="mb-3" v-html="plan.tags">
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <div>
                    <br>发布者：<el-link :underline="false" type="primary" @click="$userInfoDialog(plan.createUser);">{{plan.createUserNickname}}</el-link>
                    <el-divider direction="vertical"></el-divider>
                    预约链接：<a :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">https://reservation.cstweb.top/#/reservation/detail?id={{plan.id}}</a>
                </div>
                <br>
                <span slot="footer" class="row justify-content-center">
                    <a class="btn btn-success" :href="'https://reservation.cstweb.top/#/reservation/detail?id='+plan.id" target="_blank">立即预约</a>
                </span>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="startReservationVisible" title="选择预约模式" center="" width="60%">
            <div class="row" style="text-align:center;">
                <div class=" col-xl-6 col-md-6 mb-6" @click="createCommonPlan('common')" style="cursor:pointer;margin-top:15px;">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    通用预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-primary text-uppercase
                            mb-1
                          ">
                                    满足大部分预约需求，事项与流程相对固定，具有通用性~
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 mb-6" @click="createCommonPlan('business')" style="cursor:pointer;margin-top:15px;">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-handshake fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    交易预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                    常用到涉及金钱、物品交易等事项，需要线下或线上进行
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" style="text-align:center;">
                <div class="col-xl-6 col-md-6 mb-6"  @click="createCommonPlan('quickly')" style="cursor:pointer;margin-top:20px;">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-paper-plane fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    快速预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-success text-uppercase
                            mb-1
                          ">
                                    快捷为王！简易又快捷的事项，如收集报表、统计人数等
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 mb-6" @click="createCommonPlan('medical')" style="cursor:pointer;margin-top:20px;">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <i style="padding:15px;" class="fas fa-hospital fa-2x text-gray-300"></i>
                                </div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                    医疗预约
                                </div>
                            </div>
                            <div class="col mr-2">
                                <div class="
                            text-xs
                            font-weight-bold
                            text-info text-uppercase
                            mb-1
                          ">
                                    适用于医疗的相关预约事项，如挂号预约、买药、核酸检测预约等
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </el-dialog>
        <div style="position: fixed;left: 25px; bottom: 25px;">
            <el-button type="primary" style="width:60px;height:60px;font-size:20px;" title="预约订单" @click="$router.push('/reservation/order')" icon="el-icon-date" circle></el-button>
        </div>
        <div style="position: fixed;right: 25px; bottom: 25px;">
            <el-button type="primary" style="width:60px;height:60px;font-size:20px;" title="发布预约" @click="startReservationVisible = true" icon="el-icon-edit" circle></el-button>
        </div>
    </div>
</template>

<script>
import { favorite, check } from "@/api/favorite";
import { list } from "@/api/reservation";
import { getInfo } from "@/api/reservation";
import { Message } from "element-ui";
import QRCode from "qrcodejs2";
import { regFenToYuan } from "@/util";
export default {
    name: "Plan",
    data() {
        return {
            showStyle: true,
            plans: [],
            activeNames: ["planDetail", "planTags"],
            plan: "",
            totalSize: 0,
            dialogVisible: false,
            QRurl: "",
            detailDialog: false,
            isFavorite: false,
            startReservationVisible: false,
            content: "",
        };
    },
    mounted() {
        if(this.$route.query.content){
          this.content = this.$route.query.content
        }
        this.get();
    },
    methods: {
        searchInfo(info) {
            this.content = info;
            this.get();
        },
        async get() {
            console.log(this.content);
            let res = await list({
                pageNo: 1,
                pageSize: 6,
                moduleId: 1,
                content: this.content,
            });
            this.totalSize = res.totalSize;
            this.plans = res.data;
        },
        createCommonPlan(type) {
            this.startReservationVisible = false;
            if(type == 'common'){
                this.$router.push("/reservation/createPlan");
            }else if(type == 'business'){
                this.$router.push("/reservation/createPlanBusiness");
            }else if(type == 'quickly'){
                this.$router.push("/reservation/createPlanQuickly");
            }else{
                this.$router.push("/reservation/createPlanMedical");
            }
        },
        async handleCurrentChange(val) {
            console.log(this.content);
            let res = await list({
                pageNo: val,
                pageSize: 6,
                moduleId: 1,
                content: this.content,
            });
            this.totalSize = res.totalSize;
            this.plans = res.data;
        },
        open(row) {
            this.QRurl =
                "https://reservation.cstweb.top/#/reservation/detail?id=" +
                row.id;
            console.log("QRurl = ", this.QRurl);
            this.dialogVisible = true;
            this.creatQrCode();
        },
        creatQrCode() {
            this.$nextTick(() => {
                document.getElementById("qrCodeUrl").innerHTML = "";
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.QRurl, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            });
        },
        setClick(e) {
            var myGeo = new BMap.Geocoder();
            // 根据坐标得到地址描述
            myGeo.getLocation(
                new BMap.Point(e.point.lng, e.point.lat),
                (rs) => {
                  if(rs.address){
                      this.$confirm("要搜索预约地址为 【"+rs.address+'】 附近的预约吗？', "预约搜索提示", {
                        confirmButtonText: "搜索",
                        cancelButtonText: "取消",
                        type: "success",
                    }).then(() => {
                        this.content = rs.address
                        this.get()
                        this.content = ''
                    }).catch(() => {});
                  }
                }
            );
        },
        showDetail(row) {
            this.plan = row;
            this.chechFavorite(this.plan.id);
            this.detailDialog = true;
        },
        handerMoeny(fen) {
            if (fen <= 0) return 0;
            return parseFloat(regFenToYuan(fen));
        },
        async chechFavorite(id) {
            let res = await check({ targetId: id, type: "reservation" });
            if (res.resultCode == 200) {
                this.isFavorite = res.data;
            }
        },
        async userFavorite(id) {
            let res = await favorite({
                targetId: id,
                type: "reservation",
                remark: "预约收藏",
            });
            if (res.resultCode == 200) {
                this.isFavorite = res.data;
                if (res.data) {
                    this.$message.success("收藏成功");
                } else {
                    this.$message.warning("已取消收藏");
                }
            } else {
                this.$message.error("收藏失败");
            }
        },
    },
};
</script>

<style scoped>
.main {
    width: 97%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.qrcode {
    display: inline-block;
    width: 230px;
    height: 230px;
    background-color: #fff;
}
.map {
    width: 100%;
    height: 400px;
}
a:hover {
    text-decoration: none;
}
.el-col :hover {
    transform: scale(1.01, 1.01);
    transition: 0.3s transform;
}
</style>