import Vue from "vue";
import userInfoDialog from "@/components/public/UserInfoDialog.vue"; // 引入弹窗组件

const UserInfoDialog = Vue.extend(userInfoDialog);

userInfoDialog.install = function(userId) {
  let instance = new UserInfoDialog().$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.showUser(userId);
  });
};

export default userInfoDialog;